export const splitLongWords = (value: string): string[] => {
  const words = value.split(' ');
  const maxWordsPerLine = 2; // Adjust as needed
  const lines: string[] = [];

  for (let i = 0; i < words.length; i += maxWordsPerLine) {
    lines.push(words.slice(i, i + maxWordsPerLine).join(' '));
  }

  return lines;
}