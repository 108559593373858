import { object, string } from 'yup';

const userSchema = (emailsForValidation: string[]) =>
  object({
    email: string()
      .email('Must be a valid email')
      .required('Email address is required')
      .test(
        'unique-email',
        'This email was already added to the list',
        (value) => !emailsForValidation.includes(value)
      ),
    group: string().optional(),
    assessmentId: string()
      .notOneOf(
        ['Select an assessment for this user'],
        'You have to select an assessment for the user to take'
      )
      .required('You have to select an assessment for the user to take'),
  });

export default userSchema;
