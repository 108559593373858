import { gql, graphql, rest } from '@app/services/http';
import { availableLanguages } from '@app/constants';
import { create } from 'zustand';

type TranslationFunction = (key: string, variables?: any) => string;

interface LexiconEntry {
  namespace: string,
  en: string,
  de?: string,
  fr?: string,
  it?: string,
  es?: string,
  pt?: string,
  ja?: string,
  ko?: string,
  zh?: string,
}

interface LocalizationStoreInterface {
  lexicon: {[key: string]: LexiconEntry},
  currentLanguageCode: string,
  ready: boolean,
  loadedNamespaces: string[],
  setCurrentLanguageCode: (code: string) => void,
  setReady: (value: boolean) => void,
  loadTranslations: (namespace: string) => void,
  unloadTranslations: (namespace: string) => void,
  useTranslation: () => {
    t: TranslationFunction
  }
}

export const LocalizationStore = create<LocalizationStoreInterface>((set, get) => ({
  lexicon: {},
  currentLanguageCode: 'en',
  ready: false,
  loadedNamespaces: [],
  setCurrentLanguageCode: (code) => {
    if (Object.keys(availableLanguages).includes(code)) {
      set({
        currentLanguageCode: code,
      });
    }
  },
  setReady: (value) => set({ ready: value }),
  // load translations for a particular path, ex for rh124/ch01 -> loadContentTranslations('rh124/ch01') or 'ui'
  // translations are appended to the current lexicon
  loadTranslations: async (namespace) => {
    if(get().currentLanguageCode == 'en') return;

    await graphql
      .request(gql`
        query GetLexicons($namespace: String) {
          skill_lexicons(where: {namespace: {_like: $namespace}}) {
            en
            ${get().currentLanguageCode}
          }
        }`, {
        namespace: namespace
      })
      .then((data: any) => {
        const lx = structuredClone(get().lexicon);

        data.skill_lexicons.forEach((entry) => {
          lx[entry["en"]] = entry;
        })

        const lns = [...get().loadedNamespaces];

        if(!lns.includes(namespace)){
          lns.push(namespace);
        }

        set((state) => ({
          lexicon: lx,
          ready: true,
          loadedNamespaces: lns,
        }));
      });
  },
  // remove a particular namespace from the lexicon
  unloadTranslations: async (namespace) => {
    const lx = structuredClone(get().lexicon);

    Object.keys(lx).forEach((key) => {
      if(get().lexicon[key].namespace == namespace){
        delete lx[key];
      }
    })

    const lns = [...get().loadedNamespaces];

    if(!lns.includes(namespace)){
      lns.push(namespace);
    }

    set((state) => ({
      lexicon: lx,
      ready: true,
      loadedNamespaces: lns.filter(a => a != namespace),
    }));
  },
 
  useTranslation: () => {
    // translation key support templating like "Are you sure you want to add {{ username }}?"
    const t = (key, variables = {}) => {
      if(!get().lexicon[key]){
        //console.warn(`Missing translations key: ${key}`, get().lexicon)
      }

      let translated = get().lexicon?.[key]?.[get().currentLanguageCode] || key;

      Object.keys(variables).forEach((variable: string) => {
        translated = translated.replaceAll(`{{ ${variable} }}`, variables[variable])
        translated = translated.replaceAll(`{{${variable}}}`, variables[variable])
      });

      return translated;
    };

    return { t };
  }
}));