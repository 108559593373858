import React, { useState } from 'react';
import {
  Stack,
  StackItem,
  Flex,
  FlexItem,
  Button,
  PageSection,
  Grid,
  GridItem,
  Title,
  ProgressMeasureLocation,
  Progress,
  Split,
  SplitItem,
  Card,
  CardTitle,
  CardBody,
  CardFooter,
  Modal,
} from '@patternfly/react-core';
import PageHeader from '@app/components/PageHeader/PageHeader';
import './Landing.scss';
import { useNavigate } from 'react-router-dom';
import { AssessmentCarousel } from '@app/components/AssessmentCarousel/AssessmentCarousel';
import { Loader } from '@app/components/Loader/Loader';
import individual from '@app/images/individual.png';
import team from '@app/images/team.png';
import WomanMeetingIMG from '../../images/woman-meeting.png';
import PeopleTalkingIMG from '../../images/people-talking.png';
import { useRoadmapStore } from '@app/state/RoadmapStore';
import { LocalizationStore } from '@app/state/LocalizationStore';

const LandingPage: React.FunctionComponent = () => {
  const MARKETING_BENEFITS_DATA = {
    'took-assessment': 75,
    'without-assessment': 66,
  };

  const navigate = useNavigate();

  const localization = LocalizationStore();
  const { t } = localization.useTranslation();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [videoModalIsOpen, setVideoModalIsOpen] = useState(false);

  const { isRoadmapReady, blueprints } = useRoadmapStore((state) => ({
    isRoadmapReady: state.ready,
    blueprints: state.blueprints,
  }));

  return (
    <React.Fragment>
      <PageSection
        isWidthLimited={true}
        isCenterAligned={true}
        className="hero"
      >
        <PageHeader
          isDark={true}
          title={t(
            'Take a free skills assessment to see where you should start training'
          )}
          subtitle={t(
            'Test your expertise, determine your skills gaps, and get recommendations for where to start with Red Hat training.'
          )}
          suptitle={t('RED HAT SKILLS ASSESSMENT')}
        >
          <Flex className="top-button-container">
            <FlexItem>
              <Button
                variant="primary"
                isLarge={true}
                onClick={() => {
                  setModalIsOpen(true);
                }}
              >
                {t('Get Started')}
              </Button>
            </FlexItem>
            <FlexItem>
              <Button
                variant="tertiary"
                isLarge={true}
                onClick={() => {
                  navigate(`/${localization.currentLanguageCode}/dashboard`);
                }}
              >
                {t('My Dashboard')}
              </Button>
            </FlexItem>
            <FlexItem>
              <Button
                id="see-how-it-works-button"
                variant="link"
                isInline
                isLarge={true}
                onClick={() => setVideoModalIsOpen(true)}
              >
                {t('or see how it works')}
              </Button>
            </FlexItem>
          </Flex>
        </PageHeader>
      </PageSection>

      {isRoadmapReady ? (
        blueprints.length > 0 && (
          <PageSection
            isWidthLimited={true}
            isCenterAligned={true}
            className="popular"
          >
            <Grid hasGutter={true}>
              <GridItem
                sm={12}
                md={4}
                xl={4}
              >
                <PageHeader
                  title={t(
                    'Get started with the most popular skill assessments'
                  )}
                  suptitle={t('Most Popular')}
                  span={12}
                >
                  <Button
                    variant="tertiary"
                    onClick={() => {
                      navigate(`/${localization.currentLanguageCode}/start`);
                    }}
                  >
                    {t('Browse all')}
                  </Button>
                </PageHeader>
              </GridItem>
              <GridItem
                sm={12}
                md={8}
                xl={8}
              >
                <AssessmentCarousel blueprints={blueprints.slice(0,5)} />
              </GridItem>
            </Grid>
          </PageSection>
        )
      ) : (
        <PageSection
          isWidthLimited={true}
          isCenterAligned={true}
          className="loader md"
        >
          <Loader />
        </PageSection>
      )}

      <PageSection
        isWidthLimited={true}
        isCenterAligned={true}
        className="benefits"
      >
        <Grid hasGutter>
          <GridItem
            sm={12}
            md={6}
            xl={6}
          >
            <Stack hasGutter={true}>
              <StackItem>
                <Title headingLevel="h2">
                  {t('Students who took a skills assessment')}
                </Title>
              </StackItem>
              <StackItem isFilled>
                <Progress
                  aria-label={t('Progress with an assessment')}
                  value={MARKETING_BENEFITS_DATA['took-assessment']}
                  measureLocation={ProgressMeasureLocation.inside}
                />
              </StackItem>
              <StackItem>
                <Title headingLevel="h2">
                  {t('Students without a skills assessment')}
                </Title>
              </StackItem>
              <StackItem isFilled>
                <Progress
                  aria-label={t('Progress without an assessment')}
                  value={MARKETING_BENEFITS_DATA['without-assessment']}
                  measureLocation={ProgressMeasureLocation.inside}
                  className="dull"
                />
              </StackItem>
            </Stack>
          </GridItem>
          <GridItem
            sm={12}
            md={6}
            xl={6}
          >
            <PageHeader
              title={t('Benefits')}
              suptitle={t('Get more out of your training')}
              subtitle={t(
                'Our Net Promoter Score scores show that students who take a skills assessment prior to signing up for a training course are more satisfied with their training experience.'
              )}
              span={12}
            />
          </GridItem>
        </Grid>
      </PageSection>

      <PageSection
        isWidthLimited={true}
        isCenterAligned={true}
      >
        <Grid hasGutter>
          <GridItem
            sm={12}
            md={6}
            xl={6}
          >
            <PageHeader
              title={t(
                'Pinpoint your current skill level with Red Hat products'
              )}
              suptitle={t('Individual Skills Assessment')}
              subtitle={t(
                'Select the skills assessment related to the Red Hat products you\'re using, your job role, or the IT challenges you\'re facing. Through a series of questions, the tool will compare your experience level to the required prerequisites for the relevant courses to determine the best place for you to get started'
              )}
              span={12}
            >
              <Button
                aria-label={t('Get Started')}
                variant="danger"
                onClick={() => {
                  navigate(`/${localization.currentLanguageCode}/start`);
                }}
              >
                {t('Get started')}
              </Button>
            </PageHeader>
          </GridItem>
          <GridItem
            sm={12}
            md={5}
            xl={5}
            mdOffset={7}
            xlOffset={7}
            className="images"
          >
            <img src={WomanMeetingIMG} />
          </GridItem>
        </Grid>
      </PageSection>
      <PageSection
        isWidthLimited={true}
        isCenterAligned={true}
      >
        <Grid hasGutter={true}>
          <GridItem
            sm={12}
            md={5}
            xl={5}
            order={{ default: '1', md: '0' }}
            className="images"
          >
            <img src={PeopleTalkingIMG} />
          </GridItem>
          <GridItem
            sm={12}
            md={6}
            xl={6}
            mdOffset={6}
            xlOffset={6}
            order={{ default: '0', md: '1' }}
          >
            <PageHeader
              title={t(
                'Identify skill gaps and training opportunities for your team'
              )}
              suptitle={t('Team Skills Assessment')}
              subtitle={t(
                'Select the skills assessment related to the Red Hat products you\'re using, your job role, or the IT challenges you\'re facing. Through a series of questions, the tool will compare your experience level to the required prerequisites for the relevant courses to determine the best place for you to get started'
              )}
              span={12}
            >
              <Button
                aria-label={t('Test your team')}
                variant="danger"
                onClick={() => {
                  navigate(`/${localization.currentLanguageCode}/create`);
                }}
              >
                {t('Test your team')}
              </Button>
            </PageHeader>
          </GridItem>
        </Grid>
      </PageSection>

      {/*
      <PageSection
        isWidthLimited={true}
        isCenterAligned={true}
        className="roi"
      >
        <PageHeader
          title={t('Red Hat Training delivers ROI')}
          span={12}
          isDark={true}
        />
        <Grid hasGutter={true}>
          {MARKETING_ROI_DATA.map((entry, i) => <GridItem key={`roi-entry-${i}`} span={3} className="roi-card">
            <Text className="percentage">{entry.value}%</Text>
            <Text className="label">{t(entry.label)}</Text>
          </GridItem>)}
        </Grid>

      </PageSection>
      */}
      <Modal
        isOpen={modalIsOpen}
        showClose={false}
        className="landing-modal"
        aria-label="get started"
      >
        <Split
          hasGutter
          className="splitBox"
        >
          <SplitItem>
            <Card
              id="get-started-card"
              className={'deep-card-padding'}
            >
              <CardTitle>
                <img src={individual} />
                <Title
                  headingLevel={'h3'}
                  size={'lg'}
                >
                  {t('Individual Skills Assessments')}
                </Title>
              </CardTitle>
              <CardBody>
                <p>
                  {t(
                    'Assess your skills by role or product and receive personalized training recommendations.'
                  )}
                </p>
              </CardBody>
              <CardFooter>
                <Button
                  variant={'primary'}
                  isLarge
                  onClick={() => {
                    navigate(`/${localization.currentLanguageCode}/start`);
                  }}
                >
                  {t('Test yourself')}
                </Button>
              </CardFooter>
            </Card>
          </SplitItem>
          <SplitItem>
            <Card
              id="get-started-card"
              className={'deep-card-padding'}
            >
              <CardTitle>
                <img src={team} />
                <Title
                  headingLevel={'h3'}
                  size={'lg'}
                >
                  {t('Team Skills Assessments')}
                </Title>
              </CardTitle>
              <CardBody>
                <p>
                  {t(
                    'Identify the skills gaps within your team and receive detailed reporting and training recommendations.'
                  )}
                </p>
              </CardBody>
              <CardFooter>
                <Button
                  variant={'primary'}
                  isLarge
                  onClick={() => {
                    navigate(`/${localization.currentLanguageCode}/create`);
                  }}
                >
                  {t('Test your team')}
                </Button>
              </CardFooter>
            </Card>
          </SplitItem>
        </Split>
        <Button
          variant="link"
          className="close"
          onClick={() => {
            setModalIsOpen(false);
          }}
        >
          {t('Go back')}
        </Button>
      </Modal>
      <Modal
        isOpen={videoModalIsOpen}
        variant="large"
        onClose={() => setVideoModalIsOpen(false)}
        aria-label="Video content"
      >
        <video width="100%" height="320" autoPlay={true} controls src='/skillmaster/static/videos/howto.webm' />
      </Modal>
    </React.Fragment>
  );
};

export default LandingPage;
