import React from 'react';
import {
  Card,
  CardBody,
  Tooltip,
  Icon,
  GridItem,
  Grid,
  Flex,
} from '@patternfly/react-core';
import {
  ChartAxis,
  ChartBullet,
  ChartThemeColor,
} from '@patternfly/react-charts';
import InfoCircleIcon from '@patternfly/react-icons/dist/esm/icons/info-circle-icon';
import './PerformanceCard.scss';
import { LocalizationStore } from '@app/state/LocalizationStore';

const getThemeColor = (value) => {
  if (value < 25) return ChartThemeColor.purple;
  if (value < 50) return ChartThemeColor.orange;
  if (value < 75) return ChartThemeColor.cyan;
  return ChartThemeColor.green;
};

const getRangeData = (
  value: number,
  ticks: number[],
  t: (key: string) => string
) => {
  const firstTickIndex = ticks.findIndex((tick) => value <= tick);

  if (firstTickIndex !== -1) {
    const lowerY = ticks[firstTickIndex !== 0 ? firstTickIndex - 1 : 0];
    const upperY =
      ticks[firstTickIndex !== 0 ? firstTickIndex : firstTickIndex + 1]; // Handle potential out-of-bounds

    return [
      { name: t('Lower limit'), y: lowerY },
      { name: t('Upper limit'), y: upperY },
    ];
  }

  return undefined;
};

interface IBaseCardProps {
  children: React.ReactNode;
}

const BaseCard: React.FC<IBaseCardProps> = ({ children }) => {
  const [textContent, graph] = React.Children.toArray(children);

  return (
    <Card className="card">
      <CardBody className="card-body">
        <Grid>
          <GridItem sm={12} md={6} xl={6}>
            <Flex className="card-text">{textContent}</Flex>
          </GridItem>
          <GridItem sm={12} md={6} xl={6}>{graph}</GridItem>
        </Grid>
      </CardBody>
    </Card>
  );
};

interface IPerformanceCardProps {
  name: string;
  value: number;
  average?: number;
}

export const PerformanceCard: React.FC<IPerformanceCardProps> = ({
  name,
  value,
  average = null,
}) => {
  const TICK_VALUES = [0, 25, 50, 75, 100];

  const localization = LocalizationStore();
  const { t } = localization.useTranslation();

  return (
    <BaseCard>
      <>
        <h6 className="title">{name}</h6>
        <p className="percentage">{value}%</p>
      </>
      <ChartBullet
        ariaTitle={name}
        axisComponent={
          <ChartAxis
            tickValues={TICK_VALUES}
            tickFormat={(val) => {
              switch (val) {
              case 0:
                return `${t('New')}`;
              case 25:
                return `${t('Beginner')}`;
              case 50:
                return `${t('Intermediate')}`;
              case 75:
                return `${t('Advanced')}`;
              case 100:
                return `${t('Expert')}`;
              default:
                return '';
              }
            }}
          />
        }
        comparativeWarningMeasureData={
          average ? [{ name: t('Everyone\'s average'), y: average }] : undefined
        }
        constrainToVisibleArea
        height={150}
        labels={({ datum }) => `${t(datum.name)}: ${datum.y}`}
        maxDomain={{ y: 100 }}
        name="chart12"
        primarySegmentedMeasureData={[
          { name: t('This performance'), y: value },
        ]}
        qualitativeRangeData={getRangeData(value, TICK_VALUES, t)}
        width={450}
        themeColor={getThemeColor(value)}
      />
    </BaseCard>
  );
};

interface IConfidenceCardProps {
  name: string;
  value: number;
  average?: number;
}

export const ConfidenceCard: React.FC<IConfidenceCardProps> = ({
  name,
  value,
  average = null,
}) => {
  const localization = LocalizationStore();
  const { t } = localization.useTranslation();
  const TICK_VALUES = [0, 50, 100];

  return (
    <BaseCard>
      <>
        <Flex className="tooltip">
          <h6 className="title">{name}</h6>
          <Tooltip
            content={
              <div>
                {t(
                  'Convergence indicates how aligned assessment results and recommendations are across participants. i.e. A high convergence rating indicates participants skills are more aligned.'
                )}
              </div>
            }
          >
            <Icon size="sm">
              <InfoCircleIcon />
            </Icon>
          </Tooltip>
        </Flex>
        <p className="percentage">{value}%</p>
      </>

      <ChartBullet
        ariaTitle={name}
        axisComponent={
          <ChartAxis
            tickValues={TICK_VALUES}
            tickFormat={(val) => {
              switch (val) {
              case 0:
                return `${t('Low')}`;
              case 50:
                return `${t('Fair')}`;
              case 100:
                return `${t('Good')}`;
              default:
                return '';
              }
            }}
          />
        }
        comparativeWarningMeasureData={
          average ? [{ name: t('Everyone\'s average'), y: average }] : undefined
        }
        constrainToVisibleArea
        height={150}
        labels={({ datum }) => `${t(datum.name)}: ${datum.y}`}
        maxDomain={{ y: 100 }}
        name="chart12"
        primarySegmentedMeasureData={[
          { name: t('This assessment convergence'), y: value },
        ]}
        qualitativeRangeData={getRangeData(value, TICK_VALUES, t)}
        width={450}
        themeColor={getThemeColor(value)}
      />
    </BaseCard>
  );
};

interface ITextCardProps {
  name: string;
  value: string | number;
}

export const TextCard: React.FC<ITextCardProps> = ({ name, value }) => {
  return (
    <Card className="card">
      <CardBody className="card-body">
        <Flex direction={{ default: 'column' }}>
          <h6 className="title">{name}</h6>
          <p className="percentage">{value}</p>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default PerformanceCard;
