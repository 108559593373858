import React, { useRef } from 'react';
import { Button, Grid, GridItem, TextInput } from '@patternfly/react-core';
import { CopyIcon, MinusCircleIcon } from '@patternfly/react-icons';
import './ParticipantEntry.scss';
import { IBlueprint } from '@app/types';
import { validateParticipant } from '@app/state/CreatorStore';
import { LocalizationStore } from '@app/state/LocalizationStore';

export interface IParticipant {
  _valid: boolean;
  email: string;
  group: string;
  assessment: string;
}

export interface IParticipantEntry {
  index: number;
  participant: IParticipant;
  availableAssessments: IBlueprint[];
  onChange: (index: number, value: IParticipant) => void;
  onDelete: (index: number) => void;
  onDuplicate: (index: number) => void;
  isDummy?: boolean;
}

const ParticipantEntry: React.FC<IParticipantEntry> = ({
  index,
  participant,
  availableAssessments,
  onChange,
  onDelete,
  onDuplicate,
  isDummy = false,
}): React.ReactElement => {
  const emailRef = useRef<HTMLInputElement>(null);
  const groupRef = useRef<HTMLInputElement>(null);
  const assessmentRef = useRef<HTMLSelectElement>(null);

  const localization = LocalizationStore();
  const { t } = localization.useTranslation();

  const sync = () => {
    if (emailRef.current && groupRef.current && assessmentRef.current) {
      onChange(index, {
        _valid: validateParticipant(
          emailRef.current.value,
          assessmentRef.current.value,
          availableAssessments
        ),
        email: emailRef.current.value,
        group: groupRef.current.value,
        assessment: assessmentRef.current.value,
      });
    }
  };

  return (
    <Grid
      hasGutter={true}
      className="participant-entry"
    >
      <GridItem span={3}>
        <TextInput
          aria-label="Email"
          type="email"
          isRequired
          value={participant.email}
          placeholder={t('Email address')}
          ref={emailRef}
          onChange={() => {
            sync();
          }}
        />
      </GridItem>
      <GridItem span={3}>
        <TextInput
          aria-label="Group"
          type="text"
          isRequired
          value={participant.group}
          placeholder={t('Group (optional)')}
          ref={groupRef}
          onChange={() => {
            sync();
          }}
        />
      </GridItem>
      <GridItem span={4}>
        <select
          ref={assessmentRef}
          value={participant.assessment}
          className="assessment-dropdown"
          onChange={() => {
            sync();
          }}
        >
          <option
            key="_none-selected"
            value=""
          >{`- ${t('No assessment selected')}`}</option>
          {availableAssessments.map((a) => (
            <option
              key={a.sku}
              value={a.sku}
            >
              {t(a.label)}
            </option>
          ))}
        </select>
      </GridItem>
      <GridItem span={1}>
        <Button
          variant="tertiary"
          isBlock
          isDisabled={isDummy}
          icon={<CopyIcon />}
          onClick={() => {
            onDuplicate(index);
          }}
        ></Button>
      </GridItem>
      <GridItem span={1}>
        <Button
          variant="tertiary"
          isBlock
          isDisabled={isDummy}
          icon={<MinusCircleIcon />}
          onClick={() => {
            onDelete(index);
          }}
        ></Button>
      </GridItem>
    </Grid>
  );
};

export default ParticipantEntry;
