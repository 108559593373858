import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from '@app/pages/Landing/Landing';
import ResultsPage from './pages/Results/Results';
import NotFound from '@app/pages/NotFound/NotFound';
import StartPage from './pages/Start/Start';
import DashboardPage from './pages/Dashboard/Dashboard';
import PrivateRoutes from '@app/components/PrivateRoutes';
import TeamReport from './pages/TeamReport/TeamReport';
import Presenter from './pages/Presenter/Presenter';
import ParticipantsPage from '@app/pages/Creator/Participants';
import ReviewPage from '@app/pages/Creator/Review';
import BaseLayout from '@app/base';
import PresenterLegacy from './pages/Presenter/PresenterLegacy';

const AppRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route
        path=":lang"
        element={<BaseLayout />}
      >
        <Route
          path=""
          element={<LandingPage />}
        />
        <Route element={<PrivateRoutes />}>
          <Route
            path="dashboard"
            element={<DashboardPage />}
          />
          <Route
            path="start"
            element={<StartPage />}
          />
          <Route
            path="start/:sku"
            element={<StartPage />}
          />
          <Route
            path="assessment/:id"
            element={<Presenter />}
          />
          {/* TODO: remove me once all team assessments are on v2 */}
          <Route
            path="assessment-legacy/:id"
            element={<PresenterLegacy />}
          />
          <Route
            path="results/:id"
            element={<ResultsPage />}
          />
          <Route
            path="create"
            element={<ParticipantsPage />}
          />
          <Route
            path="create/review"
            element={<ReviewPage />}
          />
          <Route
            path="reporting/team/:id"
            element={<TeamReport />}
          />
        </Route>
      </Route>
      <Route
        path="/"
        element={
          <Navigate
            replace
            to="/en"
          />
        }
      />
      <Route
        path="*"
        element={<NotFound />}
      />
    </Routes>
  );
};

export { AppRoutes };
