import {
  GraphQLClient,
  RequestMiddleware,
  ResponseMiddleware,
} from 'graphql-request';
import Cookies from 'js-cookie';
import ky from 'ky';

const endpoint = '/skillmaster/hasura/v1/graphql';

const requestMiddleware: RequestMiddleware = async (request) => {
  const headers = request.headers || {};

  if (Cookies.get('csrf_access_token')) {
    headers['x-csrf-authorization'] = Cookies.get('csrf_access_token');
  }

  return {
    ...request,
    headers: headers,
  };
};

const responseMiddleware: ResponseMiddleware = (data) => {
  // if we're getting a 401 for an expired token, the auto-renewal mechanism
  // implemented with the heartbeat didn't work as expected, so we're
  // forcing a reset
  // @ts-ignore
  if (data.response?.status === 401 && data.response?.token_expired) {
    window.location.reload();
  }
};

export const graphql = new GraphQLClient(endpoint, {
  requestMiddleware,
  responseMiddleware,
});

export const rest = ky.extend({
  hooks: {
    beforeRequest: [
      (request) => {
        const token = Cookies.get('csrf_access_token');
        if (token) {
          request.headers.set('x-csrf-authorization', token);
        }
      },
    ],
  },
});

export { gql } from 'graphql-request';
