import React from 'react';

interface IBackticks {
  source: string;
}

const Backticks: React.FC<IBackticks> = ({ source }) => {
  const entries = source.split('`');

  return (
    <>
      {entries.map((entry, i) => {
        if (i % 2) {
          // Inside backticks (odd index positions)
          if (entry.trim().includes('\\') && entries[i - 1].trim().endsWith('\\')) {
            // If the backticks should not be escaped, replace '\\' with '`'
            const parsedEntry = '`' + entry.replaceAll('\\', '`');
            return parsedEntry;
          } else {
            // If the backticks should be escaped, do not replace '\\'
            return <code key={i}>{entry}</code>;
          }
        } else {
          // Outside backticks (even index positions)
          return entry;
        }
      })}
    </>
  );
};

export default Backticks;
