import { create } from 'zustand';

import { v4 as uuidv4 } from 'uuid';
import { IAssessmentModel } from '@app/types';

export interface IOffering {
    id: string,
    name: string,
    label: string,
    summary: string,
    action: string|null,
    href: string|null,
}

interface ISheetBodyBankQuestion {
  topic: string,
  question: string,
  correct: number,
  options: string[]
}

interface ISheetBody {
  bank: ISheetBodyBankQuestion[]
}

export interface ISheet {
  id: string,
  metadata: { [key: string]: string },
  title: string,
  body: ISheetBody,
  path: string[],
}

interface IScoreEntry {
  maximum: number,
  current: number,
}

interface IFinalScoreEntry {
  id: string,
  name: string,
  maximum: number,
  points: number,
  score: number,
}

export interface IProctorStore {
  currentSheet: ISheet|null,
  currentIndex: number,
  score: { [key: string]: IScoreEntry }, // { "The unit topic": 99 }
  allOfferings: IOffering[],
  suggestions: IOffering[],
  ready: boolean,
  completed: boolean,
  hasUpdatedCompanyName: boolean,
  setHasUpdatedCompanyName: (value: boolean) => void,
  chronoStartedAt: number|null,
  setSheet: (sheet: ISheet) => void,
  setOfferings: (offerings: IOffering[]) => void,
  answerQuestion: (answerIndex: number) => void,
  getFinalScore: () => IFinalScoreEntry[],
  resetSheet: () => void,
  shownAssessment: null | IAssessmentModel;
}

const initialState ={
  currentSheet: null,
  currentIndex: 0,
  chronoStartedAt: null,
  score: {},
  allOfferings: [],
  suggestions: [],
  ready: false,
  completed: false,
  hasUpdatedCompanyName: false,
  shownAssessment: null
}

export const useProctorStore = create<IProctorStore>()((set, get) => ({
  ...initialState,
  setHasUpdatedCompanyName: (value) =>
    set(() => ({ hasUpdatedCompanyName: value })),
  setSheet: (sheet: ISheet) => {
    set({
      currentSheet: sheet,
      chronoStartedAt: new Date().getTime(),
    });
  },
  setOfferings: (offerings: IOffering[]) => {
    set({
      allOfferings: offerings,
    });
  },
  answerQuestion: (answerIndex: number) => {
    if (!get().currentSheet) {
      return;
    }

    if (get().currentIndex + 1 === get().currentSheet?.body.bank.length) {
      set({
        completed: true,
      });
    } else {
      const score = structuredClone(get().score);
      const suggestions = [...get().suggestions];
      const currentQuestion = get().currentSheet!.body.bank[get().currentIndex];
      const path = get().currentSheet!.path;

      if (currentQuestion) {
        // create score for this topic if doesn't exist yet
        if (!Object.keys(score).includes(currentQuestion.topic)) {
          score[currentQuestion.topic] = {
            maximum: 0,
            current: 0,
          };
        }

        // increment the  maximum number of points
        score[currentQuestion.topic].maximum += 1;

        // increment score only if it's the correct answer
        if (answerIndex === currentQuestion.correct) {
          score[currentQuestion.topic].current += 1;
        }

        const assessment_score = Math.round(
          Object.values(score).reduce(
            (a, c) => a + Math.round((c.current * 100) / c.maximum),
            0
          ) / Object.values(score).length
        );
        const suggestion_index =
          assessment_score === 100
            ? path.length - 1
            : Math.floor((path.length * assessment_score) / 100);

        const new_suggestions = [path[suggestion_index]];

        // if the top suggestion is not the last one, add the prerequisites to the suggestions
        if (suggestion_index > 0) {
          const rest_of_path = path.slice(0, suggestion_index);

          rest_of_path.forEach((code: string) => {
            new_suggestions.push(code);
          });
        }

        set({
          currentIndex: get().currentIndex + 1,
          score: score,
          suggestions: new_suggestions.map((id: string) => {
            const target = get().allOfferings.find(
              (offering: IOffering) =>
                offering.label.toLowerCase() === id.toLowerCase()
            );

            if (!target) {
              throw Error(
                `Could not find offering with ID ${id} within offering list`
              );
            }

            return target;
          }),
        });
      }
    }
  },
  getFinalScore: () => {
    const currentScore = get().score;

    return Object.keys(currentScore).map((topic: string) => {
      const maximum = currentScore[topic].maximum;
      const current = currentScore[topic].current;

      return {
        id: uuidv4(),
        name: topic,
        maximum: maximum,
        points: current,
        score: Math.round((current * 100) / maximum),
      };
    });
  },
  resetSheet: () => {
    set(initialState);
  },
}));