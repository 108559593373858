import React, { useState } from 'react';
import { Radio, Text, PageSection } from '@patternfly/react-core';
import { PageControls } from '@app/components/PageControls/PageControls';
import './SingleChoiceQuestion.scss';
import Backticks from '../Backticks/Backticks';
import { useProctorStore } from '@app/state/ProctorStore';
import { LocalizationStore } from '@app/state/LocalizationStore';
import { Loader } from '@app/components/Loader/Loader';

const SingleChoiceQuestion: React.FC = () => {
  const localization = LocalizationStore();

  const { t } = localization.useTranslation();

  const {
    currentSheet,
    currentIndex,
    answerQuestion,
  } = useProctorStore((state) => ({
    currentSheet: state.currentSheet,
    currentIndex: state.currentIndex,
    answerQuestion: state.answerQuestion,
  }));

  const [selectedAnswer, setSelectedAnswer] = useState<number>(0);

  const handleChange = (_: boolean, event: React.BaseSyntheticEvent) => {
    setSelectedAnswer(parseInt(event.target.dataset.index));
  }

  const onNextCallback = () => {
    answerQuestion(selectedAnswer);
    setSelectedAnswer(0);
  }

  return currentSheet != null ? (
    <React.Fragment>
      <PageSection
        isWidthLimited={true}
        isCenterAligned={true}
        className="single-question"
      >
        <Text className="question-text">
          <Backticks source={t(
            currentSheet.body.bank[currentIndex].question
          )} />
        </Text>

        {currentSheet.body.bank[currentIndex].options.map((answer, index) => (
          <Radio
            aria-label={t(answer)}
            id={'answer-' + index}
            className="question-answer"
            name={'answer-choice'}
            key={index}
            data-index={index}
            onChange={handleChange}
            label={<Backticks source={t(answer)} />}
            isChecked={index === selectedAnswer}
          />
        ))}
      </PageSection>
      <PageSection
        isWidthLimited={true}
        isCenterAligned={true}
        className="page-controls"
      >
        <PageControls onRight={onNextCallback} rightLabel={t('Next')} />
      </PageSection>
    </React.Fragment>
  ) : <Loader />
};

export default SingleChoiceQuestion;
