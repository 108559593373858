import React, { useState } from 'react';
import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  ActionsColumn,
  IAction,
} from '@patternfly/react-table';
import {
  Modal as PTFModal,
  ModalVariant,
  Button,
  Form,
  FormGroup,
  TextInput,
} from '@patternfly/react-core';
import { SectionHeader } from '@app/components/PageHeader/PageHeader';
import { v4 as uuidv4 } from 'uuid';
import './styles.scss';
import {
  ICampaignModel,
  deleteSharedEmailsMutationResponse,
  sharedEmailList,
  updateSharedEmailsMutationResponse,
} from '@app/types';
import { LocalizationStore } from '@app/state/LocalizationStore';
import { UserStore } from '@app/state/UserStore';
import { useAlert } from '@app/hooks/useAlert';

enum ModalEnum {
  'ADD',
  'EDIT',
}

type User = {
  id: string;
  email: string;
};

interface IInputModal {
  isOpen: boolean;
  mode: null | ModalEnum;
  user: User;
  confirmButtonLoading: boolean;
}

type IDeleteModal = Omit<IInputModal, 'mode'>;

interface IShareTableProps {
  data: ICampaignModel;
  handleSharedEmailUpdate: (
    value: string | null,
    fieldName: string
  ) => Promise<updateSharedEmailsMutationResponse>;
  handleDelete: (
    emailsForUpdate: sharedEmailList
  ) => Promise<deleteSharedEmailsMutationResponse>;
}

const ShareTable: React.FC<IShareTableProps> = ({
  data,
  handleSharedEmailUpdate,
  handleDelete,
}) => {
  const { addAlert } = useAlert();
  const filterEmails = (data: ICampaignModel | sharedEmailList) => {
    return Object.entries(data)
      .filter(
        ([key, value]) => key.startsWith('shared_email_') && value !== null
      )
      .map(([key, value]) => ({
        id: key,
        email: value as string,
      }));
  };

  const localization = LocalizationStore();

  const { t } = localization.useTranslation();
  const { user } = UserStore((state) => ({
    user: state.current,
  }));

  const columnNames = {
    email: t('Email'),
    number: t('Number'),
  };

  const [users, setUsers] = useState<User[]>(filterEmails(data));
  const [inputModal, setInputModal] = useState<IInputModal>({
    isOpen: false,
    mode: null,
    user: {
      id: '',
      email: '',
    },
    confirmButtonLoading: false,
  });
  const [deleteModal, setDeleteModal] = useState<IDeleteModal>({
    isOpen: false,
    user: {
      id: '',
      email: '',
    },
    confirmButtonLoading: false,
  });

  const actions = (user: User): IAction[] => [
    {
      title: t('Edit'),
      onClick: () =>
        setInputModal({
          isOpen: true,
          mode: ModalEnum.EDIT,
          user: {
            id: user.id,
            email: user.email,
          },
          confirmButtonLoading: false,
        }),
    },
    {
      title: t('Delete'),
      onClick: () =>
        setDeleteModal({
          isOpen: true,
          user: {
            id: user.id,
            email: user.email,
          },
          confirmButtonLoading: false,
        }),
    },
  ];

  const handleDeleteModalClose = () => {
    setDeleteModal({
      isOpen: false,
      user: {
        id: '',
        email: '',
      },
      confirmButtonLoading: false,
    });
  };

  const handleInputModalClose = () => {
    setInputModal({
      isOpen: false,
      mode: null,
      user: {
        id: '',
        email: '',
      },
      confirmButtonLoading: false,
    });
  };

  const handleDataAfterDelete = (data: sharedEmailList) => {
    const keys = Object.keys(data);
    const index = keys.indexOf(deleteModal.user.id);
    const shiftedData = {} as sharedEmailList;

    for (let i = 1; i <= keys.length; i++) {
      const currentKey = `shared_email_${i}`;
      // Check if the current iteration is before the index of the deleted user
      if (i <= index) {
        // If yes, copy the data as is
        shiftedData[currentKey] = data[currentKey];
      } else if (i === keys.length) {
        // If it's the last iteration, set the current key to null
        shiftedData[currentKey] = null;
      } else {
        // If the current iteration is after the index, shift the data by one position
        shiftedData[currentKey] = data[`shared_email_${i + 1}`];
      }
    }
    return shiftedData;
  };

  const handleDeleteUser = async () => {
    setDeleteModal((prev) => ({
      ...prev,
      confirmButtonLoading: true,
    }));

    try {
      const updateMutationResponse = await handleSharedEmailUpdate(
        null,
        deleteModal.user.id
      );

      const filteredEmails = handleDataAfterDelete(
        updateMutationResponse.update_skill_campaigns_by_pk
      );

      const deleteMutationResponse = await handleDelete(filteredEmails);

      // sets users to the last response from the mutations
      setUsers(
        filterEmails(
          deleteMutationResponse.update_skill_campaigns_many[0].returning[0]
        )
      );

      handleDeleteModalClose();
    } catch (error: any) {
      setDeleteModal((prev) => ({
        ...prev,
        confirmButtonLoading: false,
      }));
      addAlert(t('Error while deleting email'), 'danger', uuidv4(), t(error.message));
    }
  };

  const handleConfirmInputModal = async () => {
    setInputModal((prev) => ({
      ...prev,
      confirmButtonLoading: true,
    }));

    const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    try {
      if (!inputModal.user.email)
        throw new Error('Email field cannot be empty');
      if (!isValidEmail.test(inputModal.user.email))
        throw new Error('Please enter a valid email address.');
      if (users.find((user) => user.email === inputModal.user.email))
        throw new Error('This report was already shared with this email');
      if (users.length === 10 && inputModal.mode === ModalEnum.ADD)
        throw new Error('You can only add 10 emails to this list');
      if (inputModal.user.email === user.email)
        throw new Error('You cannot share a report with yourself');

      // ADD mode
      if (inputModal.mode === ModalEnum.ADD) {
        const generatedId = `shared_email_${users.length + 1}`;

        const data = await handleSharedEmailUpdate(
          inputModal.user.email,
          generatedId
        );

        setUsers(filterEmails(data.update_skill_campaigns_by_pk));
      } else {
        // EDIT mode
        const data = await handleSharedEmailUpdate(
          inputModal.user.email,
          inputModal.user.id
        );

        setUsers(filterEmails(data.update_skill_campaigns_by_pk));
      }

      handleInputModalClose();
    } catch (error: any) {
      setInputModal((prev) => ({
        ...prev,
        confirmButtonLoading: false,
      }));
      addAlert(
        inputModal.mode === ModalEnum.ADD
          ? t('Error while adding email')
          : t('Error while editing email'),
        'danger',
        uuidv4(),
        t(error.message),
      );
    }
  };

  return (
    <React.Fragment>
      <div className="top-section">
        <SectionHeader
          title={t('Share assessment results')}
          description={t('View and manage user access to results')}
        />
        <Button
          variant="primary"
          className="share-button"
          onClick={() =>
            setInputModal((prev) => ({
              ...prev,
              isOpen: true,
              mode: ModalEnum.ADD,
            }))
          }
        >
          {t('Share Report')}
        </Button>
      </div>

      {users.length > 0 ? (
        <Table aria-label="Shared emails list">
          <Thead>
            <Tr>
              <Th>{columnNames.email}</Th>
              <Th>{columnNames.number}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.map((user) => (
              <Tr key={user.id}>
                <Td dataLabel={columnNames.email}>{user.email}</Td>
                <Td dataLabel={columnNames.number}>
                  {/* reduced shared_email_* to a single number */}
                  {user.id.split('l_').slice(1).join('l_')}
                </Td>
                <Td isActionCell>
                  <ActionsColumn items={actions(user)} />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <center className="empty-table-text">
          {t(
            'This report has not been shared with any users, click “Share Report” to grant users access to view Team Assessment results.'
          )}
        </center>
      )}

      {/* Add and Edit Modal */}
      <React.Fragment>
        <PTFModal
          variant={ModalVariant.small}
          title={
            inputModal.mode === ModalEnum.ADD
              ? t('Add new email')
              : t('Edit email')
          }
          description={
            inputModal.mode === ModalEnum.ADD
              ? t(
                'Enter the email address associated with the Red Hat ID belonging to the person you want to share this report with:'
              )
              : t(
                'Enter the new email address associated with the Red Hat ID belonging to the person you want to share this report with:'
              )
          }
          isOpen={inputModal.isOpen}
          onClose={handleInputModalClose}
          actions={[
            <Button
              key="cancel"
              variant="tertiary"
              onClick={handleInputModalClose}
            >
              {t('Cancel')}
            </Button>,
            <Button
              key="create"
              variant="primary"
              form="modal-with-form-form"
              onClick={handleConfirmInputModal}
              type="submit"
              isLoading={inputModal.confirmButtonLoading}
            >
              {t('Confirm')}
            </Button>,
          ]}
        >
          <Form>
            <FormGroup
              label="E-mail"
              isRequired
              fieldId="email"
            >
              <TextInput
                isRequired
                type="email"
                id="email"
                value={inputModal.user.email}
                onChange={(value) =>
                  setInputModal((prev) => ({
                    ...prev,
                    user: {
                      id: prev.user.id,
                      email: value,
                    },
                  }))
                }
              />
            </FormGroup>
          </Form>
        </PTFModal>
      </React.Fragment>
      {/* Delete Modal */}
      <React.Fragment>
        <PTFModal
          variant={ModalVariant.small}
          title="Delete email"
          isOpen={deleteModal.isOpen}
          onClose={handleDeleteModalClose}
          actions={[
            <Button
              key="cancel"
              variant="tertiary"
              onClick={handleDeleteModalClose}
            >
              {t('Cancel')}
            </Button>,
            <Button
              key="confirm"
              variant="primary"
              onClick={handleDeleteUser}
              isLoading={deleteModal.confirmButtonLoading}
            >
              {t('Confirm')}
            </Button>,
          ]}
        >
          {t(
            'Are you sure you want to remove this email ? If you continue, the associated user will no longer be able to access this report.'
          )}
        </PTFModal>
      </React.Fragment>
    </React.Fragment>
  );
};

export default ShareTable;
