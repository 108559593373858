import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  Button,
  Card,
  Stack,
  StackItem,
  CardBody,
  Grid,
  GridItem,
  Split,
  SplitItem,
  Modal,
  ModalVariant,
  Text,
} from '@patternfly/react-core';
import './DashboardEntry.scss';
import { useNavigate } from 'react-router-dom';
import {
  ICachedOfferingModel,
  ICampaignModel,
  IInvitationModel,
  IReportModel,
} from '@app/types';
import { calculatePerformanceEntries } from '@app/utils/reporting';
import { UserStore } from '@app/state/UserStore';
import { LocalizationStore } from '@app/state/LocalizationStore';
import { gql, graphql } from '@app/services/http';

interface IDashboardEntryProps {
  index?: number;
  invitation?: IInvitationModel;
  campaign?: ICampaignModel;
  report?: IReportModel;
  assessment?: ICampaignModel;
  onExpirationUpdated?: (id: string, expiration: Date) => void;
  onlyExtendButton?: boolean;
  isShared?: boolean;
}

interface Outcome {
  score: number;
  suggestion: ICachedOfferingModel;
}

const UPDATE_TEAMASSESSMENT_EXPIRY = gql`
  mutation UpdateExpirationDateOnCampaign(
    $campaign_id: uuid!
    $expiration: timestamp
  ) {
    update_skill_campaigns_by_pk(
      _set: { expiration: $expiration }
      pk_columns: { id: $campaign_id }
    ) {
      id
      expiration
    }
  }
`;

const DashboardEntry: React.FC<IDashboardEntryProps> = ({
  invitation = null,
  campaign = null,
  report = null,
  index = null,
  onExpirationUpdated,
  onlyExtendButton = false,
  isShared = null,
}) => {
  // when the campaign have less than this amount of days before expiration,
  // it can be extended by the same amount of days
  const EXTENSION_TIMEFRAME = 4 * 7; // renewal timeframe in days
  const navigate = useNavigate();

  const { user } = UserStore((state) => ({
    user: state.current,
  }));

  const localization = LocalizationStore();
  const { t } = localization.useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatingExpiry, setUpdatingExpiry] = useState(false);

  const [canExtend, setCanExtend] = useState<boolean>(false);
  const [entryType, setEntryType] = useState('');
  const [assessmentsNames, setAssessmentsNames] = useState<string[]>([]);
  const [participants, setParticipants] = useState(0);

  const [outcome, setOutcome] = useState<Outcome | null>(null);

  const showExtensionModal = (show) => {
    setIsModalOpen(show);
  };

  const isInTheFuture = (date: Date) => {
    return date.getTime() - Date.now() > 0;
  };

  const onExtendCampaign = async (id, expiry) => {
    const old_expiry = new Date(expiry);
    const new_expiry = new Date(
      Date.UTC(
        old_expiry.getFullYear(),
        old_expiry.getMonth(),
        old_expiry.getDate(),
        0,
        0,
        0,
        0
      )
    );
    new_expiry.setDate(new_expiry.getDate() + EXTENSION_TIMEFRAME);

    setUpdatingExpiry(true);

    await graphql
      .request(UPDATE_TEAMASSESSMENT_EXPIRY, {
        expiration: new_expiry.toISOString(),
        campaign_id: id,
      })
      .then(() => {
        setUpdatingExpiry(false);
        setIsModalOpen(false);
        if (onExpirationUpdated) {
          onExpirationUpdated(id, new_expiry);
        }
      });
  };

  const isWithinTimeframe = (expiration) => {
    const then = new Date(expiration);
    const now = new Date();

    const msBetweenDates = then.getTime() - now.getTime();

    const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);

    return daysBetweenDates > 0 && daysBetweenDates < EXTENSION_TIMEFRAME;
  };

  const onReportClick = (report) => {
    navigate(`/${localization.currentLanguageCode}/results/${report.id}`);
  };

  const onCampaignClick = (campaign) => {
    navigate(
      `/${localization.currentLanguageCode}/reporting/team/${campaign.id}`
    );
  };

  const handleCampaignInfoParsing = (array: ICampaignModel) => {
    const invitations_assessments = array.metadata.invitations.map(
      (i) => i.metadata.assessment.name
    );
    const reports_assessments = array.reports.map(
      (r) => r.metadata.assessment.name
    );

    const names = invitations_assessments
      .concat(reports_assessments)
      .filter((v, i, a) => a.indexOf(v) === i);

    const participants = array.reports
      .map((r) => r.metadata.user.email)
      .concat(array?.metadata.invitations.map((i) => i.metadata.user.email))
      .filter((v, i, a) => a.indexOf(v) === i).length;

    return { names, participants };
  };

  useEffect(() => {
    if (invitation) {
      setEntryType(t('INVITATION'));
      setAssessmentsNames([invitation.metadata.assessment.name]);
    } else if (campaign) {
      setEntryType(t('TEAM REPORT'));
      const { names, participants } = handleCampaignInfoParsing(campaign);
      setAssessmentsNames(names);
      setParticipants(participants);
    } else if (report) {
      setEntryType(
        report.campaign_id ? t('TEAM RESULT') : t('INDIVIDUAL RESULT')
      );
      setAssessmentsNames([report.metadata.assessment.name]);

      const { performanceRate } = calculatePerformanceEntries([report]);

      setOutcome({
        score: performanceRate,
        suggestion: report.suggestions[0],
      });
    }

    if (
      campaign &&
      campaign.owner_id == user.uuid &&
      isWithinTimeframe(campaign.expiration)
    ) {
      setCanExtend(true);
    }
  }, [invitation, campaign, report]);

  return (
    <Card
      isRounded
      className="dashboard-entry"
    >
      <CardBody>
        {/* main row */}
        <Grid
          hasGutter={true}
          className="main"
        >
          {/* left black box */}
          <GridItem
            sm={12}
            md={12}
            xl={2}
          >
            <Split
              hasGutter={true}
              className="type-label"
            >
              <SplitItem>
                {index && (
                  <Text className="counter">{`${index}`.padStart(2, '0')}</Text>
                )}
              </SplitItem>
              <SplitItem>
                <Text>{t(entryType)}</Text>
              </SplitItem>
            </Split>
          </GridItem>
          <GridItem
            sm={12}
            md={12}
            xl={8}
          >
            <Split
              hasGutter={true}
              className="middle-content"
            >
              {/* assessment titles */}
              <SplitItem isFilled={true}>
                <Stack hasGutter={true}>
                  <StackItem>
                    <List
                      isPlain
                      isBordered
                    >
                      {assessmentsNames.map((assessment, index) => (
                        <ListItem key={index}>
                          <strong>{t(assessment)}</strong>
                        </ListItem>
                      ))}
                    </List>
                  </StackItem>
                  {outcome !== null && (
                    <StackItem>
                      <Split hasGutter={true}>
                        <SplitItem>
                          <span className="label">{t('SCORE')}:</span>
                        </SplitItem>
                        <SplitItem isFilled>
                          <span className="outcome">{outcome.score}%</span>
                        </SplitItem>
                      </Split>
                      <Split hasGutter={true}>
                        <SplitItem>
                          <span className="label">{t('SUGGESTION')}:</span>
                        </SplitItem>
                        <SplitItem isFilled>
                          <span className="outcome">{`${outcome.suggestion.label} - ${outcome.suggestion.name}`}</span>
                        </SplitItem>
                      </Split>
                    </StackItem>
                  )}
                </Stack>
              </SplitItem>
              <SplitItem>
                <Stack>
                  <StackItem>
                    <Split hasGutter={true}>
                      <SplitItem>
                        <span className="label">
                          {invitation && t('EXPIRES ON:')}
                          {campaign &&
                            isInTheFuture(new Date(campaign.expiration)) &&
                            t('EXPIRES ON:')}
                          {campaign &&
                            !isInTheFuture(new Date(campaign.expiration)) &&
                            t('EXPIRED ON:')}
                          {report && t('COMPLETED ON:')}
                        </span>
                      </SplitItem>
                      <SplitItem isFilled>
                        <span className="outcome">
                          {campaign
                            ? new Date(campaign.expiration).toLocaleDateString(
                              localization.currentLanguageCode
                            )
                            : report
                              ? new Date(report.timestamp).toLocaleDateString(
                                localization.currentLanguageCode
                              )
                              : invitation
                                ? new Date(
                                  invitation.expiration
                                ).toLocaleDateString(
                                  localization.currentLanguageCode
                                )
                                : null}
                        </span>
                      </SplitItem>
                    </Split>
                  </StackItem>
                  {participants > 0 && (
                    <StackItem>
                      <Split hasGutter={true}>
                        <SplitItem>
                          <span className="label">{t('PARTICIPANTS')}: </span>
                        </SplitItem>
                        <SplitItem isFilled>
                          <span className="outcome">{participants}</span>
                        </SplitItem>
                      </Split>
                    </StackItem>
                  )}
                  {campaign && isShared && (
                    <StackItem>
                      <Split hasGutter={true}>
                        <SplitItem>
                          <span className="label">{t('OWNED BY: ')}</span>
                        </SplitItem>
                        <SplitItem isFilled>
                          <span className="outcome">
                            {campaign.metadata.owner.name}
                          </span>
                        </SplitItem>
                      </Split>
                    </StackItem>
                  )}
                </Stack>
              </SplitItem>
            </Split>
          </GridItem>
          {/* right buttons */}
          <GridItem
            sm={12}
            md={12}
            xl={2}
            className="additional-info"
          >
            <Stack>
              {/* show results button */}
              {!onlyExtendButton && (
                <StackItem>
                  {report && (
                    <Button
                      isBlock
                      variant="primary"
                      iconPosition="right"
                      onClick={() => {
                        onReportClick(report);
                      }}
                    >
                      {t('Open')}
                    </Button>
                  )}
                  {campaign && !onlyExtendButton && (
                    <Button
                      isBlock
                      variant="primary"
                      iconPosition="right"
                      onClick={() => {
                        onCampaignClick(campaign);
                      }}
                    >
                      {t('Open')}
                    </Button>
                  )}
                  {invitation && (
                    <Button
                      isBlock
                      variant="primary"
                      onClick={() => {
                        if (invitation.sheet_id) {
                          navigate(
                            `/${localization.currentLanguageCode}/assessment/${invitation.sheet_id}?invitation=${invitation.id}`
                          );
                        } else if (invitation.assessment_id) {
                          // TODO: remove me once all team assessments are on v2
                          navigate(
                            `/${localization.currentLanguageCode}/assessment-legacy/${invitation.assessment_id}?invitation=${invitation.id}`
                          );
                        }
                      }}
                    >
                      {t('Take')}
                    </Button>
                  )}
                </StackItem>
              )}
              {/* extend button */}
              {campaign && !isShared && (
                <StackItem>
                  <React.Fragment>
                    <Button
                      isBlock
                      className="extend-exp-button"
                      variant={onlyExtendButton ? 'primary' : 'tertiary'}
                      isDisabled={!canExtend}
                      onClick={() => {
                        showExtensionModal(true);
                      }}
                    >
                      {t('Extend expiration')}
                    </Button>
                    <Modal
                      variant={ModalVariant.small}
                      title={t('Extend expiration')}
                      isOpen={isModalOpen}
                      showClose={false}
                      actions={[
                        <Button
                          key="confirm"
                          variant="primary"
                          isLoading={updatingExpiry}
                          onClick={() => {
                            campaign &&
                              onExtendCampaign(
                                campaign.id,
                                campaign.expiration
                              );
                          }}
                        >
                          {t('Confirm')}
                        </Button>,
                        <Button
                          key="cancel"
                          variant="link"
                          onClick={() => {
                            showExtensionModal(false);
                          }}
                        >
                          {t('Cancel')}
                        </Button>,
                      ]}
                    >
                      {t('This will add')}{' '}
                      <strong>
                        {EXTENSION_TIMEFRAME} {t('days')}
                      </strong>{' '}
                      {t(
                        'to the currently set expiration date for this team assessment. Do you confirm?'
                      )}
                    </Modal>
                  </React.Fragment>
                </StackItem>
              )}
            </Stack>
          </GridItem>
        </Grid>
      </CardBody>
    </Card>
  );
};

export default DashboardEntry;
