// LEGACY, TO BE REMOVED
import { types } from 'mobx-state-tree';
import {
  ActionTypes,
  AssessmentModel,
  IAssessmentModel,
  NodeData
} from '@app/types';

export const AssessmentTrackerStore = types.model('AssessmentTrackerStore', {
  shownAssessment: types.maybeNull(types.reference(AssessmentModel)),
  shownNode: types.maybeNull(types.reference(NodeData)),
  listenersStack: types.array(types.reference(NodeData)),
  userScore: types.map(types.number),
  maximumScore: types.map(types.number),
  completed: types.boolean,
  startedAt: types.number,
  userUpdatedCompany: types.boolean,
  debug: types.array(types.string),
})
  .views((self) => ({
    loadTrackedInLocalStorage(assessmentId: string) {
      return null
    },
  }))
  .actions((self) => ({
    setUserUpdatedCompany(updated) {
      self.userUpdatedCompany = updated;
    },
    load_node(node_id) {
      self.shownNode = node_id;

    },
    add_score(unit_id, value) {
      if (!self.userScore.has(unit_id)) {
        self.userScore.set(unit_id, 0);
      }

      self.userScore.set(unit_id, self.userScore.get(unit_id) + value);
    },
    push_listener(listener_node) {
      self.listenersStack.push(listener_node);
    },
    pop_listener() {
      const popped = self.listenersStack[self.listenersStack.length - 1];
      self.listenersStack.pop();
      return popped;
    },
    set_maximum_for_unit(unit_id, value) {
      let maximumScoreValue = self.maximumScore.get(unit_id) || 0;

      self.maximumScore.set(unit_id, maximumScoreValue += value);
    },
    add_debug(entry){
      self.debug.push(entry);
    },
    clear_debug(){
      self.debug.clear();
    }
  }))
  .actions((self) => ({
    track(assessment: IAssessmentModel | undefined) {
      if (assessment === undefined) console.warn('attempted to track nothing');
      if (assessment) {

        const stored = localStorage.getItem('tracker')
        const json = stored ? JSON.parse(stored) : null;

        if(json && json[assessment.id]){
          const snapshot = json[assessment.id];

          self.shownAssessment = snapshot.shownAssessment;
          self.shownNode = snapshot.shownNode;
          self.listenersStack = snapshot.listenersStack;
          self.completed = snapshot.completed;
          self.startedAt = snapshot.startedAt;
          self.maximumScore = snapshot.maximumScore;
          self.userScore = snapshot.userScore;
        }else{
          self.shownAssessment = assessment.id;
          self.shownNode = self.shownAssessment.nodes[0].id;
          self.listenersStack.clear();
          self.completed = false;
          self.startedAt = new Date().getTime();
          self.maximumScore.clear();
          self.userScore.clear();
        }
      }
    },
    resolve_actions(actions) {
      const resolve = (actions) => {
        actions.forEach(action => {
          if (action.type == ActionTypes.NEXT) {
            self.load_node(action.payload.target);
          } else if (action.type == ActionTypes.SCORE) {
            const unit = action.payload.target;
            const value = action.payload.value;
            self.add_score(unit, value);
          } else if (action.type == ActionTypes.END) {
            self.completed = true;
          } else if (action.type == ActionTypes.EVENT) {
            const listener = self.pop_listener();
            const available_events = listener.body.link.listeners.map(l => l.target);

            if (!listener || !available_events.includes(action.payload.target)) {
              throw new Error('no listener matching the emitted event:' + action.payload.target + ', ' + available_events.join(','));
            }

            const actions = listener.body.link.listeners.find(l => l.target == action.payload.target).actions;
            resolve(actions);
          } else {
            throw new Error('Unsupported action type ' + action.type);
          }
        });
      }

      resolve(actions);
    },
    resolve_maximum_value() {
      const availableScores = {};

      self.shownNode?.body.singlechoice.answers.forEach(answer => {
        answer.actions.forEach(action => {
          if (action.type === ActionTypes.SCORE) {
            if (!availableScores[action.payload.target]) availableScores[action.payload.target] = [];

            // push all available scores for each unit
            availableScores[action.payload.target].push(action.payload.value);
          }
        })
      })

      Object.keys(availableScores).forEach(unit_id => {
        const highestScore = Math.max(...availableScores[unit_id]);
        self.set_maximum_for_unit(unit_id, highestScore) // for each unit pick the maximum
      })
    }
  }));
