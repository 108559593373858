import { unparse } from 'papaparse';
import prettyMilliseconds from 'pretty-ms';
import { downloadZip } from './storage';
import { ICampaignModel } from '@app/types';

interface IParsedTeamReportResults {
  performanceEntries: any[],
  overallScore: number,
  suggestions: any[],
  outcomeConvergence: number,
  avgCompletionTime: string,
  responseRate: number,
  allUsersData: UserData[]
}

export interface UserData {
  identifier: string;
  email: string;
  group: string;
  assessmentName: string;
  assessmentId: string;
  recommendation: string;
  taken: boolean;
  answered: Date | null;
}

const getGroupFromCampaign = (campaign: ICampaignModel, email: string, assessment_id: string) => {
  const target = campaign.metadata.invitations.find(i => i.recipient_email === email && i.assessment_id === assessment_id);

  return target ? target.metadata.user.group : '';
}

export const calculatePerformanceEntries = (reports) => {
  // store cached copies of units and offerings because we cannot
  // rely on the ones from the catalog (they could have changed)
  const aggregated_units = {};
  const aggregated_offerings = {};

  // compose a dict with all the scores from all participants
  reports.forEach(r => {
    r.score.forEach(u => {
      // cache a simplified copy of this unit, we'll need it later
      // on to render the graphs
      if (!aggregated_units[u.name]) {
        aggregated_units[u.name] = {
          id: u.id,
          name: u.name,
          scores: [],
        };
      }

      // push the score of this participant
      aggregated_units[u.name].scores.push(u.score);
    });

    r.suggestions.forEach(o => {
      // cache a simplified copy of this offerings as well
      if (!aggregated_offerings[o.id]) {
        aggregated_offerings[o.id] = {
          id: o.id,
          label: o.label,
          name: o.name,
          href: o.href,
          action: o.action,
          counter: 0, // how many people got this recommendation?
        }
      }

      aggregated_offerings[o.id].counter += 1;
    });
  });

  // for each gap, compute the average percentage value
  // and set the aggregated_units as an ordered array
  // from the weakest skill to the strongest
  const orderedUnits = Object.keys(aggregated_units).map(k => {
    const scores = aggregated_units[k].scores;
    const percentage = scores.reduce((partial, a) => partial + a, 0) / scores.length;

    return {
      name: aggregated_units[k].name,
      value: Math.round(percentage),
    };
  }).sort((a, b) => a.value - b.value);

  // compute the average of all skill units performances
  const performanceRate = Math.round(orderedUnits.reduce((a, c) => a + c.value, 0) / orderedUnits.length)

  return {
    orderedUnits,
    aggregated_offerings,
    performanceRate,
  }

};

export const parseTeamReport = (campaign: any, selection: string[]): IParsedTeamReportResults => {
  const results: IParsedTeamReportResults = {
    performanceEntries: [],
    overallScore: 0,
    suggestions: [],
    avgCompletionTime: 'TBD',
    outcomeConvergence: 0,
    responseRate: 0,
    allUsersData: []
  }

  const targetReports = selection.length ?
    campaign.reports.filter(r => selection.includes(r.id))
    : campaign.reports;

  const { orderedUnits, aggregated_offerings, performanceRate } = calculatePerformanceEntries(targetReports);

  results.performanceEntries = orderedUnits;

  results.overallScore = performanceRate;

  // set suggestions as an ordered array from the most suggested
  // offering to the last
  const allSuggestions = Object.keys(aggregated_offerings).map(k => {
    return aggregated_offerings[k];
  }).sort((a, b) => b.counter - a.counter);

  if (allSuggestions.length > 0) {
    const firstThreeSuggestions = allSuggestions.slice(0, 3);
    results.suggestions = firstThreeSuggestions;

    // compute confidence rate
    const topSuggestionCounter = allSuggestions[0].counter;
    const allOfThemCounter = allSuggestions.reduce((a, c) => a + c.counter, 0);

    results.outcomeConvergence = Math.round(topSuggestionCounter * 100 / allOfThemCounter);
  }

  results.avgCompletionTime = targetReports.length ? prettyMilliseconds(
    Math.round(targetReports.reduce((a, c) => a + c.statistics.duration, 0) / targetReports.length)
  ) : 'TBD';

  results.allUsersData = parseUserData(targetReports, campaign)

  results.responseRate = selection.length ? 100 : Math.round(
    results.allUsersData.filter(e => e.taken === true).length * 100 / results.allUsersData.length
  )

  return results;
}

export const parseUserData = (targetReports: any, campaign: any) => {
  return targetReports.map(r => {
    const group = getGroupFromCampaign(
      campaign,
      r.metadata.user.email,
      r.metadata.assessment.id
    );

    return {
      identifier: r.metadata.user.fullname,
      email: r.metadata.user.email,
      report_id: r.id,
      group: group ? group : '',
      assessmentName: r.metadata.assessment.name,
      assessmentId: r.metadata.assessment.id,
      taken: true,
      answered: r.timestamp,
      // we only get the first one, because that's the one we're really suggesting
      recommendation: `${r.suggestions[0].label} - ${r.suggestions[0].name}`
    }
  }).concat(campaign.metadata.invitations
    .filter(i => !campaign.reports.find(r => r.metadata.assessment.id === i.metadata.assessment.id && r.metadata.user.email === i.metadata.user.email))
    .map(i => {
      const group = getGroupFromCampaign(
        campaign,
        i.metadata.user.email,
        i.metadata.assessment.id
      );
      return {
        identifier: i.metadata.user.email,
        email: i.metadata.user.email,
        invitation_id: i.id,
        group: group ? group : '',
        assessmentName: i.metadata.assessment.name,
        assessmentId: i.metadata.assessment.id,
        taken: false,
        answered: null
      }
    })
  );
}

export const downloadReportZip = async (
  performanceEntries,
  overallScore,
  outcomeConvergence,
  avgCompletionTime,
  responseRate,
  suggestions,
  allUsersData: UserData[],
  languageCode: string
) => {
  const unitMetrics = performanceEntries.map(p => [p.name, `${p.value}%`]);
  const assessmentMetrics = [
    ['Team score', `${overallScore}%`],
    ['Outcome confidence', `${outcomeConvergence}%`],
    ['Average completion time', avgCompletionTime],
    ['Response rate', `${responseRate}%`]
  ]

  const metricsContent = {
    fields: [
      'metric name',
      'value'
    ],
    data: [...unitMetrics, ...assessmentMetrics]
  }

  const suggestionsContent = {
    fields: [
      'ranking',
      'label',
      'name',
      'link'
    ],
    data: suggestions.map((s, i) => [
      i + 1,
      s.label,
      s.name,
      s.href
    ])
  }
  const metricsCsv = unparse(metricsContent);
  const suggestionsCsv = unparse(suggestionsContent);

  const zipContent = [
    {
      filename: 'metrics.csv',
      data: new Blob([metricsCsv], { type: 'text/csv' })
    },
    {
      filename: 'suggestions.csv',
      data: new Blob([suggestionsCsv], { type: 'text/csv' })
    },

  ];

  if (allUsersData) {
    const participantsContent = {
      fields: [
        'identifier',
        'group',
        'assessment',
        'recommendation',
        'taken',
        'answered on',
      ],
      data: allUsersData.map((user) => {
        return [
          user.identifier,
          user.group,
          user.assessmentName,
          user.recommendation,
          user.taken,
          user.answered
            ? new Date(user.answered).toLocaleDateString(languageCode)
            : '',
        ];
      }),
    };

    const participantsCsv = unparse(participantsContent);
    zipContent.push({
      filename: 'participants.csv',
      data: new Blob([participantsCsv], { type: 'text/csv' }),
    });
  }

  await downloadZip(`rh-teamreport-${Date.now()}.zip`, zipContent);
};