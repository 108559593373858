import { create } from 'zustand';
import { IBlueprint } from '@app/types';

interface Participant {
  _valid: boolean;
  email: string;
  group: string;
  assessment: string;
}

interface CreatorStoreState {
  participants: Participant[];
  expiry: Date | null;
  valid: boolean;
  sanitizedParticipants: () => Participant[];
  participantsByGroup: () => { group: string; members: string[] }[];
  participantsByAssessment: () => {
    assessment: string;
    participants: string[];
  }[];
  assessmentsByParticipant: () => {
    participant: string;
    assessments: string[];
  }[];
  textFormattedExpiry: () => string | undefined;
  statistics: () => {
    numberOfAssessment: number;
    numberOfParticipants: number;
    averageAssessmentsPerParticipant: string;
    expiresOn: string | undefined;
  };
  onParticipantChange: () => void;
  setDefaultExpiry: () => void;
  updateParticipant: (index: number, value: Participant) => void;
  removeParticipant: (index: number) => void;
  duplicateParticipant: (index: number) => void;
  setExpiry: (d: Date | null) => void;
  batchLoad: (batch: Participant[]) => void;
  reset: () => void;
}

function validateParticipant(
  email: string,
  assessment: any,
  availableAssessments: IBlueprint[]
): boolean {
  return !!(
    email.length &&
    validateEmail(email) &&
    assessment.length &&
    availableAssessments.map((a) => a.sku).includes(assessment)
  );
}

function validateEmail(email: string): RegExpMatchArray | null {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

const CreatorStore = create<CreatorStoreState>((set, get) => ({
  participants: [],
  expiry: null,
  valid: false,
  sanitizedParticipants() {
    return get()
      .participants.filter((p) => {
        return p.email.length && p.assessment.length;
      })
      .filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.email === value.email && t.assessment === value.assessment
          )
      );
  },
  participantsByGroup() {
    const byGroup = {};

    get()
      .sanitizedParticipants()
      .forEach((p) => {
        if (!byGroup[p.group]) {
          byGroup[p.group] = [];
        }

        if (!byGroup[p.group].includes(p.email)) {
          byGroup[p.group].push(p.email);
        }
      });

    return Object.keys(byGroup).map((k) => {
      return {
        group: k,
        members: byGroup[k],
      };
    });
  },
  participantsByAssessment() {
    const byAssessment = {};

    get()
      .sanitizedParticipants()
      .forEach((p) => {
        if (!byAssessment[p.assessment]) {
          byAssessment[p.assessment] = [];
        }

        if (!byAssessment[p.assessment].includes(p.email)) {
          byAssessment[p.assessment].push(p.email);
        }
      });

    return Object.keys(byAssessment).map((k) => {
      return {
        assessment: k,
        participants: byAssessment[k],
      };
    });
  },
  assessmentsByParticipant() {
    const byParticipant = {};

    get()
      .sanitizedParticipants()
      .forEach((p) => {
        if (!byParticipant[p.email]) {
          byParticipant[p.email] = [];
        }

        if (!byParticipant[p.email].includes(p.assessment)) {
          byParticipant[p.email].push(p.assessment);
        }
      });

    return Object.keys(byParticipant).map((k) => {
      return {
        participant: k,
        assessments: byParticipant[k],
      };
    });
  },
  textFormattedExpiry() {
    const expiry = get().expiry;

    if (!expiry) return undefined;

    const d = new Date(
      Date.UTC(
        expiry.getFullYear(),
        expiry.getMonth(),
        expiry.getDate(),
        0,
        0,
        0,
        0
      )
    );

    let month = '' + (d.getUTCMonth() + 1);
    let day = '' + d.getUTCDate();
    const year = '' + d.getUTCFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  },
  statistics() {
    const numberOfAssessments = Object.keys(
      get().participantsByAssessment()
    ).length;
    const numberOfParticipants = Object.keys(
      get().assessmentsByParticipant()
    ).length;
    const numberOfAssignedAssessments = get()
      .assessmentsByParticipant()
      .map((group) => group.assessments.length)
      .reduce((t, c) => {
        return (t += c);
      }, 0);

    return {
      numberOfAssessment: numberOfAssessments,
      numberOfParticipants: numberOfParticipants,
      averageAssessmentsPerParticipant: (
        numberOfAssignedAssessments / numberOfParticipants
      ).toFixed(1),
      expiresOn: get().textFormattedExpiry(),
    };
  },
  onParticipantChange() {
    const participants1 = [...get().participants];
    const participantsLength = participants1.length;
    // add new row at the bottom if needed
    if (
      !participantsLength ||
      participants1[participantsLength - 1].email != '' ||
      participants1[participantsLength - 1].group != '' ||
      participants1[participantsLength - 1].assessment != ''
    ) {
      participants1.push({
        _valid: false,
        email: '',
        group: '',
        assessment: '',
      });

      set({
        participants: participants1,
      });
    }

    const onlyValidEntries = participants1.filter((p) => p._valid);

    set({
      valid: !!(
        onlyValidEntries.length > 0 &&
        onlyValidEntries.length === participantsLength - 1
      ),
    });
  },
  setDefaultExpiry() {
    const DEFAULT_WEEKS_IN_FUTURE = 8;

    if (!get().expiry) {
      const d = new Date();
      d.setUTCHours(0, 0, 0, 0);
      d.setDate(d.getDate() + 7 * DEFAULT_WEEKS_IN_FUTURE);
      set({
        expiry: d,
      });
    }
  },
  updateParticipant(index, value) {
    const participants1 = [...get().participants];

    if (typeof participants1[index] === 'undefined') {
      throw Error('Tried to edit a non-existent participant');
    }

    participants1[index]._valid = value._valid;
    participants1[index].email = value.email.toLowerCase();
    participants1[index].group = value.group;
    participants1[index].assessment = value.assessment;

    set({
      participants: participants1,
    });
  },
  removeParticipant(index) {
    const participants1 = [...get().participants];
    if (typeof participants1[index] === 'undefined') {
      throw Error('Tried to delete a non-existent participant');
    }

    participants1.splice(index, 1);

    set({
      participants: participants1,
    });

  },
  duplicateParticipant(index) {
    const participants1 = [...get().participants];

    if (typeof participants1[index] === 'undefined') {
      throw Error('Tried to duplicate a non-existent participant');
    }

    const target = participants1.slice(index, index + 1);
    participants1.splice(index + 1, 0, {
      email: target[0].email,
      group: target[0].group,
      assessment: '',
      _valid: false,
    });
    set({
      participants: participants1,
    });

  },
  setExpiry: (d) => set({ expiry: d }),
  batchLoad(batch) {
    const pp: Participant[] = batch.map((entry) => ({
      _valid: entry._valid,
      email: entry.email,
      group: entry.group,
      assessment: entry.assessment,
    }));

    set({
      participants: pp,
    });
  },
  reset() {
    set({
      participants: [],
      expiry: null,
      valid: false,
    }),
    get().setDefaultExpiry();
  },
}));

export { CreatorStore, validateParticipant, validateEmail };
