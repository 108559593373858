import * as React from 'react';
import {
  Panel, PanelMain, PanelMainBody, PanelHeader, Text,
  Button,
  Title
} from '@patternfly/react-core';
import { ArrowRightIcon } from '@patternfly/react-icons';
import './OfferingCard.scss';
import { LocalizationStore } from '@app/state/LocalizationStore';
import { IOfferingModel } from '@app/types';

interface IResultBox {
  offering: IOfferingModel;
  color?: number;
  showDescription?: boolean;
}

const ResultBox: React.FC<IResultBox> = ({ offering, color = 1, showDescription = true }) => {
  const localization = LocalizationStore();
  const { t } = localization.useTranslation();
  
  return (
    <Panel className={`offering-card color-${color}`}>
      <PanelHeader>{t(offering.label)}</PanelHeader>
      <PanelMain>
        <PanelMainBody>
          <Title headingLevel='h3'>{t(offering.name)}</Title>
          { showDescription && <Text className="summary">
            {t(offering.summary)}
          </Text> }

          {offering.href && offering.action && <Button
            variant="link"
            isLarge
            icon={<ArrowRightIcon />}
            iconPosition="right"
            target="_blank"
            component="a"
            href={offering.href ? offering.href : '#'}
          >
            {t(offering.action)}
          </Button>}
        </PanelMainBody>
      </PanelMain>
    </Panel>
  )
};

export default ResultBox;