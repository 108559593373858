import { useState } from 'react';

const usePagination = (data) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [shownData, setShownData] = useState(data);

  const startSlicing = (page - 1) * perPage;
  const endSlicing = startSlicing + perPage;

  const onPerPageSelect = (_event, value) => {
    setPerPage(value);
  };

  const onSetPage = (_event, value) => {
    setPage(value);
  };

  const onChangePage = (
    _: React.SyntheticEvent<HTMLButtonElement>,
    page: number
  ): void => {
    const start = (page - 1) * perPage;
    const end = start + perPage;
    setShownData(data.slice(start, end));
  };

  return {
    page,
    perPage,
    onPerPageSelect,
    onSetPage,
    onChangePage,
    shownData,
    setShownData,
    startSlicing,
    endSlicing,
    setPage
  };
};

export default usePagination;
