import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalVariant,
  Form,
  FormGroup,
  TextInput,
  Select,
  SelectOption,
  SelectVariant,
} from '@patternfly/react-core';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import schema from './schema.yup';
import { LocalizationStore } from '@app/state/LocalizationStore';

interface InvitationFormProps {
  onSubmit: SubmitHandler<{
    group?: string | undefined;
    email: string;
    assessmentId: string;
  }>;
  emailsForValidation: string[];
  selectData: {
    key: string;
    value: string;
    isPlaceholder?: boolean;
  }[];
  isInvitationModalOpen: boolean;
    setIsInvitationModalOpen: (value: React.SetStateAction<boolean>) => void;
}

const InvitationForm: React.FC<InvitationFormProps> = ({
  onSubmit,
  emailsForValidation,
  selectData,
  isInvitationModalOpen,
  setIsInvitationModalOpen
}) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const localization = LocalizationStore();
  const { t } = localization.useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema(emailsForValidation)),
    defaultValues: {
      assessmentId: selectData.length === 1 ? selectData[0].key : ''
    }
  });

  const onClose = () => {
    reset();
    setIsInvitationModalOpen(false);
  };

  const selectContent = [
    {
      key: 'no-value',
      value: t('Select an assessment for this user'),
      isPlaceholder: true
    },
    ...selectData
  ]

  return (
    <Modal
      variant={ModalVariant.small}
      title={t('Invite a new participant')}
      isOpen={isInvitationModalOpen}
      description={t(
        'Here you should type the email from the person that you would to invite, and also select and assessment for them to take'
      )}
      showClose={false}
      actions={[
        <Button
          key="cancel"
          variant="tertiary"
          onClick={onClose}
          isDisabled={isSubmitting}
        >
          {t('Cancel')}
        </Button>,
        <Button
          key="confirm"
          variant="primary"
          type="submit"
          form="invitation-form"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        >
          {t('Confirm')}
        </Button>,
      ]}
    >
      <Form
        onSubmit={handleSubmit(onSubmit)}
        id="invitation-form"
      >
        <FormGroup
          fieldId="email"
          label="Email"
          isRequired
          validated={errors?.email?.message ? 'error' : undefined}
          helperTextInvalid={errors?.email?.message ? t(errors.email.message) : undefined}
        >
          <TextInput
            id="email"
            type="text"
            isRequired
            validated={errors?.email?.message ? 'error' : undefined}
            placeholder="johndoe@mail.com"
            {...(register('email'),
            {
              onChange: (value) =>
                setValue('email', value, { shouldValidate: true }),
            })}
          />
        </FormGroup>

        <FormGroup
          fieldId="group"
          label={t('Group')}
        >
          <TextInput
            id="group"
            type="text"
            placeholder={t('Marketing team')}
            {...(register('group'),
            {
              onChange: (value) => setValue('group', value),
            })}
          />
        </FormGroup>

        <FormGroup
          label={t('Select an assessment')}
          isRequired
          fieldId="assessmentId"
          validated={errors?.assessmentId?.message ? 'error' : undefined}
          helperTextInvalid={errors?.assessmentId?.message ? t(errors.assessmentId.message) : undefined}
        >
          <Select
            id="assessmentId"
            validated={errors?.assessmentId?.message ? 'error' : undefined}
            variant={SelectVariant.single}
            selections={watch('assessmentId')}
            isOpen={isSelectOpen}
            menuAppendTo="parent"
            onToggle={(isExpanded) => setIsSelectOpen(isExpanded)}
            onSelect={(event, value, isPlaceholder) => {
              if (!isPlaceholder) {
                setValue('assessmentId', value.toString(), { shouldValidate: true });
                setIsSelectOpen(false);
              }
            }}
            {...register('assessmentId')}
          >
            {selectContent.map((option, index) => (
              <SelectOption
                key={index}
                value={option.key}
                isPlaceholder={option.isPlaceholder}
              >
                {option.value}
              </SelectOption>
            ))}
          </Select>
        </FormGroup>
      </Form>
    </Modal>
  );
};

export default InvitationForm;
