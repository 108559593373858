import * as React from 'react';
import '@rhds/elements/rh-footer/rh-footer.js';
import '@rhds/elements/rh-footer/rh-footer-lightdom.css';
import './Footer.scss'

import { LocalizationStore } from '@app/state/LocalizationStore';

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    for?: string;
    'xml:lang'?: string;
    hreflang?: string;
  }
}
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'rh-footer': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      'rh-footer-social-link': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & { icon: any; };
      'rh-footer-block': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      'rh-cta': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      'rh-global-footer': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      'rh-footer-copyright': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

export const Footer = (): React.ReactElement => {
  const localization = LocalizationStore();
  const { t } = localization.useTranslation();
  const BASE_URL = `https://redhat.com/${localization.currentLanguageCode}`

  return (<rh-footer data-analytics-region="page-footer">
    <a
      slot="logo"
      style={{ width: '180px' }}
      href={BASE_URL}
      data-analytics-category="Footer"
      data-analytics-text="Logo">
      <img alt="Red Hat logo"

        src="https://static.redhat.com/libs/redhat/brand-assets/2/corp/logo--on-dark.svg"
        loading="lazy" />
    </a>
    <rh-footer-social-link slot="social-links" icon="web-icon-linkedin">
      <a href="https://www.linkedin.com/company/red-hat" className="">
        <pfe-icon icon="web-icon-linkedin" pfe-size="md" aria-hidden="true"></pfe-icon>
      </a>
      {/* <a href="https://www.linkedin.com/company/red-hat" data-analytics-region="social-links-exit" data-analytics-category="Footer|social-links" data-analytics-text="LinkedIn">LinkedIn</a></rh-footer-social-link> */
      }
    </rh-footer-social-link>
    <rh-footer-social-link slot="social-links" icon="web-icon-youtube">
      {/* <a href="https://www.youtube.com/user/RedHatVideos"
      data-analytics-region="social-links-exit" data-analytics-category="Footer|social-links"
      data-analytics-text="YouTube">YouTube</a> */}
      <a href="https://www.youtube.com/user/RedHatVideos" className="">
        <pfe-icon icon="web-icon-youtube" pfe-size="md" aria-hidden="true"></pfe-icon>
      </a>
    </rh-footer-social-link>
    <rh-footer-social-link slot="social-links" icon="web-icon-facebook">
      {/* <a href="https://www.facebook.com/redhatinc" data-analytics-region="social-links-exit"
      data-analytics-category="Footer|social-links" data-analytics-text="Facebook">Facebook</a> */}
      <a href="https://www.facebook.com/redhatinc" className="">
        <pfe-icon icon="web-icon-facebook" pfe-size="md" aria-hidden="true"></pfe-icon>
      </a>
    </rh-footer-social-link>
    <rh-footer-social-link slot="social-links" icon="web-icon-twitter">
      {/* <a href="https://twitter.com/RedHat" data-analytics-region="social-links-exit"
      data-analytics-category="Footer|social-links" data-analytics-text="Twitter">Twitter</a> */}
      <a href="https://twitter.com/RedHat" className="">
        <pfe-icon icon="web-icon-twitter" pfe-size="md" aria-hidden="true"></pfe-icon>
      </a>
    </rh-footer-social-link>
    <h3 slot="links" data-analytics-text="Products">{t('Products')}</h3>
    <ul slot="links">
      <li><a href={`${BASE_URL}/technologies/linux-platforms/enterprise-linux`} data-analytics-category="Footer|Products" data-analytics-text="Red Hat Enterprise Linux">{t('Red Hat Enterprise Linux')}</a></li>
      <li><a href={`${BASE_URL}/technologies/cloud-computing/openshift`} data-analytics-category="Footer|Products" data-analytics-text="Red Hat OpenShift">{t('Red Hat OpenShift')}</a></li>
      <li><a href={`${BASE_URL}/technologies/management/ansible`} data-analytics-category="Footer|Products" data-analytics-text="Red Hat Ansible Automation Platform">{t('Red Hat Ansible Automation Platform')}</a></li>
      <li><a href={`${BASE_URL}/technologies/cloud-computing/openshift/cloud-services`} data-analytics-category="Footer|Products" data-analytics-text="Cloud services">{t('Cloud services')}</a></li>
      <li><a href={`${BASE_URL}/technologies/all-products`} data-analytics-category="Footer|Products" data-analytics-text="See all products">{t('See all products')}</a></li>
    </ul>
    <h3 slot="links" data-analytics-text="Tools">{t('Tools')}</h3>
    <ul slot="links">
      <li><a href="https://sso.redhat.com" data-analytics-category="Footer|Tools" data-analytics-text="My account">{t('My account')}</a></li>
      <li><a href={`${BASE_URL}/services/training-and-certification`} data-analytics-category="Footer|Tools" data-analytics-text="Training and certification">{t('Training and certification')}</a></li>
      <li><a href="https://access.redhat.com" data-analytics-category="Footer|Tools" data-analytics-text="Customer support">{t('Customer support')}</a></li>
      <li><a href="https://developers.redhat.com/" data-analytics-category="Footer|Tools" data-analytics-text="Developer resources">{t('Developer resources')}</a></li>
      <li><a href="https://learn.redhat.com/" data-analytics-category="Footer|Tools" data-analytics-text="Learning community">{t('Learning community')}</a></li>
      <li><a href="https://connect.redhat.com/" data-analytics-category="Footer|Tools" data-analytics-text="Partner resources">{t('Partner resources')}</a></li>
      <li><a href={`${BASE_URL}/resources`} data-analytics-category="Footer|Tools" data-analytics-text="Resource library">{t('Resource library')}</a></li>
    </ul>
    <h3 slot="links" data-analytics-text="Try buy sell">{t('Try, buy & sell')}</h3>
    <ul slot="links">
      <li><a href={`${BASE_URL}/products/trials`} data-analytics-category="Footer|Try buy sell" data-analytics-text="Product trial center">{t('Product trial center')}</a></li>
      <li><a href="https://marketplace.redhat.com" data-analytics-category="Footer|Try buy sell" data-analytics-text="Red Hat Marketplace">{t('Red Hat Marketplace')}</a></li>
      <li><a href="https://catalog.redhat.com/" data-analytics-category="Footer|Tools" data-analytics-text="Red Hat Ecosystem Catalog">{t('Red Hat Ecosystem Catalog')}</a></li>
      <li><a href="http://redhat.force.com/finder/" data-analytics-category="Footer|Try buy sell" data-analytics-text="Find a partner">{t('Find a partner')}</a></li>
      <li><a href="https://www.redhat.com/en/store" data-analytics-category="Footer|Try buy sell" data-analytics-text="Red Hat Store">{t('Red Hat Store')}</a></li>
      <li><a href="https://cloud.redhat.com/" data-analytics-category="Footer|Tools" data-analytics-text="Console">{t('Console')}</a></li>
    </ul>
    <h3 slot="links" data-analytics-text="Communicate">{t('Communicate')}</h3>
    <ul slot="links">
      <li><a href={`${BASE_URL}/services/consulting-overview#contact-us`} data-analytics-category="Footer|Communicate" data-analytics-text="Contact consulting">{t('Contact consulting')}</a></li>
      <li><a href={`${BASE_URL}/contact`} data-analytics-category="Footer|Communicate" data-analytics-text="Contact sales">{t('Contact sales')}</a></li>
      <li><a href={`${BASE_URL}/services/training-and-certification/contact-us`} data-analytics-category="Footer|Communicate" data-analytics-text="Contact training">{t('Contact training')}</a></li>
      <li><a href={`${BASE_URL}/about/social`} data-analytics-category="Footer|Communicate" data-analytics-text="Social">{t('Social')}</a></li>
    </ul>
    <rh-footer-block slot="main-secondary">
      <h3 slot="header" data-analytics-text="About Red Hat">{t('About Red Hat')}</h3>
      <p> {t('We’re the world’s leading provider of enterprise open source solutions—including Linux, cloud, container, and Kubernetes. We deliver hardened solutions that make it easier for enterprises to work across platforms and environments, from the core datacenter to the network edge.')}</p>
    </rh-footer-block>

    <rh-global-footer slot="global">
      <h3 slot="links-primary" data-analytics-text="Red Hat legal and privacy links" hidden>{t('Red Hat legal and privacy links')}</h3>
      <ul slot="links-primary" data-analytics-region="page-footer-bottom-primary">
        <li><a href={`${BASE_URL}/about/company`} data-analytics-category="Footer|Corporate" data-analytics-text="About Red Hat">{t('About Red Hat')}</a></li>
        <li><a href={`${BASE_URL}/jobs`} data-analytics-category="Footer|Corporate" data-analytics-text="Jobs">{t('Jobs')}</a></li>
        <li><a href={`${BASE_URL}/events`} data-analytics-category="Footer|Corporate" data-analytics-text="Events">{t('Events')}</a></li>
        <li><a href={`${BASE_URL}/about/office-locations`} data-analytics-category="Footer|Corporate" data-analytics-text="Locations">{t('Locations')}</a></li>
        <li><a href={`${BASE_URL}/contact`} data-analytics-category="Footer|Corporate" data-analytics-text="Contact Red Hat">{t('Contact Red Hat')}</a></li>
        <li><a href={`${BASE_URL}/blog`} data-analytics-category="Footer|Corporate" data-analytics-text="Red Hat Blog">{t('Red Hat Blog')}</a></li>
        <li><a href={`${BASE_URL}/about/our-culture/diversity-equity-inclusion/statement`} data-analytics-category="Footer|Corporate" data-analytics-text="Diversity equity and inclusion">{t('Diversity, equity, and inclusion')}</a></li>
        <li><a href={'https://coolstuff.redhat.com/'} data-analytics-category="Footer|Corporate" data-analytics-text="Cool Stuff Store">{t('Cool Stuff Store')}</a></li>
        <li><a href={'https://www.redhat.com/en/summit'} data-analytics-category="Footer|Corporate" data-analytics-text="Red Hat Summit">{t('Red Hat Summit')}</a></li>
      </ul>
      <rh-footer-copyright slot="links-secondary">© 2022 Red Hat, Inc.</rh-footer-copyright>
      <h3 slot="links-secondary" data-analytics-text="Red Hat legal and privacy links" hidden>{t('Red Hat legal and privacy links')}</h3>
      <ul slot="links-secondary" data-analytics-region="page-footer-bottom-secondary">
        <li><a href={`${BASE_URL}/about/privacy-policy`} data-analytics-category="Footer|Red Hat legal and privacy links" data-analytics-text="Privacy statement">{t('Privacy statement')}</a></li>
        <li><a href={`${BASE_URL}/about/terms-use`} data-analytics-category="Footer|Red Hat legal and privacy links" data-analytics-text="Terms of use">{t('Terms of use')}</a></li>
        <li><a href={`${BASE_URL}/about/all-policies-guidelines`} data-analytics-category="Footer|Red Hat legal and privacy links" data-analytics-text="All policies and guidelines">{t('All policies and guidelines')}</a></li>
        <li><a href={`${BASE_URL}/about/digital-accessibility`} data-analytics-category="Footer|Red Hat legal and privacy links" data-analytics-text="Digital accessibility" className="active">{t('Digital accessibility')}</a></li>
      </ul>
    </rh-global-footer>
  </rh-footer>
  )
};
