import * as React from 'react';
import { ExclamationTriangleIcon } from '@patternfly/react-icons';
import {
  PageSection,
  Title,
  Button,
  EmptyState,
  EmptyStateIcon,
  EmptyStateBody,
} from '@patternfly/react-core';
import { useNavigate } from 'react-router-dom';
import './NotFound.scss';
import { LocalizationStore } from '@app/state/LocalizationStore';

const NotFound: React.FunctionComponent = () => {
  const localization = LocalizationStore();
  const { t } = localization.useTranslation();

  function GoHomeBtn() {
    const navigate = useNavigate();
    function handleClick() {
      navigate(`/${localization.currentLanguageCode}`);
    }
    return <Button onClick={handleClick}>{t('Go to the home page')}</Button>;
  }

  return (
    <PageSection
      isWidthLimited={true}
      isCenterAligned={true}
      className="not-found"
    >
      <EmptyState>
        <EmptyStateIcon icon={ExclamationTriangleIcon} />
        <Title
          aria-label={t('Page not found')}
          headingLevel="h1"
          size="lg"
        >
          {t('Page not found')}
        </Title>
        <EmptyStateBody>
          {t(
            'We didn\'t find the page you\'re looking for. If you think this is an error, please contact Red Hat Support'
          )}
        </EmptyStateBody>
        <GoHomeBtn />
      </EmptyState>
    </PageSection>
  );
};

export default NotFound;
