// LEGACY, TO BE REMOVED
import React, { useState } from 'react';
import { useMst } from '@app/state/RootStore';
import { Modal, Radio, ModalVariant, Text, PageSection, Button } from '@patternfly/react-core';
import { PageControls } from '@app/components/PageControls/PageControls';
import { observer } from 'mobx-react-lite';
import { ISingleChoiceModel } from '@app/types';
import './SingleChoiceQuestion.scss';
import Backticks from '../Backticks/Backticks';
import { LocalizationStore } from '@app/state/LocalizationStore';

const SingleChoiceQuestion: React.FC = observer((): React.ReactElement => {
  const { catalog, tracker } = useMst();
  const localization = LocalizationStore();
  const { t } = localization.useTranslation();
  const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (_: boolean, event: React.BaseSyntheticEvent) => {
    setSelectedAnswer(parseInt(event.target.dataset.index));
  }

  const onRestartCallback = () => {
    closeModal()
    const assessmentId = tracker.shownAssessment.id;
    const stored = localStorage.getItem('tracker');

    if (stored) {
      const json = JSON.parse(stored);

      if (json[assessmentId]) {
        delete json[assessmentId];

        localStorage.setItem('tracker', JSON.stringify(json));

        //window.location.reload();
        tracker.track(catalog.get_assessment(assessmentId));
      }
    }Function
  }

  const onNextCallback = () => {
    if (selectedAnswer >= tracker.shownNode.body.singlechoice.answers.length) {
      throw new Error('Invalid answer index provided');
    }

    const actions = tracker.shownNode.body.singlechoice.answers[selectedAnswer].actions;
    tracker.resolve_maximum_value();
    tracker.resolve_actions(actions);
    setSelectedAnswer(0);
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return tracker.shownNode && (
    <React.Fragment>
      <Modal
        variant={ModalVariant.small}
        title={t('Are you sure?')}
        isOpen={isModalOpen}
        showClose={false}
        actions={[
          <Button
            key="confirm"
            onClick={onRestartCallback}
          >
            {t('Yes')}
          </Button>,
          <Button key="cancel" variant="link" onClick={closeModal}>
            {t('Cancel')}
          </Button>
        ]}
      >
        {t('Restarting will discard all your progress with this assessment. Are you sure?')}
      </Modal>
      <PageSection
        isWidthLimited={true}
        isCenterAligned={true}
        className="single-question"
      >
        <Text className="question-text">
          <Backticks source={t(
            (tracker.shownNode.body.singlechoice as ISingleChoiceModel).message
          )} />
        </Text>

        {(tracker.shownNode.body.singlechoice as ISingleChoiceModel).answers.map((answer, index) => (
          <Radio
            aria-label={t(answer.message)}
            id={'answer-' + index}
            className="question-answer"
            name={'answer-choice'}
            key={index}
            data-index={index}
            onChange={handleChange}
            label={<Backticks source={t(answer.message)} />}
            isChecked={index === selectedAnswer}
          />
        ))}
      </PageSection>
      <PageSection
        isWidthLimited={true}
        isCenterAligned={true}
        className="page-controls"
      >
        <PageControls onRight={onNextCallback} rightLabel={t('Next')} onLeft={() => setIsModalOpen(true)} leftLabel={t('Restart')} />
      </PageSection>
    </React.Fragment>
  )
});

export default SingleChoiceQuestion;
