// LEGACY, TO BE REMOVED
import { types } from 'mobx-state-tree';
import {
  AssessmentModel, IAssessmentModel, OfferingModel, UnitModel,
  JobRoleModel, GoalModel, ProductModel
} from '@app/types';
import { gql } from '@apollo/client';
import { client } from '@app/services/ApolloClient';

export const PULL_CATALOG = gql`
query CatalogQuery {
  skill_assessments(
    order_by: {
      priority: desc
    }
  ) {
    id
    state
    priority
    name
    metadata
    sku
    summary
    products {
      product {
        id
        icon
        name
      }
    }
    jobroles {
      jobrole {
        id
        icon
        name
      }
    }
    goals {
      goal {
        id
        icon
        name
      }
    }
    nodes {
      id
      body
      position
      type
    }
  },
  skill_offerings {
    id
    name
    label
    summary
    action
    href
  },
  skill_units {
    id
    name
    providers
  },
  skill_jobroles {
    id
    name
    icon
  },
  skill_goals {
    id
    name
    icon
  },
  skill_products {
    id
    name
    icon
  }
}
`;

export const CatalogStore = types.model('CatalogStore', {
  // if this becomes a performance issue we'll change this to a string
  // and serialize/deserialize to set and read the values
  assessments: types.optional(types.array(AssessmentModel), []),
  ready: types.boolean,
  offerings: types.optional(types.array(OfferingModel), []),
  units: types.optional(types.array(UnitModel), []),
  jobroles: types.optional(types.array(JobRoleModel), []),
  goals: types.optional(types.array(GoalModel), []),
  products: types.optional(types.array(ProductModel), []),
})
  .views((self) => ({
    get deployedAssessments(): IAssessmentModel[] {
      return self.assessments.filter(a => a.state == 'deployed');
    },

    get deployedProducts() {
      const aggregated = {};
      self.assessments.forEach(a => {
        if (a.products.length) {
          a.products.forEach(e => {
            aggregated[e.id] = true;
          })
        }
      });

      return self.products.filter(p => aggregated[p.id]);
    },

    get deployedJobroles() {
      const aggregated = {};
      self.assessments.forEach(a => {
        if (a.jobroles.length) {
          a.jobroles.forEach(e => {
            aggregated[e.id] = true;
          })
        }
      });

      return self.jobroles.filter(p => aggregated[p.id]);
    },

    get deployedGoals() {
      const aggregated = {};
      self.assessments.forEach(a => {
        if (a.goals.length) {
          a.goals.forEach(e => {
            aggregated[e.id] = true;
          })
        }
      });

      return self.goals.filter(p => aggregated[p.id]);
    },
  }))
  .actions((self) => ({
    loadData(assessments, offerings, units, jobroles, goals, products) {
      self.assessments = assessments;
      self.offerings = offerings;
      self.units = units;
      self.jobroles = jobroles;
      self.goals = goals;
      self.products = products;

      self.ready = true;
    },
  }))
  .actions((self) => ({
    load() {
      client.query({
        query: PULL_CATALOG
      }).then((res) => {
        self.loadData(
          res.data.skill_assessments,
          res.data.skill_offerings,
          res.data.skill_units,
          res.data.skill_jobroles,
          res.data.skill_goals,
          res.data.skill_products
        )
      });
    },
    get_assessment(uuid) {
      return self.assessments.find(a => a.id === uuid);
    },
    get_assessment_by_sku(sku) {
      return self.assessments.find(a => a.state === 'deployed' && a.sku === sku);
    },
    get_unit(uuid) {
      return self.units.find(u => u.id === uuid);
    },
    get_offering(uuid) {
      return self.offerings.find(o => o.id === uuid);
    },
  }));
