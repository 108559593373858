import React, { useState } from 'react';
import {
  Alert,
  Grid,
  GridItem,
  PageSection,
  Text,
  Card,
  CardTitle,
  CardBody,
  List,
  ListItem,
} from '@patternfly/react-core';
import { useNavigate } from 'react-router-dom';
import PageHeader from '@app/components/PageHeader/PageHeader';
import { Loader } from '@app/components/Loader/Loader';
import { SectionHeader } from '@app/components/PageHeader/PageHeader';
import { PageControls } from '@app/components/PageControls/PageControls';
import './Review.scss';
import { useRoadmapStore } from '@app/state/RoadmapStore';
import { gql, graphql, rest } from '@app/services/http';
import { UserStore } from '@app/state/UserStore';
import { LocalizationStore } from '@app/state/LocalizationStore';
import { PartnerStore } from '@app/state/PartnerStore';
import { CreatorStore } from '@app/state/CreatorStore';

const CREATE_TEAM_ASSESSMENT = gql`
  mutation CreateTeamAssessment($campaign: skill_campaigns_insert_input!) {
    insert_skill_campaigns_one(object: $campaign) {
      id
    }
  }
`;

const ReviewPage: React.FunctionComponent = () => {
  const { user } = UserStore((state) => ({
    user: state.current,
  }));
  const localization = LocalizationStore();
  const { t } = localization.useTranslation();

  const partner = PartnerStore();
  const creator = CreatorStore();

  const { get_blueprint_by_sku, isRoadmapReady, roadmapId } = useRoadmapStore(
    (state) => ({
      get_blueprint_by_sku: state.get_blueprint_by_sku,
      isRoadmapReady: state.ready,
      roadmapId: state.id,
    })
  );

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const generateInvitations = (expiration, blueprints_map) => {
    const invitations: any[] = [];

    creator.sanitizedParticipants().forEach((entry) => {
      const aa: any = blueprints_map[entry.assessment];

      invitations.push({
        recipient_email: entry.email,
        sheet_id: aa.id,
        expiration: expiration,
        metadata: {
          assessment: {
            id: aa.id,
            name: aa.title,
          },
          user: {
            fullname: '',
            email: entry.email,
            group: entry.group,
          },
          deputy: {
            fullname: user.fullname,
          },
          partner: {
            shortname: partner.shortname,
            name: partner.name,
            logo: partner.logo,
          },
        },
        partner: partner.shortname ? partner.shortname : null,
      });
    });

    return invitations;
  };

  const submitTeamAssessment = async () => {
    setLoading(true);

    const expiration = creator.expiry?.toISOString();
    const blueprints_map: any = {};

    // loop through all participants and extract a unique list of the required blueprints
    creator.sanitizedParticipants().forEach((entry) => {
      blueprints_map[entry.assessment] = null;
    });

    // extract the SKUs as an ordered list
    const ordered_skus: string[] = Object.keys(blueprints_map);

    // generate a new assessment for each requested blueprint SKU
    const res: any = await rest
      .post('/skillmaster/authoring/assessments/generate', {
        json: {
          roadmap: roadmapId,
          targets: ordered_skus,
        },
      })
      .json();

    // map the IDs of the created assessments to the referencing blueprint
    ordered_skus.forEach((sku: string, i: number) => {
      blueprints_map[sku] = res['result'][i];
    });

    // generate the invitations
    const invitations = generateInvitations(expiration, blueprints_map);

    // create the campaign

    await graphql
      .request(CREATE_TEAM_ASSESSMENT, {
        campaign: {
          expiration: expiration,
          metadata: {
            owner: {
              name: user.fullname,
            },
            partner: {
              shortname: partner.shortname,
              name: partner.name,
              logo: partner.logo,
            },
            invitations: invitations,
          },
          invitations: {
            data: invitations,
          },
          partner: partner.shortname ? partner.shortname : null,
        },
      })
      .then((data: any) => {
        creator.reset();
        navigate(
          `/${localization.currentLanguageCode}/reporting/team/${data.insert_skill_campaigns_one.id}`
        );
      });
  };

  return isRoadmapReady && !loading ? (
    <React.Fragment>
      <PageSection
        isWidthLimited={true}
        isCenterAligned={true}
        className="review"
      >
        <PageHeader
          title={t('Review your data')}
          subtitle={t(
            'Please review carefully the information you provided; once you\'re sure you\'ll be able to submit the team assessment'
          )}
        ></PageHeader>

        <React.Fragment>
          <Grid hasGutter={true}>
            <GridItem span={6}>
              <Alert
                variant="info"
                className="invitation-alert"
                title={t(
                  'An email will be sent to each participant inviting them to complete their assigned assessments.'
                )}
              />

              <List
                isPlain
                isBordered
                className="statistics"
              >
                <ListItem>
                  {t('Total number of participants:')}{' '}
                  <strong>{creator.statistics().numberOfParticipants}</strong>
                </ListItem>
                <ListItem>
                  {t('Total number of assessments:')}{' '}
                  <strong>{creator.statistics().numberOfAssessment}</strong>
                </ListItem>
                <ListItem>
                  {t('Avg number of assessments per participant:')}{' '}
                  <strong>
                    {creator.statistics().averageAssessmentsPerParticipant}
                  </strong>
                </ListItem>
                <ListItem>
                  {t('Expires at midnight (UTC) on:')}{' '}
                  <strong>{creator.statistics().expiresOn}</strong>
                </ListItem>
              </List>

              <SectionHeader
                title={t('Group assignment')}
                description={t('List of provided groups and their members')}
              />
              {creator.participantsByGroup().map((group, i) => (
                <Card
                  key={i}
                  className="assessment-preview"
                >
                  <CardTitle>
                    {group.group ? `${i + 1}. ${group.group}` : t('Unassigned')}
                  </CardTitle>
                  <CardBody>
                    <List
                      isPlain
                      isBordered
                    >
                      {group.members.map((member, j) => (
                        <ListItem key={j}>{member}</ListItem>
                      ))}
                    </List>
                  </CardBody>
                </Card>
              ))}
            </GridItem>
            <GridItem span={1}></GridItem>
            <GridItem span={5}>
              <SectionHeader
                title={t('Participants by assessment')}
                description={t(
                  'A list of assessments and the participants they are assigned to'
                )}
              />
              {creator.participantsByAssessment().map((group, i) => (
                <Card
                  key={i}
                  className="assessment-preview"
                >
                  <CardTitle>{`${i + 1}. ${t(
                    get_blueprint_by_sku(group.assessment)?.label
                  )}`}</CardTitle>
                  <CardBody>
                    <Text className="summary">
                      {t(get_blueprint_by_sku(group.assessment)?.summary)}
                    </Text>
                    <List
                      isPlain
                      isBordered
                    >
                      <ListItem key="label">
                        <strong>{t('Participants:')}</strong>
                      </ListItem>
                      {group.participants.map((participant, j) => (
                        <ListItem key={j}>{participant}</ListItem>
                      ))}
                    </List>
                  </CardBody>
                </Card>
              ))}
            </GridItem>
          </Grid>
        </React.Fragment>
      </PageSection>
      <PageSection
        isWidthLimited={true}
        isCenterAligned={true}
        className="page-controls"
      >
        <PageControls
          onRight={() => {
            submitTeamAssessment();
          }}
          rightLabel={t('Submit')}
          onLeft={() => {
            navigate(`/${localization.currentLanguageCode}/create`);
          }}
          leftLabel={t('Change')}
        />
      </PageSection>
    </React.Fragment>
  ) : (
    <PageSection
      isWidthLimited={true}
      isCenterAligned={true}
      className="loader lg"
    >
      <Loader />
    </PageSection>
  );
};

export default ReviewPage;
