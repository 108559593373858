import React from 'react';
import RhLogo from '../../images/rh-logo.svg';
import { getHeaderNavItems } from './HeaderMenuItems';
import '@patternfly/pfe-tabs';
import './Header.scss';

import { availableLanguages } from '@app/constants';
import TalkBubbleIcon from '../../images/Icon-Red_Hat-Talk_Bubble-A-White-RGB.svg';
import { Observer } from 'mobx-react-lite';
import { PartnerStore } from '@app/state/PartnerStore';
import { LocalizationStore } from '@app/state/LocalizationStore';

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    for?: string;
    'xml:lang'?: string;
    hreflang?: string;
  }
}
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'pfe-navigation': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
      'pfe-navigation-account': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
      'pfe-navigation-dropdown': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      > & { icon?: string; name?: string };
      'pfe-cta': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      > & { priority?: string; has_link?: boolean };
      'pfe-icon': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      > & { icon?: string };
      'pfe-tabs': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      > & { vertical?: boolean; variant?: string };
      'pfe-tab': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      > & { role?: string; slot?: string };
      'pfe-tab-panel': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      > & { role?: string; slot?: string };
    }
  }
}

export const Header: React.FC = () => {
  const partner = PartnerStore();
  const localization = LocalizationStore();

  const { t } = localization.useTranslation();

  return (
    <pfe-navigation
      id="pfe-navigation"
      role="banner"
      aria-label="Global navigation"
    >
      <nav
        className="pfe-navigation"
        aria-label="Main Navigation"
      >
        <div
          className="pfe-navigation__logo-wrapper"
          id="pfe-navigation__logo-wrapper"
          style={
            partner.logo
              ? {
                backgroundImage: `url(${encodeURI(partner.logo)})`,
                paddingLeft: '150px',
                backgroundSize: '135px auto',
                backgroundRepeat: 'no-repeat',
                backgroundPositionX: 'left',
                backgroundPositionY: 'center',
              }
              : undefined
          }
        >
          <a
            href={`/${localization.currentLanguageCode}`}
            className="pfe-navigation__logo-link"
          >
            <img
              className="
            pfe-navigation__logo-image
            pfe-navigation__logo-image--screen
            pfe-navigation__logo-image--small"
              src={RhLogo}
              width="400"
              alt="Redhat"
            />
          </a>
        </div>
        <ul
          className="pfe-navigation__menu"
          id="pfe-navigation__menu"
        >
          {getHeaderNavItems().map((navItem) => (
            <li
              className="pfe-navigation__menu-item"
              key={navItem.name}
            >
              <a
                href="#"
                className="pfe-navigation__menu-link"
              >
                {t(navItem.name)}
              </a>

              <div className="pfe-navigation__dropdown">
                {navItem.dropdownColumns.map((column) => (
                  <div key={column.title}>
                    <div className="pfe-link-list--header">
                      {column.href ? (
                        <a href={column.href}>{t(column.title)}</a>
                      ) : (
                        <span>{t(column.title)}</span>
                      )}
                    </div>

                    <ul>
                      {column.links.map((link) => (
                        <li key={link.label}>
                          <a href={link.href}>{t(link.label)}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}

                {!!navItem.actionItems?.length && (
                  <div className="pfe-navigation__footer">
                    {navItem.actionItems.map((actionItem) => (
                      <pfe-cta
                        priority={actionItem.priority}
                        key={actionItem.name}
                      >
                        <a href={actionItem.href || '#'}>
                          {t(actionItem.name)}
                        </a>
                      </pfe-cta>
                    ))}
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </nav>

      <div
        slot="secondary-links"
        className="pfe-navigation__secondary-links"
        id="pfe-navigation__secondary-links"
      >
        <a
          href={`https://www.redhat.com/${localization.currentLanguageCode}/contact`}
        >
          <img
            src={TalkBubbleIcon}
            style={{ width: '26px' }}
          />
          {t('Contact us')}
        </a>
      </div>

      <div slot="secondary-links">
        <Observer>
          {() => (
            <pfe-navigation-dropdown
              dropdown-width="full"
              icon="web-globe"
              name={`${availableLanguages[localization.currentLanguageCode]}`}
              id="language-dropdown"
            >
              <div className="pfe-navigation-item__tray--container">
                <div className="language-picker">
                  <h3>{t('Select a language')}</h3>
                  <ul id="pfe-navigation__ul">
                    {Object.keys(availableLanguages).map((code) => (
                      <li key={code}>
                        <a
                          href={`/${code}`}
                          className="language-link"
                        >
                          {availableLanguages[code]}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </pfe-navigation-dropdown>
          )}
        </Observer>
      </div>
    </pfe-navigation>
  );
};

export default Header;
