import React, { useContext } from 'react';
import {
  AlertProps,
  AlertGroup,
  Alert,
  AlertActionCloseButton,
} from '@patternfly/react-core';
import { createContext, ReactNode, useState } from 'react';

interface AlertProviderProps {
  children: ReactNode;
}

interface IAlert {
  title: string;
  variant: AlertProps['variant'];
  key: React.Key;
  message?: string;
  actionLinks?: React.ReactNode;
  timeout?: number;
}

interface AlertContextData {
  addAlert: (
    title: string,
    variant: AlertProps['variant'],
    key: React.Key,
    message?: string,
    actionLinks?: React.ReactNode,
    timeout?: number
  ) => void;
  removeAlert: (key: React.Key) => void;
}

export const AlertContext = createContext<AlertContextData>({
  addAlert: () => undefined,
  removeAlert: () => undefined,
});

export function AlertProvider({ children }: AlertProviderProps): JSX.Element {
  const [alerts, setAlerts] = useState<IAlert[]>([]);

  const addAlert = (
    title: string,
    variant: AlertProps['variant'],
    key: React.Key,
    message?: string,
    actionLinks?: React.ReactNode,
    timeout?: number
  ) => {
    setAlerts((prevAlerts) => [
      ...prevAlerts,
      {
        title,
        ...(message ? { message } : {}),
        variant,
        key,
        ...(actionLinks ? { actionLinks } : {}),
        timeout: timeout ? timeout : 8000,
      },
    ]);
  };

  const removeAlert = (key: React.Key) => {
    setAlerts((prevAlerts) => [
      ...prevAlerts.filter((alert) => alert.key !== key),
    ]);
  };

  return (
    <AlertContext.Provider
      value={{
        addAlert,
        removeAlert,
      }}
    >
      {children}
      <>
        <AlertGroup
          isToast
          isLiveRegion
        >
          {alerts.map(
            ({ title, message, variant, key, actionLinks, timeout }) => (
              <Alert
                isInline
                variant={variant}
                title={title}
                timeout={timeout}
                actionClose={
                  <AlertActionCloseButton
                    title={title}
                    variantLabel={`${title}-alert`}
                    onClose={() => removeAlert(key)}
                  />
                }
                actionLinks={actionLinks}
                key={key}
              >
                {message && message}
              </Alert>
            )
          )}
        </AlertGroup>
      </>
    </AlertContext.Provider>
  );
}

export function useAlert(): AlertContextData {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error('useAlert must be used within a <AlertProvider />');
  }
  return context;
}
