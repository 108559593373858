import React, { useState, useEffect } from 'react';
import {
  Alert,
  PageSection,
  Grid,
  GridItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Text,
} from '@patternfly/react-core';
import { useParams } from 'react-router-dom';
import OfferingCard from '@app/components/OfferingCard/OfferingCard';
import PageHeader, {
  SectionHeader,
} from '@app/components/PageHeader/PageHeader';
import { PageHero } from '@app/components/PageHero/PageHero';
import { useNavigate } from 'react-router-dom';
import './Results.scss';
import static1 from '@app/images/static1.png';
import static2 from '@app/images/static2.png';
import { IReportModel } from '@app/types';
import { Loader } from '@app/components/Loader/Loader';
import { calculatePerformanceEntries } from '@app/utils/reporting';
import PerformanceCard from '@app/components/PerformanceCard/PerformanceCard';
import { LocalizationStore } from '@app/state/LocalizationStore';
import { gql, graphql } from '@app/services/http';

const GET_REPORT = gql`
  query GetReport($id: uuid!) {
    skill_reports_by_pk(id: $id) {
      campaign_id
      id
      metadata
      owner_id
      partner
      score
      suggestions
      timestamp
    }
  }
`;

const ResultsPage: React.FC = () => {
  const navigate = useNavigate();
  const localization = LocalizationStore();
  const [report, setReport] = useState<IReportModel | null>(null);

  const [performanceEntries, setPerformanceEntries] = useState<any[]>([]);
  const [performanceRate, setPerformanceRate] = useState<number | null>(null);

  const { t } = localization.useTranslation();
  const { id } = useParams();

  const getReport = async () => {
    await graphql
      .request(GET_REPORT, {
        id: id,
      })
      .then((data: any) => {
        if (data['skill_reports_by_pk']) {
          setReport(data.skill_reports_by_pk);

          const { orderedUnits, aggregated_offerings, performanceRate } =
            calculatePerformanceEntries([data.skill_reports_by_pk]);

          setPerformanceEntries(orderedUnits);
          setPerformanceRate(performanceRate);
        } else {
          navigate('/en/not-found');
        }
      });
  };

  useEffect(() => {
    getReport()
  }, []);

  return report ? (
    <React.Fragment>
      <PageSection
        isWidthLimited={true}
        isCenterAligned={true}
        className="page-hero"
      >
        <PageHero
          title={t(report.metadata.assessment.name)}
        />
      </PageSection>
      <PageSection
        isWidthLimited={true}
        isCenterAligned={true}
        className="results"
      >
        <Grid>
          <GridItem
            sm={12}
            md={12}
            xl={6}
          >
            <PageHeader
              title={t('Congratulations')}
              subtitle={t(
                'Review the following recommendations regarding your skills.'
              )}
              span={12}
            />

            <SectionHeader
              title={t("What you should take next")}
              description={t("Based on your assessment results, this is our best suggestion for you.")}
            />

            {report.suggestions.length > 0 ? (
              <OfferingCard
                key={report.suggestions[0].id}
                offering={report.suggestions[0]}
                color={1}
              />
            ) : (
              <Text>{t('No suggestions for this assessment')}</Text>
            )}

            <Divider className="large" />

            <SectionHeader
              title={t("Why we're suggesting this")}
              description={t("Here are the metrics which contributed to the suggestion above.")}
            />
            {/*
              <Stack hasGutter={true}>
                {performanceEntries.map(unit => {
                  return <StackItem key={unit.id}>
                    <PerformanceCard
                      {...unit}
                    />
                  </StackItem>
                })}
              </Stack>
              */}
            {performanceEntries.length > 0 ? (
              <React.Fragment>
                {performanceRate !== null && (
                  <PerformanceCard
                    name={t('This Assessment')}
                    value={performanceRate}
                  />
                )}

                <br />

                {performanceEntries.map((unit) => (
                  <PerformanceCard
                    key={unit.name}
                    name={t(unit.name)}
                    value={unit.value}
                  />
                ))}
              </React.Fragment>
            ) : (
              <Text>{t('No scores for this assessment')}</Text>
            )}

            {report.suggestions.length > 1 && (
              <React.Fragment>
                <Divider className="large" />

                <SectionHeader
                  title={t("Also part of your skill path")}
                  description={t("Consider the following recommendations as a prerequisite or complementary to your main suggestion.")}
                />

                {report.suggestions.slice(1, 3).map((offering, index) => (
                  <OfferingCard
                    key={offering.id}
                    offering={offering}
                    color={index + 2}
                    showDescription={false}
                  />
                ))}
              </React.Fragment>
            )}
          </GridItem>
          <GridItem
            sm={12}
            md={12}
            xl={5}
            xlOffset={7}
            className="side-content"
          >
            <Grid>
              <GridItem span={12}>
                <Button
                  variant="tertiary"
                  isLarge
                  isBlock
                  onClick={() => {
                    navigate(`/${localization.currentLanguageCode}/dashboard`);
                  }}
                >
                  {t('Go to your dashboard')}
                </Button>
                <Button
                  variant="tertiary"
                  isLarge
                  isBlock
                  onClick={() => {
                    navigate(`/${localization.currentLanguageCode}/start`);
                  }}
                >
                  {t('Take another assessment')}
                </Button>

                {report.campaign_id && (
                  <Alert
                    variant="info"
                    title={
                      t(
                        'This is part of a team skills assessment and your results have been shared with '
                      ) +
                      report.metadata.deputy.fullname +
                      '.'
                    }
                  />
                )}

                <Card className="contact-us">
                  <CardBody>
                    {t('Need additional information about Red Hat Training?')}
                  </CardBody>
                  <CardFooter>
                    <Button
                      variant="primary"
                      component="a"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.redhat.com/en/services/training-and-certification/contact-us"
                    >
                      {t('Contact us')}
                    </Button>
                  </CardFooter>
                </Card>

                <Card>
                  <CardBody>
                    <Grid>
                      <GridItem
                        span={8}
                        offset={2}
                      >
                        <Card className="static">
                          <CardBody>
                            <a
                              href="https://www.redhat.com/en/services/training/learning-subscription"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={static1} />
                            </a>
                          </CardBody>
                        </Card>

                        <Card className="static">
                          <CardBody>
                            <a
                              href="https://learn.redhat.com/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={static2} />
                            </a>
                          </CardBody>
                        </Card>
                      </GridItem>
                    </Grid>
                  </CardBody>
                </Card>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      </PageSection>
    </React.Fragment>
  ) : (
    <PageSection
      isWidthLimited={true}
      isCenterAligned={true}
      className="loader lg"
    >
      <Loader />
    </PageSection>
  );
};

export default ResultsPage;
