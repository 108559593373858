import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import '@patternfly/react-core/dist/styles/base.css';
import { AppRoutes } from '@app/routes';
import { Provider, rootStore } from '@app/state/RootStore';

import '@cpelements/pfe-navigation/dist/pfe-navigation--lightdom.css';
import '@rhds/tokens/css/global.css';
import '@app/app.scss';
import { observer } from 'mobx-react-lite';

import { ApolloProvider } from '@apollo/client';
import { client as apolloClient } from '@app/services/ApolloClient';
import { AlertProvider } from '@app/hooks/useAlert';

// LEGACY, TO BE REMOVED
// REMOVE ApollO RELATED CODE

const App = observer(() => {
  return (
    <ApolloProvider client={apolloClient}>
      <Router>
        <Provider value={rootStore}>
          <AlertProvider>
            <AppRoutes />
          </AlertProvider>
        </Provider>
      </Router>
    </ApolloProvider>
  );
});

export default App;
