import React, { useEffect, useState, useRef } from 'react';
import { useMst } from '@app/state/RootStore';
import {
  Grid,
  GridItem,
  Text,
  TextInput,
  PageSection,
} from '@patternfly/react-core';
import { PageControls } from '@app/components/PageControls/PageControls';
import './SingleChoiceQuestion.scss';
import { UserStore } from '@app/state/UserStore';
import { useProctorStore } from '@app/state/ProctorStore';
import { gql, graphql } from '@app/services/http';
import { LocalizationStore } from '@app/state/LocalizationStore';

const UPDATE_COMPANY_NAME = gql`
  mutation UpdateCompanyName($user_id: uuid!, $company: String!) {
    update_skill_users_by_pk(
      pk_columns: { id: $user_id }
      _set: { company: $company }
    ) {
      id
    }
  }
`;

const UpdateCompany: React.FC = (): React.ReactElement => {
  // remove legacy store
  const { tracker } = useMst();

  const { user, sync_user_info } = UserStore((state) => ({
    user: state.current,
    sync_user_info: state.sync_user_info,
  }));
  const {
    setHasUpdatedCompanyName
  } = useProctorStore((state) => ({
    setHasUpdatedCompanyName: state.setHasUpdatedCompanyName
  }));

  const localization = LocalizationStore();
  const { t } = localization.useTranslation();

  const [companyName, setCompanyName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const companyRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (user.company) {
      setCompanyName(user.company);
    }
  }, [user.company]);

  const onNextCallback = async () => {
    setLoading(true);

    await graphql
      .request(UPDATE_COMPANY_NAME, {
        user_id: user.uuid,
        company: companyName,
      })
      .then((res) => {
        // if res is null it means the update was not possible
        // so user can not go foward
        if (res) {
          // remove tracker.setUserUpdatedCompany as it uses the legacy store
          tracker.setUserUpdatedCompany(true);

          setHasUpdatedCompanyName(true);
          sync_user_info();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChange = () => {
    if (companyRef.current) {
      setCompanyName(companyRef.current.value);
    }
  };

  return (
    <React.Fragment>
      <PageSection
        isWidthLimited={true}
        isCenterAligned={true}
        className="single-question"
      >
        <Text className="question-text">
          {t('Which company are you currently working for?')}
        </Text>

        <Grid>
          <GridItem span={4}>
            <TextInput
              aria-label="Company name"
              type="text"
              isRequired
              isDisabled={loading}
              value={companyName}
              placeholder={t('Company name')}
              ref={companyRef}
              onChange={onChange}
            />
          </GridItem>
        </Grid>
      </PageSection>
      <PageSection
        isWidthLimited={true}
        isCenterAligned={true}
        className="page-controls"
      >
        <PageControls
          onRight={onNextCallback}
          isRightDisabled={!companyName.trim().length || loading}
          isRightLoading={loading}
          rightLabel={t('Next')}
        />
      </PageSection>
    </React.Fragment>
  );
};

export default UpdateCompany;
