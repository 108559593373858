import * as React from 'react';
import {
  Grid,
  GridItem,
  Title,
  TitleSizes
} from '@patternfly/react-core';
import './PageHeader.scss';
import { gridSpans } from '@patternfly/react-core';

interface IPageHeader {
    title: string;
    subtitle?: string;
    suptitle?: string;
    children?: React.ReactNode;
    isDark?: boolean;
    span?: gridSpans;
}

interface ISectionHeader {
    title?: string;
    description?: string;
}

export const SectionHeader: React.FC<ISectionHeader> = ({ title, description }) => {
  return <section className="section-header">
    { title && <Title headingLevel="h5">{title}</Title>}
    { description && <Title headingLevel="h6">{description}</Title> }
  </section>
}

export const PageHeader: React.FC<IPageHeader> = ({ title, subtitle, suptitle, children=null, isDark=false, span=6 }) => {
  return (
    <Grid className={`page-header ${ isDark ? 'dark' : ''}`}>
      <GridItem sm={12} md={12} xl={span}>
        { suptitle && <Title headingLevel="h6" size={TitleSizes['lg']}>{ suptitle }</Title> }
        <Title headingLevel="h1" size={TitleSizes['4xl']}>{ title }</Title>
        { subtitle && <Title headingLevel="h5" size={TitleSizes['xl']}>{ subtitle }</Title> }
        { children }
      </GridItem>
    </Grid>
  )
};

export default PageHeader;