import { ISortBy, ThProps } from '@patternfly/react-table';
import { useState } from 'react';

const useSorting = () => {
  const [activeSortIndex, setActiveSortIndex] = useState<number | null>(null);
  const [activeSortDirection, setActiveSortDirection] = useState<
    'asc' | 'desc' | undefined
  >(undefined);

  const getSortParams = (columnIndex: number): ThProps['sort'] => ({
    sortBy: {
      index: activeSortIndex as keyof ISortBy['index'],
      direction: activeSortDirection as keyof ISortBy['direction'],
      defaultDirection: 'asc', // starting sort direction when first sorting a column. Defaults to 'asc'
    },
    onSort: (_event, index, direction) => {
      setActiveSortIndex(index);
      setActiveSortDirection(direction);
    },
    columnIndex,
  });

  return {
    activeSortIndex,
    activeSortDirection,
    getSortParams,
    setActiveSortIndex
  };
};

export default useSorting;
