import * as React from 'react';
import { PageSection, Button, Divider } from '@patternfly/react-core';
import DashboardEntry from '@app/components/DashboardEntry/DashboardEntry';
import {
  PageHeader,
  SectionHeader,
} from '@app/components/PageHeader/PageHeader';
import { useEffect, useState } from 'react';
import {
  IInvitationModel,
  ICampaignModel,
  IReportModel,
} from '@app/types';
import './Dashboard.scss';
import { Loader } from '@app/components/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import { UserStore } from '@app/state/UserStore';
import { LocalizationStore } from '@app/state/LocalizationStore';
import { gql, graphql } from '@app/services/http';

const GET_DASHBOARD = gql`
  query GetDashboard($user_id: uuid!, $user_email: String) {
    skill_reports(
      where: { owner_id: { _eq: $user_id } }
      order_by: { timestamp: desc }
    ) {
      id
      timestamp
      campaign_id
      owner_id
      metadata
      score
      suggestions
    }
    skill_invitations(
      where: { recipient_email: { _eq: $user_email } }
      order_by: { timestamp: desc }
    ) {
      id
      assessment_id
      sheet_id
      campaign_id
      metadata
      timestamp
      expiration
    }
    dashboard: skill_campaigns(
      where: { owner_id: { _eq: $user_id } }
      order_by: { timestamp: desc }
    ) {
      id
      reports {
        metadata
      }
      expiration
      owner_id
      metadata
      user {
        fullname
      }
    }
    shared_with_me: skill_campaigns(
      order_by: { timestamp: desc }
      where: {
        owner_id: { _neq: $user_id }
        _or: [
          { shared_email_1: { _eq: $user_email } }
          { shared_email_2: { _eq: $user_email } }
          { shared_email_3: { _eq: $user_email } }
          { shared_email_4: { _eq: $user_email } }
          { shared_email_5: { _eq: $user_email } }
          { shared_email_6: { _eq: $user_email } }
          { shared_email_7: { _eq: $user_email } }
          { shared_email_8: { _eq: $user_email } }
          { shared_email_9: { _eq: $user_email } }
          { shared_email_10: { _eq: $user_email } }
        ]
      }
    ) {
      id
      reports {
        metadata
      }
      expiration
      metadata
    }
  }
`;

const DashboardPage: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const [invitations, setInvitations] = useState<IInvitationModel[]>([]);
  const [reports, setReports] = useState<IReportModel[]>([]);
  const [campaigns, setCampaigns] = useState<ICampaignModel[]>([]);
  const [sharedWithMe, setSharedWithMe] = useState<ICampaignModel[]>([]);
  const [loading, setLoading] = useState(true);

  const localization = LocalizationStore();
  const { t } = localization.useTranslation();

  const { user, isUserReady } = UserStore((state) => ({
    user: state.current,
    isUserReady: state.ready,
  }));

  const getDashboardInfo = async () => {
    if (isUserReady) {
      try {
        const data: any = await graphql.request(GET_DASHBOARD, {
          user_id: user.uuid,
          user_email: user.email,
        });
        setInvitations(data['skill_invitations']);
        setReports(data['skill_reports']);
        setCampaigns(data['dashboard']);
        setSharedWithMe(data['shared_with_me']);
      } catch (error: any) {
        console.error(error.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getDashboardInfo();
  }, [isUserReady]);

  if (loading) {
    return (
      <PageSection
        isWidthLimited={true}
        isCenterAligned={true}
        className="loader lg"
      >
        <Loader />
      </PageSection>
    );
  }

  return (
    <PageSection
      isWidthLimited={true}
      isCenterAligned={true}
      className="dashboard-page"
    >
      <PageHeader
        title={t('Welcome back, {{ username }}', { username: user.given_name })}
        subtitle={t(
          'This page shows all your past and present activity with individual and team assessments.'
        )}
      />

      {invitations.length > 0 && (
        <React.Fragment>
          <section className="dashboard-section">
            <SectionHeader
              title={t('My Invitations')}
              description={t(
                'You have been invited to take part of the following team assessments.'
              )}
            />
            {invitations.map((invitation, index) => (
              <DashboardEntry
                key={invitation['id']}
                invitation={invitation}
                index={index + 1}
              />
            ))}
          </section>
          <Divider className="large" />
        </React.Fragment>
      )}

      {reports.length > 0 && (
        <React.Fragment>
          <section className="dashboard-section">
            <SectionHeader
              title={t('My assessment results')}
              description={t('Review the results of your past assessments.')}
            />
            <Button
              variant="tertiary"
              onClick={() => {
                navigate(`/${localization.currentLanguageCode}/start`);
              }}
            >
              {t('Take a new assessment')}
            </Button>
            {reports.map((report, index) => (
              <DashboardEntry
                key={report.id}
                report={report}
                index={index + 1}
              />
            ))}
          </section>
          <Divider className="large" />
        </React.Fragment>
      )}

      <section className="dashboard-section">
        <SectionHeader
          title={t('My team assessments')}
          description={t(
            'An overview of all the team assessments you created.'
          )}
        />
        <Button
          variant="tertiary"
          onClick={() => {
            navigate(`/${localization.currentLanguageCode}/create`);
          }}
        >
          {t('Create a new team assessment')}
        </Button>
        {campaigns.map((campaign, index) => (
          <DashboardEntry
            key={campaign.id}
            campaign={campaign}
            index={index + 1}
            onExpirationUpdated={getDashboardInfo}
          />
        ))}
      </section>

      {sharedWithMe.length > 0 && (
        <>
          <Divider className="large" />

          <section className="dashboard-section">
            <SectionHeader
              title={t('Shared with me')}
              description={t(
                'A summary of the team assessments that have been shared by other users with you.'
              )}
            />
            {sharedWithMe.map((campaign, index) => (
              <DashboardEntry
                key={campaign.id}
                campaign={campaign}
                index={index + 1}
                isShared={true}
              />
            ))}
          </section>
        </>
      )}
    </PageSection>
  );
};

export default DashboardPage;
