// LEGACY, TO BE REMOVED
import React, { useEffect } from 'react';
import { useMst } from '@app/state/RootStore';
import { PageSection } from '@patternfly/react-core';
import { Loader } from '@app/components/Loader/Loader';
import { observer } from 'mobx-react-lite';
import { LocalizationStore } from '@app/state/LocalizationStore';

const LinkNodeWrapper: React.FC = observer((): React.ReactElement => {
  const { tracker } = useMst();

  const localization = LocalizationStore();

  const { t } = localization.useTranslation();

  useEffect(() => {
    tracker.push_listener(tracker.shownNode);
    tracker.load_node(tracker.shownNode.body.link.target.nodes[0].id);
  }, []);

  return tracker.shownNode && (
    <PageSection
      isWidthLimited={true}
      isCenterAligned={true}
      className="loader lg"
    ><Loader/></PageSection>
  )
});

export default LinkNodeWrapper;