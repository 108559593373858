import { create } from 'zustand';
import {
  REDIRECT_TIMESTAMP_LIMIT,
  sso_local_key,
  timestamp_before_sso_key,
} from '@src/app/constants';
import { gql, graphql, rest } from '@app/services/http';

const GET_USER_BY_UUID = gql`
  query user($uuid: uuid!) {
    skill_users_by_pk(id: $uuid) {
      username
      country
      email
      family_name
      fullname
      given_name
      job_title
      locale
      phone
      corporate
      company
      role {
        slug
      }
    }
  }
`;

export interface UserModel {
  uuid: string | null;
  username: string;
  country: string;
  email: string;
  family_name: string;
  fullname: string;
  given_name: string;
  job_title: string | null;
  locale: string;
  phone: string | null;
  corporate: boolean;
  company: string | null;
  role: string;
}

interface UserStoreInterface {
  current: UserModel;
  expiration: null | number;
  ready: boolean;
  isLoggedIn: () => boolean;
  isArchitect: () => boolean;
  handle_sso_redirect: () => void;
  logout: () => void;
  sync_user_info: () => Promise<void>;
  heartbeat: (way: string) => void;
}

export const UserStore = create<UserStoreInterface>((set, get) => ({
  current: {
    uuid: null,
    username: '',
    country: '',
    email: '',
    family_name: '',
    fullname: '',
    given_name: '',
    job_title: null,
    locale: '',
    phone: null,
    corporate: false,
    company: null,
    role: '',
  },
  expiration: null,
  ready: false,
  isLoggedIn: () => get().current.uuid !== null,
  isArchitect: () => get().current.role === 'architect',
  handle_sso_redirect: () => {
    const timestampTarget = localStorage.getItem(
      timestamp_before_sso_key
    ) as string;
    const dateTarget = new Date(Number.parseInt(timestampTarget));
    const dateNow = new Date();

    const timeDiff = Math.abs(dateNow.getTime() - dateTarget.getTime());
    const minutesDiff = Math.floor(timeDiff / (1000 * 60));

    if (
      localStorage.getItem(sso_local_key) &&
      timestampTarget &&
      minutesDiff < REDIRECT_TIMESTAMP_LIMIT
    ) {
      const target = localStorage.getItem(sso_local_key) || '/';
      localStorage.removeItem(sso_local_key);
      localStorage.removeItem(timestamp_before_sso_key);

      window.location.href = target;
    }
  },
  logout: () => {
    window.location.href = '/skillmaster/authentication/logout';
  },
  sync_user_info: async () => {
    if (get().isLoggedIn()) {
      await graphql
        .request(GET_USER_BY_UUID, {
          uuid: get().current.uuid,
        })
        .then((data: any) => {
          set((state) => ({
            ...state,
            current: {
              ...state.current,
              ...data.skill_users_by_pk,
              role: data.skill_users_by_pk.role.slug,
            },
            ready: true,
          }));
        });
    }
  },
  heartbeat: async (way: string) => {
    await rest
      .post('/skillmaster/authentication/heartbeat')
      .json()
      .then((data: any) => {
        if (data.uuid !== get().current.uuid) {
          set((state) => ({
            ...state,
            current: {
              ...state.current,
              uuid: data.uuid,
            },
          }));
        }

        set((state) => ({
          ...state,
          expiration: Date.now() + data.lifetime * 1000,
        }));

        get().sync_user_info();
      })
      .catch((error: any) => {
        if (error?.response?.status === 401) {
          // user is unauthenticated
          localStorage.setItem(sso_local_key, window.location.href);

          if (!localStorage.getItem(timestamp_before_sso_key)) {
            const now = `${Date.now()}`;
            localStorage.setItem(timestamp_before_sso_key, now);
          }

          window.location.href = `/skillmaster/authentication/initiate/${way}`;
        } else {
          console.error('unexpected heartbeat error', error);
        }
      });
  },
}));
