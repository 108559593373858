import { rest } from '@app/services/http';
import { create } from 'zustand';

interface PartnerStoreInterface {
  name: string | null;
  shortname: string | null;
  logo: string | null;
  ready: boolean;
  getPartnerInfo: (shortname: string | null) => void;
  setNoPartner: () => void;
}

export const PartnerStore = create<PartnerStoreInterface>((set, get) => ({
  name: null,
  shortname: null,
  logo: null,
  ready: false,
  getPartnerInfo: (value) => {
    const id = value?.toLowerCase();

    rest.get(`/skillmaster/vendor/partners/get-info/${id}`).json().then((data: any) => {
      const dataToStore = {
        shortname: id,
        name: data.name,
        logo: data.logo,
        ready: true
      }

      localStorage.setItem('partner', JSON.stringify(dataToStore));

      set(dataToStore);
    });
  },
  setNoPartner: () => {
    set({
      ready: true,
    });
  },
}));
