import React, { useEffect } from 'react';
import {
  Button,
  DatePicker,
  PageSection,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
} from '@patternfly/react-core';
import ParticipantEntry from '@app/components/ParticipantEntry/ParticipantEntry';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '@app/components/PageHeader/PageHeader';
import { Loader } from '@app/components/Loader/Loader';
import { PageControls } from '@app/components/PageControls/PageControls';
import { parse, unparse } from 'papaparse';
import './Participants.scss';
import { downloadFile } from '@app/utils/storage';
import { useRoadmapStore } from '@app/state/RoadmapStore';
import { LocalizationStore } from '@app/state/LocalizationStore';
import { CreatorStore, validateParticipant } from '@app/state/CreatorStore';

export interface IAssessmentOption {
  label: string;
  id: string;
}

const ParticipantsPage: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const localization = LocalizationStore();
  const { t } = localization.useTranslation();

  const creator = CreatorStore();
  const { isRoadmapReady, blueprints } = useRoadmapStore((state) => ({
    isRoadmapReady: state.ready,
    blueprints: state.blueprints,
  }));

  useEffect(() => {
    creator.onParticipantChange();
  }, [creator.participants]);

  const onCSVFileUpload = () => {
    const fileElem = document.getElementById('csv-file-upload');
    if (fileElem) {
      fileElem.click();
    }
  };

  const onCSVParsing = async (e) => {
    const csv: File = e.target.files[0];

    parse(csv, {
      complete: (result, file) => {
        if (result.errors.length) {
          alert('Error parsing the CSV');
          console.error(result.errors);
        } else {
          // remove comment and empty lines
          console.info('Received raw CSV data', result.data);
          const batch = result.data.filter(
            (row) => !row[0].startsWith('#') && row.length === 3
          );

          const sanitized = batch.filter((row) => {
            return (
              row[0].length > 0 &&
              (row[2] == '' || blueprints.map((a) => a.sku).includes(row[2]))
            );
          });

          const validated = sanitized.map((row) => {
            return {
              email: row[0].toLowerCase(),
              group: row[1],
              assessment: row[2],
              _valid: validateParticipant(row[0], row[2], blueprints),
            };
          });

          const invalidEntries = batch.length - validated.length;
          console.info('processed batch', batch);
          console.info('sanitized and validated', validated);

          if (invalidEntries) {
            alert(
              `Found ${invalidEntries} invalid entries. Loading the remaining ones.`
            );
          }
          creator.batchLoad(validated);
        }
      },
    });

    e.target.value = null;
  };

  const onCSVTemplateDownload = () => {
    const participants = creator.sanitizedParticipants().map((p) => {
      return [p.email, p.group, p.assessment];
    });

    const catalogIndex = blueprints.map((a) => {
      return [`# ${a.label}`, '', a.sku];
    });

    const notes = [
      ['#', '', ''],
      ['#', '', ''],
      ['#', '', ''],
      [
        '# Use the following SKU list to fill out the \'assessment\' column in this CSV.',
        '',
        '',
      ],
      ['# Empty rows and rows starting with an hash will be ignored.', '', ''],
      ['#', '', ''],
    ];

    const content = {
      fields: ['# email', 'group', 'assessment'],
      data: [...participants, ...notes, ...catalogIndex],
    };

    const csv = unparse(content);

    downloadFile(
      `rh-teamfile-${Date.now()}.csv`,
      new Blob([csv], { type: 'text/csv' })
    );
  };

  const rangeValidator = (date: Date) => {
    const minDate = new Date();
    const maxDate = new Date();

    maxDate.setFullYear(maxDate.getFullYear() + 1);

    if (date < minDate) {
      return 'Date is before the allowable range.';
    } else if (date > maxDate) {
      return 'Date is after the allowable range.';
    }

    return '';
  };

  const convertDateToUTC = (date: Date | undefined) => {
    if (date) {
      const d = new Date(
        Date.UTC(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          0,
          0,
          0,
          0
        )
      );
      creator.setExpiry(d);
    } else {
      creator.setExpiry(null);
    }
  };

  useEffect(() => {
    creator.onParticipantChange();
    creator.setDefaultExpiry();
  }, []);

  const expirationDatePicker = () => {
    return (
      <DatePicker
        validators={[rangeValidator]}
        onBlur={(str, date) => convertDateToUTC(date)}
        onChange={(str, date) => convertDateToUTC(date)}
        value={creator.textFormattedExpiry()}
      />
    );
  };

  return isRoadmapReady ? (
    <React.Fragment>
      <PageSection
        isWidthLimited={true}
        isCenterAligned={true}
        className="creator"
      >
        <PageHeader
          title={t('Create a team assessment')}
          subtitle={t(
            'Test your whole team by inviting them to take assessments as a group and get advanced insight into their skill levels.'
          )}
        />

        <section>
          <p>
            {t(
              'Please fill in the requested data by providing an email address, an optional group name and an assessment. An expiration date is also needed.'
            )}
          </p>
          <br />
          <p className="legal-notice">
            {t(
              'Note: By providing this information, you assert that you are authorized to act in this capacity and that you have informed the individuals whose information will be provided.'
            )}
            <br />
            {t(
              'Your name will be shared with individuals who you have requested take a team skills assessment.'
            )}
          </p>
        </section>

        <Toolbar className="csv-container">
          <ToolbarContent>
            <ToolbarItem alignment={{ default: 'alignLeft' }}>
              {t('Expires on')}&nbsp;
              {expirationDatePicker()}
            </ToolbarItem>
            <ToolbarItem alignment={{ default: 'alignRight' }}>
              <input
                type="file"
                id="csv-file-upload"
                multiple={false}
                accept="text/csv"
                style={{ display: 'none' }}
                onChange={onCSVParsing}
              />
              <Button
                variant="link"
                onClick={() => {
                  onCSVTemplateDownload();
                }}
              >
                {t('Download template')}
              </Button>
              <Button
                id="fileSelect"
                variant={'danger'}
                onClick={() => {
                  onCSVFileUpload();
                }}
              >
                {t('Upload CSV')}
              </Button>
            </ToolbarItem>
          </ToolbarContent>
        </Toolbar>

        <section>
          {creator.participants.map((participant, index) => (
            <article
              key={index}
              className={`participant-row ${
                participant._valid || index === creator.participants.length - 1
                  ? 'valid'
                  : 'invalid'
              }`}
            >
              <ParticipantEntry
                index={index}
                participant={participant}
                availableAssessments={blueprints}
                onChange={creator.updateParticipant}
                onDelete={creator.removeParticipant}
                onDuplicate={creator.duplicateParticipant}
                isDummy={index === creator.participants.length - 1}
              />
            </article>
          ))}
        </section>
      </PageSection>
      <PageSection
        isWidthLimited={true}
        isCenterAligned={true}
        className="page-controls"
      >
        <PageControls
          isRightDisabled={
            !!(
              !creator.valid ||
              !creator.expiry ||
              (creator.expiry && rangeValidator(creator.expiry))
            )
          }
          onRight={() => {
            navigate(`/${localization.currentLanguageCode}/create/review`);
          }}
          rightLabel={t('Review')}
          onLeft={() => {
            creator.reset();
          }}
          leftLabel={t('Reset')}
        />
      </PageSection>
    </React.Fragment>
  ) : (
    <PageSection
      isWidthLimited={true}
      isCenterAligned={true}
      className="loader lg"
    >
      <Loader />
    </PageSection>
  );
};

export default ParticipantsPage;
