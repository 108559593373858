import { LocalizationStore } from '@app/state/LocalizationStore';

interface IDropdownColumnLinks {
  href: string;
  label: string;
}

interface IMenuDropdownColumns {
  title: string;
  href?: string;
  links: Array<IDropdownColumnLinks>;
}

interface IDropdownActionItems {
  name: string;
  priority?: string;
  href?: string;
}

interface IHeaderNavItems {
  name: string;
  dropdownColumns: Array<IMenuDropdownColumns>;
  actionItems?: Array<IDropdownActionItems>;
}

export const getHeaderNavItems = (): Array<IHeaderNavItems> => {
  const localization = LocalizationStore();
  const BASE_URL = `https://www.redhat.com/${localization.currentLanguageCode}`;
  return [
    {
      name: 'Products',
      dropdownColumns: PRODUCTS_MENU_DROPDOWN_COLUMNS(BASE_URL),
      actionItems: [
        {
          name: 'See all products',
          href: `${BASE_URL}/technologies/all-products`,
          priority: 'primary',
        },
        {
          name: 'Red Hat Cloud Services',
          href: `${BASE_URL}/technologies/cloud-computing/openshift/cloud-services`,
        },
        {
          name: 'Buy products online',
          href: `${BASE_URL}/store`,
        },
        {
          name: 'Subscription model',
          href: `${BASE_URL}/about/value-of-subscription`,
        },
      ],
    },
    {
      name: 'Solutions',
      dropdownColumns: SOLUTIONS_MENU_DROPDOWN_COLUMNS(BASE_URL),
      actionItems: [
        {
          name: 'Explore solutions',
          href: `${BASE_URL}/solutions`,
          priority: 'primary',
        },
      ],
    },
    {
      name: 'Training & services',
      dropdownColumns: TRAINING_AND_SERVICES_DROPDOWN_COLUMNS(BASE_URL),
      actionItems: [
        {
          name: 'Explore training & services',
          href: `${BASE_URL}/services`,
          priority: 'primary',
        },
      ],
    },
    {
      name: 'Resources',
      dropdownColumns: RESOURCES_MENU_DROPDOWN_COLUMNS(BASE_URL),
      actionItems: [
        {
          name: 'Explore resources',
          href: `${BASE_URL}/resources`,
          priority: 'primary',
        },
      ],
    },
    {
      name: 'Partners',
      dropdownColumns: PARTNERS_MENU_DROPDOWN_COLUMNS(BASE_URL),
    },
    {
      name: 'About',
      dropdownColumns: ABOUT_MENU_DROPDOWN_COLUMNS(BASE_URL),
      actionItems: [
        {
          name: 'Explore Red Hat',
          href: `${BASE_URL}/about`,
          priority: 'primary',
        },
        {
          name: 'Search jobs',
          href: `${BASE_URL}/jobs`,
        },
        {
          name: 'Contact us',
          href: `${BASE_URL}/contact`,
        },
      ],
    },
  ];
};

export const PRODUCTS_MENU_DROPDOWN_COLUMNS = (
  BASE_URL: string
): Array<IMenuDropdownColumns> => {
  return [
    {
      title: 'Platforms',
      links: [
        {
          href: `${BASE_URL}/technologies/linux-platforms/enterprise-linux`,
          label: 'Red Hat Enterprise Linux',
        },
        {
          href: `${BASE_URL}/technologies/jboss-middleware/application-platform`,
          label: 'Red Hat JBoss Enterprise Application Platform',
        },
        {
          href: `${BASE_URL}/technologies/linux-platforms/openstack-platform`,
          label: 'Red Hat OpenStack Platform',
        },
      ],
    },
    {
      title: 'Application services',
      links: [
        {
          href: `${BASE_URL}/products/application-foundations`,
          label: 'Red Hat Application Services Portfolio',
        },
        {
          href: `${BASE_URL}/products/integration`,
          label: 'Red Hat Integration',
        },
        {
          href: `${BASE_URL}/products/process-automation`,
          label: 'Red Hat Process Automation',
        },
        {
          href: `${BASE_URL}/products/runtimes`,
          label: 'Red Hat Runtimes',
        },
      ],
    },
    {
      title: 'Cloud services',
      links: [
        {
          href: `${BASE_URL}/technologies/cloud-computing/openshift/aws`,
          label: 'Red Hat OpenShift Service on AWS',
        },
        {
          href: `${BASE_URL}/technologies/cloud-computing/openshift/azure`,
          label: 'Microsoft Azure Red Hat OpenShift',
        },
        {
          href: `${BASE_URL}/technologies/cloud-computing/openshift/openshift-api-management`,
          label: 'Red Hat OpenShift API Management',
        },
        {
          href: `${BASE_URL}/technologies/cloud-computing/openshift/openshift-data-science`,
          label: 'Red Hat OpenShift Data Science',
        },
        {
          href: `${BASE_URL}/technologies/cloud-computing/openshift/dedicated`,
          label: 'Red Hat OpenShift Dedicated',
        },
        {
          href: `${BASE_URL}/technologies/cloud-computing/openshift/ibm`,
          label: 'Red Hat OpenShift on IBM Cloud',
        },
        {
          href: `${BASE_URL}/technologies/cloud-computing/openshift/openshift-service-registry`,
          label: 'Red Hat OpenShift Service Registry',
        },
        {
          href: `${BASE_URL}/technologies/cloud-computing/openshift/openshift-streams-for-apache-kafka`,
          label: 'Red Hat OpenShift Streams for Apache Kafka',
        },
      ],
    },
    {
      title: 'Data Services',
      links: [
        {
          href: `${BASE_URL}/technologies/cloud-computing/openshift-data-foundation`,
          label: 'Red Hat OpenShift Data Foundation',
        },
        {
          href: `${BASE_URL}/technologies/storage/ceph`,
          label: 'Red Hat Ceph Storage',
        },
        {
          href: `${BASE_URL}/technologies/storage/gluster`,
          label: 'Red Hat Gluster Storage',
        },
        {
          href: `${BASE_URL}/technologies/storage/hyperconverged-infrastructure`,
          label: 'Red Hat Hyperconverged Infrastructure',
        },
      ],
    },
    {
      title: 'Cloud computing',
      links: [
        {
          href: `${BASE_URL}/technologies/cloud-computing/openshift`,
          label: 'Red Hat OpenShift',
        },
        {
          href: `${BASE_URL}/products/edge`,
          label: 'Red Hat Edge',
        },
        {
          href: `${BASE_URL}/technologies/linux-platforms/enterprise-linux`,
          label: 'Red Hat Enterprise Linux',
        },
        {
          href: `${BASE_URL}/technologies/storage/hyperconverged-infrastructure`,
          label: 'Red Hat Hyperconverged Infrastructure',
        },
        {
          href: `${BASE_URL}/technologies/linux-platforms/openstack-platform`,
          label: 'Red Hat OpenStack Platform',
        },
        {
          href: `${BASE_URL}/technologies/cloud-computing/quay`,
          label: 'Red Hat Quay',
        },
      ],
    },
    {
      title: 'Application development',
      links: [
        {
          href: `${BASE_URL}/products/application-foundations`,
          label: 'Red Hat Application Services portfolio',
        },
        {
          href: `${BASE_URL}/technologies/jboss-middleware/codeready-studio`,
          label: 'Red Hat CodeReady Studio',
        },
        {
          href: `${BASE_URL}/technologies/jboss-middleware/codeready-workspaces`,
          label: 'Red Hat CodeReady Workspaces',
        },
        {
          href: `${BASE_URL}/technologies/jboss-middleware/application-platform`,
          label: 'Red Hat JBoss Enterprise Application Platform',
        },
        {
          href: `${BASE_URL}/technologies/cloud-computing/openshift`,
          label: 'Red Hat OpenShift',
        },
      ],
    },
    {
      title: 'Automation and management',
      links: [
        {
          href: `${BASE_URL}/technologies/management/ansible`,
          label: 'Red Hat Ansible Automation Platform',
        },
        {
          href: `${BASE_URL}/technologies/management/advanced-cluster-management`,
          label: 'Red Hat Advanced Cluster Management for Kubernetes',
        },
        {
          href: `${BASE_URL}/technologies/cloud-computing/openshift/advanced-cluster-security-kubernetes`,
          label: 'Red Hat Advanced Cluster Security for Kubernetes',
        },
        {
          href: `${BASE_URL}/technologies/management/insights`,
          label: 'Red Hat Insights',
        },
        {
          href: `${BASE_URL}/products/process-automation`,
          label: 'Red Hat Process Automation',
        },
        {
          href: `${BASE_URL}/technologies/management/satellite`,
          label: 'Red Hat Satellite',
        },
        {
          href: `${BASE_URL}/technologies/management/smart-management`,
          label: 'Red Hat Smart Management',
        },
      ],
    },
  ];
};

export const SOLUTIONS_MENU_DROPDOWN_COLUMNS = (
  BASE_URL: string
): Array<IMenuDropdownColumns> => {
  return [
    {
      title: 'By category',
      links: [
        {
          href: `${BASE_URL}/solutions/it-automation`,
          label: 'Automation',
        },
        {
          href: `${BASE_URL}/solutions/cloud-native-development`,
          label: 'Cloud-native development',
        },
        {
          href: `${BASE_URL}/solutions/digital-transformation`,
          label: 'Digital transformation',
        },
        {
          href: `${BASE_URL}/solutions/infrastructure`,
          label: 'Infrastructure',
        },
        {
          href: `${BASE_URL}/solutions/digital-transformation/sap`,
          label: 'SAP workloads',
        },
      ],
    },
    {
      title: 'By organization type',
      links: [
        {
          href: `${BASE_URL}/solutions/automotive`,
          label: 'Automotive',
        },
        {
          href: `${BASE_URL}/solutions/financial-services`,
          label: 'Financial Services',
        },
        {
          href: `${BASE_URL}/solutions/healthcare`,
          label: 'Healthcare',
        },
        {
          href: `${BASE_URL}/solutions/industrial-sector`,
          label: 'Industrial sector',
        },
        {
          href: `${BASE_URL}/solutions/media-entertainment`,
          label: 'Media and entertainment',
        },
        {
          href: `${BASE_URL}/solutions/mid-sized-organizations`,
          label: 'Mid-sized',
        },
        {
          href: `${BASE_URL}/solutions/public-sector`,
          label: 'Public sector',
        },
        {
          href: `${BASE_URL}/solutions/telecommunications`,
          label: 'Telecommunications',
        },
      ],
    },
    {
      title: 'By customer',
      links: [
        {
          href: `${BASE_URL}/success-stories/british-army`,
          label: 'British Army',
        },
        {
          href: `${BASE_URL}/success-stories/edenor`,
          label: 'Edenor',
        },
        {
          href: `${BASE_URL}/success-stories/hca-healthcare`,
          label: 'HCA Healthcare',
        },
        {
          href: `${BASE_URL}/success-stories/macquarie`,
          label: 'Macquarie Bank',
        },
        {
          href: `${BASE_URL}/success-stories/tata-consultancy-services`,
          label: 'Tata Consultancy Services',
        },
        {
          href: `${BASE_URL}/success-stories/ups`,
          label: 'UPS',
        },
        {
          href: `${BASE_URL}/success-stories`,
          label: 'Search all success stories',
        },
      ],
    },
  ];
};

export const TRAINING_AND_SERVICES_DROPDOWN_COLUMNS = (
  BASE_URL: string
): Array<IMenuDropdownColumns> => {
  return [
    {
      title: 'Services',
      href: `${BASE_URL}/services`,
      links: [
        {
          href: `${BASE_URL}/services/consulting`,
          label: 'Consulting',
        },
        {
          href: `${BASE_URL}/services/consulting/open-innovation-labs`,
          label: 'Open Innovation Labs',
        },
        {
          href: `${BASE_URL}/services/support/technical-account-management`,
          label: 'Technical Account Management',
        },
      ],
    },
    {
      title: 'Training and certification',
      href: `${BASE_URL}/services/training-and-certification`,
      links: [
        {
          href: `${BASE_URL}/services/training/all-courses-exams`,
          label: 'All courses and exams',
        },
        {
          href: `${BASE_URL}/services/certifications`,
          label: 'All certifications',
        },
        {
          href: 'https://rhtapps.redhat.com/verify/',
          label: 'Verify a certification',
        },
        {
          href: '/',
          label: 'Skills assessment',
        },
        {
          href: `${BASE_URL}/services/training/learning-subscription`,
          label: 'Learning subscription',
        },
        {
          href: 'https://learn.redhat.com/',
          label: 'Learning community',
        },
        {
          href: `${BASE_URL}/services/training/red-hat-academy`,
          label: 'Red Hat Academy',
        },
        {
          href: `${BASE_URL}/services/training-and-certification/faq`,
          label: 'FAQs',
        },
        {
          href: `${BASE_URL}/services/training-and-certification/contact-us`,
          label: 'Connect with learning experts',
        },
      ],
    },
    {
      title: 'Featured',
      links: [
        {
          href: `${BASE_URL}/services/training/rh124-red-hat-system-administration-i`,
          label: 'Red Hat System Administration I (RH124)',
        },
        {
          href: `${BASE_URL}/services/training/do280-red-hat-openshift-administration-i`,
          label: 'Red Hat OpenShift Administration I (DO280)',
        },
        {
          href: `${BASE_URL}/services/certification/rhcsa`,
          label: 'Red Hat Certified System Administrator (RHCSA)',
        },
        {
          href: `${BASE_URL}/services/certification/rhce`,
          label: 'Red Hat Certified Engineer (RHCE)',
        },
      ],
    },
  ];
};

export const RESOURCES_MENU_DROPDOWN_COLUMNS = (
  BASE_URL: string
): Array<IMenuDropdownColumns> => {
  return [
    {
      title: 'Our vision',
      links: [
        {
          href: `${BASE_URL}/topics/security/devsecops/approach`,
          label: 'DevSecOps',
        },
        {
          href: `${BASE_URL}/topics/edge-computing/approach`,
          label: 'Edge computing',
        },
        {
          href: `${BASE_URL}/topics/containers/kubernetes-approach`,
          label: 'Kubernetes',
        },
        {
          href: `${BASE_URL}/topics/cloud/open-hybrid-cloud-approach`,
          label: 'Open hybrid cloud',
        },
      ],
    },
    {
      title: 'Topics',
      links: [
        {
          href: `${BASE_URL}/topics/api`,
          label: 'APIs',
        },
        {
          href: `${BASE_URL}/topics/automation`,
          label: 'Automation',
        },
        {
          href: `${BASE_URL}/topics/cloud`,
          label: 'Cloud computing',
        },
        {
          href: `${BASE_URL}/topics/devops`,
          label: 'DevOps',
        },
        {
          href: `${BASE_URL}/topics/digital-transformation`,
          label: 'Digital transformation',
        },
        {
          href: `${BASE_URL}/topics/edge-computing`,
          label: 'Edge computing',
        },
        {
          href: `${BASE_URL}/topics/linux`,
          label: 'Linux',
        },
        {
          href: `${BASE_URL}/topics/microservices`,
          label: 'Microservices',
        },
        {
          href: `${BASE_URL}/topics/security`,
          label: 'Security',
        },
        {
          href: `${BASE_URL}/topics/virtualization`,
          label: 'Virtualization',
        },
        {
          href: `${BASE_URL}/topics`,
          label: 'See all topics',
        },
      ],
    },
    {
      title: 'Articles',
      links: [
        {
          href: `${BASE_URL}/topics/cloud-computing/what-are-cloud-services`,
          label: 'What are cloud services?',
        },
        {
          href: `${BASE_URL}/topics/edge-computing/what-is-edge-computing`,
          label: 'What is edge computing?',
        },
        {
          href: `${BASE_URL}/topics/cloud-computing/what-is-hybrid-cloud`,
          label: 'What is hybrid cloud?',
        },
        {
          href: `${BASE_URL}/topics/cloud-computing/why-choose-red-hat-cloud`,
          label: 'Why build a Red Hat cloud?',
        },
        {
          href: `${BASE_URL}/topics/cloud-computing/cloud-vs-edge`,
          label: 'Cloud vs. edge',
        },
        {
          href: `${BASE_URL}/technologies/cloud-computing/openshift/red-hat-openshift-kubernetes`,
          label: 'Red Hat OpenShift vs. Kubernets',
        },
        {
          href: `${BASE_URL}/topics/automation/learning-ansible-tutorial`,
          label: 'Learning Ansible basics',
        },
        {
          href: `${BASE_URL}/topics/linux/what-is-linux`,
          label: 'What is Linux?',
        },
      ],
    },
    {
      title: 'More to explore',
      links: [
        {
          href: `${BASE_URL}/blog`,
          label: 'Blog',
        },
        {
          href: `${BASE_URL}/success-stories`,
          label: 'Customer success stories',
        },
        {
          href: `${BASE_URL}/events`,
          label: 'Events and webinars',
        },
        {
          href: `${BASE_URL}/about/newsroom`,
          label: 'Newsroom',
        },
        {
          href: `${BASE_URL}/red-hat-podcasts`,
          label: 'Podcasts',
        },
        {
          href: `${BASE_URL}/resources`,
          label: 'Resource library',
        },
        {
          href: `${BASE_URL}/services/training-and-certification`,
          label: 'Training and certification',
        },
      ],
    },
  ];
};

export const PARTNERS_MENU_DROPDOWN_COLUMNS = (
  BASE_URL: string
): Array<IMenuDropdownColumns> => {
  return [
    {
      title: 'For customers',
      links: [
        {
          href: `${BASE_URL}/partners`,
          label: 'Explore our partners',
        },
        {
          href: 'https://catalog.redhat.com/',
          label: 'Red Hat Ecosystem Catalog',
        },
        {
          href: `${BASE_URL}/partners/aws`,
          label: 'Red Hat on AWS',
        },
        {
          href: `${BASE_URL}/partners/microsoft`,
          label: 'Red Hat on Microsoft Azure',
        },
        {
          href: `${BASE_URL}/partners/google`,
          label: 'Red Hat on Google Cloud',
        },
        {
          href: `${BASE_URL}/partners/isv`,
          label: 'ISV software solutions',
        },
        {
          href: `${BASE_URL}/partners/global-systems-integrators-and-advisory`,
          label: 'Global Systems Integrators',
        },
      ],
    },
    {
      title: 'For partners',
      links: [
        {
          href: 'https://connect.redhat.com/',
          label: 'Partner Connect',
        },
        {
          href: 'https://connect.redhat.com/en/benefits-of-being-a-partner',
          label: 'Become a partner',
        },
        {
          href: 'https://connect.redhat.com/en/training',
          label: 'Training',
        },
        {
          href: 'https://connect.redhat.com/en/support',
          label: 'Support',
        },
        {
          href: 'https://connect.redhat.com/login',
          label: 'Log in',
        },
      ],
    },
  ];
};

export const ABOUT_MENU_DROPDOWN_COLUMNS = (
  BASE_URL: string
): Array<IMenuDropdownColumns> => {
  return [
    {
      title: 'About us',
      links: [
        {
          href: `${BASE_URL}/about/company`,
          label: 'Our company',
        },
        {
          href: `${BASE_URL}/about/our-culture`,
          label: 'How we work',
        },
        {
          href: `${BASE_URL}/about/community-social-responsibility`,
          label: 'Our social impact',
        },
        {
          href: `${BASE_URL}/about/development-model`,
          label: 'Development model',
        },
        {
          href: `${BASE_URL}/about/value-of-subscription`,
          label: 'Subscription model',
        },
        {
          href: `${BASE_URL}/services/support`,
          label: 'Product Support',
        },
      ],
    },
    {
      title: 'Open source',
      links: [
        {
          href: `${BASE_URL}/about/open-source`,
          label: 'Open source commitments',
        },
        {
          href: `${BASE_URL}/about/our-community-contributions`,
          label: 'How we contribute',
        },
        {
          href: 'https://github.com/RedHatOfficial',
          label: 'Red Hat on GitHub',
        },
      ],
    },
    {
      title: 'Company details',
      links: [
        {
          href: `${BASE_URL}/about/analysts`,
          label: 'Analyst relations',
        },
        {
          href: `${BASE_URL}/blog`,
          label: 'Blog',
        },
        {
          href: `${BASE_URL}/about/office-locations`,
          label: 'Locations',
        },
        {
          href: `${BASE_URL}/about/newsroom`,
          label: 'Newsroom',
        },
      ],
    },
    {
      title: 'Communities',
      links: [
        {
          href: 'https://www.ansible.com/community',
          label: 'Ansible',
        },
        {
          href: 'https://opensource.com/',
          label: 'For open source enthusiasts',
        },
        {
          href: 'https://www.redhat.com/sysadmin/',
          label: 'For system administrators',
        },
        {
          href: 'https://enterprisersproject.com/',
          label: 'For IT leaders',
        },
        {
          href: 'https://www.redhat.com/architect/',
          label: 'For architects',
        },
        {
          href: 'https://access.redhat.com/accelerators',
          label: 'Customer advocacy',
        },
      ],
    },
  ];
};
