import React, { useEffect, useState, Fragment } from 'react';
import {
  TableComposable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  TableText,
} from '@patternfly/react-table';
import {
  Split,
  SplitItem,
  Switch,
  Bullseye,
  EmptyState,
  EmptyStateBody,
  EmptyStateIcon,
  EmptyStateVariant,
  Pagination,
  PaginationVariant,
  SearchInput,
  Title,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
  ToolbarItemVariant,
  Button,
  Hint,
  HintBody,
  AlertActionLink,
} from '@patternfly/react-core';
import { SectionHeader } from '@app/components/PageHeader/PageHeader';
import { SearchIcon } from '@patternfly/react-icons';
import './UserTable.scss';
import usePagination from '@app/hooks/usePagination';
import useSorting from '@app/hooks/useSorting';
import { LocalizationStore } from '@app/state/LocalizationStore';
import { UserData } from '@app/utils/reporting';
import InvitationForm from '@src/app/components/UsersTable/InvitationForm/InvitationForm';
import RevokeModal from './RevokeModal/RevokeModal';
import { useAlert } from '@app/hooks/useAlert';
import { gql, graphql, rest } from '@app/services/http';
import { parseUserData } from '@app/utils/reporting';
import { ICampaignModel } from '@app/types';
import { v4 } from 'uuid';
import { UserStore } from '@app/state/UserStore';

const GET_UPDATED_REPORTS = gql`
  query GetTeamReport($campaign_id: uuid!) {
    skill_campaigns_by_pk(id: $campaign_id) {
      reports {
        id
        timestamp
        metadata
        score
        suggestions
        statistics
      }
    }
  }
`;

interface IUserTableProps {
  userData: UserData[];
  selected: string[];
  onSelectionChange: (string) => void;
  campaign: ICampaignModel;
  setTeamStatistics: (value: any) => void;
}

export interface SubmitData {
  email: string;
  group?: string | undefined;
  assessmentId: string;
}

const UserTable: React.FC<IUserTableProps> = ({
  userData,
  selected,
  onSelectionChange,
  campaign,
  setTeamStatistics,
}) => {
  const [isInvitationModalOpen, setIsInvitationModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState<UserData[]>([]);
  const user = UserStore();
  const localization = LocalizationStore();
  const { t } = localization.useTranslation();

  const dataToBePaginated = filteredData.length > 0 ? filteredData : userData;
  const [paginationCount, setPaginationCount] = useState(
    dataToBePaginated.length
  );
  const { addAlert } = useAlert();
  const {
    page,
    perPage,
    shownData,
    setShownData,
    startSlicing,
    endSlicing,
    setPage,
    onChangePage,
    onPerPageSelect,
    onSetPage,
  } = usePagination(dataToBePaginated);
  const { activeSortIndex, activeSortDirection, getSortParams } = useSorting();

  const onChange = (
    value: string,
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const newFilteredData = userData.filter((user) =>
      user.identifier.toLowerCase().includes(value.toLowerCase())
    );
    newFilteredData.length > 0
      ? setFilteredData(newFilteredData)
      : handleNoFilteredDataFound();
  };

  const getSortableRowValue = (row: UserData): string[] => {
    const { identifier, group } = row;

    return [identifier, group];
  };

  useEffect(() => {
    setShownData(dataToBePaginated.slice(startSlicing, endSlicing));
    setPaginationCount(dataToBePaginated.length);
  }, [
    userData,
    endSlicing,
    setShownData,
    startSlicing,
    userData.length,
    dataToBePaginated,
  ]);

  useEffect(() => {
    // handle the case when we update the filters and do not update the page
    // we need to update the page, so the pagination count doesnt look wrong
    if (shownData.length < startSlicing) {
      setPage(1);
    }
  }, [searchValue]);

  useEffect(() => {
    if (activeSortIndex !== null) {
      const sortedData = userData.sort((a, b) => {
        const aValue = getSortableRowValue(a)[activeSortIndex];
        const bValue = getSortableRowValue(b)[activeSortIndex];

        if (activeSortDirection === 'asc') {
          return (aValue as string).localeCompare(bValue as string);
        }
        return (bValue as string).localeCompare(aValue as string);
      });
      setShownData(sortedData);
    }
  }, [activeSortDirection, activeSortIndex, userData]);

  const onParticipantIsSelected = (id) => {
    const sp: string[] = [...selected];

    if (sp.includes(id)) {
      sp.splice(sp.indexOf(id), 1);
    } else {
      sp.push(id);
    }

    onSelectionChange(sp);
  };

  const handleNoFilteredDataFound = () => {
    setShownData([]);
    setPaginationCount(0);
  };

  const [revokeModal, setRevokeModal] = useState({
    open: false,
    loading: false,
    email: '',
    assessmentId: '',
  });

  const options = userData
    .filter((userData, index, array) => {
      return (
        userData.assessmentId &&
        userData.assessmentName &&
        array.findIndex(
          (a) =>
            a.assessmentId === userData.assessmentId &&
            a.assessmentName === userData.assessmentName
        ) === index
      );
    })
    .map((parsedUserData) => ({
      key: parsedUserData.assessmentId,
      value: parsedUserData.assessmentName,
    }));

  const getInvitations = async () => {
    await graphql
      .request(GET_UPDATED_REPORTS, {
        campaign_id: campaign.id,
      })
      .then((data: any) => {
        setTeamStatistics((state) => ({
          ...state,
          allUsersData: parseUserData(
            data['skill_campaigns_by_pk'].reports,
            campaign
          ),
        }));
      })
      .catch((error: Error) => {
        throw {
          title: 'Could not update the table, please hit try again',
          message: error.message,
          actionLinks: (
            <React.Fragment>
              <AlertActionLink onClick={getInvitations}>
                {t('Refresh')}
              </AlertActionLink>
            </React.Fragment>
          ),
        };
      });
  };

  const handleSendInvitation = async (data: SubmitData) => {
    try {
      const response = await rest.post('/authoring/invitations/invite', {
        json: {
          campaign: campaign.id,
          email: data.email,
          sheet: data.assessmentId, // which sheet_id to associate to the invitation. Must already be part of the team assessment
          ...(data.group ? { group: data.group } : {}),
        },
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      } else {
        await getInvitations();

        addAlert(
          t('Invitation was sent successfully'),
          'success',
          v4(),
          t('The user will receive an email for taking the assessment.')
        );
      }
    } catch (error: any) {
      addAlert(
        error.title ? t(error.title) : t('Error while processing the request'),
        'danger',
        v4(),
        t(error.message),
        error.actionLinks
      );
    } finally {
      setIsInvitationModalOpen(false);
    }
  };

  const handleRevokeInvitation = async () => {
    try {
      setRevokeModal((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const response = await rest.post('/authoring/invitations/revoke', {
        json: {
          campaign: campaign.id,
          email: revokeModal.email,
          sheet: revokeModal.assessmentId,
        },
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      } else {
        await getInvitations();

        addAlert(
          t('Invitation was revoked successfully'),
          'success',
          v4(),
          t('The user will not have access to the assessment anymore.')
        );
      }
    } catch (error: any) {
      addAlert(
        error.title ? t(error.title) : t('Error while revoking the invitation'),
        'danger',
        v4(),
        t(error.message),
        error.actionLinks
      );
    } finally {
      setRevokeModal({
        open: false,
        loading: false,
        email: '',
        assessmentId: '',
      });
    }
  };

  return (
    <Fragment>
      <div className="top-section">
        {campaign.reports.length > 0 ? (
          <SectionHeader
            title={t('Participants')}
            description={t(
              'Check the status of invitations and see reports from completed assessments'
            )}
          />
        ) : (
          <SectionHeader
            title={t('No response yet')}
            description={t(
              'Once at least one participant will have completed an assessment you\'ll see the results here'
            )}
          />
        )}
        {user.current.uuid === campaign.owner_id && (
          <Button
            variant="primary"
            className="share-button"
            onClick={() => setIsInvitationModalOpen(true)}
          >
            {t('Invite Participant')}
          </Button>
        )}
      </div>

      {campaign.reports.length > 0 && (
        <Hint className="hint">
          <HintBody>
            {t(
              'This report is interactive: selecting one or more entries from this table will dynamically filter the presented metrics.'
            )}
          </HintBody>
        </Hint>
      )}
      <Split>
        <SplitItem
          isFilled
          className="table-toolbar"
        >
          <Toolbar>
            <ToolbarContent>
              <ToolbarItem>
                <Button
                  variant="tertiary"
                  isSmall
                  onClick={() => {
                    onSelectionChange([]);
                  }}
                >
                  {t('Reset')}
                </Button>
              </ToolbarItem>
            </ToolbarContent>
          </Toolbar>
        </SplitItem>
        <SplitItem>
          <Toolbar>
            <ToolbarContent>
              <ToolbarItem></ToolbarItem>
              <ToolbarItem variant={ToolbarItemVariant['search-filter']}>
                <SearchInput
                  value={searchValue}
                  placeholder={`${t('Search identifier')}`}
                  onChange={onChange}
                  onClear={() => setFilteredData([])}
                />
              </ToolbarItem>
            </ToolbarContent>
          </Toolbar>
        </SplitItem>
        <SplitItem>
          <Pagination
            perPageComponent="button"
            itemCount={paginationCount}
            perPage={perPage}
            page={page}
            onSetPage={onSetPage}
            onNextClick={onChangePage}
            onPreviousClick={onChangePage}
            onLastClick={onChangePage}
            onFirstClick={onChangePage}
            widgetId="pagination-options-menu-top"
            onPerPageSelect={onPerPageSelect}
            variant={PaginationVariant.bottom}
          />
        </SplitItem>
      </Split>

      {shownData && (
        <TableComposable
          className="users-table"
          aria-label="Users Table"
        >
          <Thead>
            <Tr aria-label="Users Table Columns">
              <Th
                width={10}
                label="Selected"
              />
              <Th
                width={20}
                label="Identifier"
                sort={getSortParams(0)}
              >
                {t('Identifier')}
              </Th>
              <Th
                width={15}
                label="Group"
                sort={getSortParams(1)}
              >
                {t('Group')}
              </Th>
              <Th
                width={25}
                label="Assessments"
                textCenter
              >
                {t('Assessments')}
              </Th>
              <Th
                width={25}
                label="Recommendation"
                textCenter
              >
                {t('Recommendation')}
              </Th>
              <Th
                width={10}
                label="Answered on"
                textCenter
              >
                {t('Answered on')}
              </Th>
              <Th
                width={10}
                label="Action"
                textCenter
              >
                {t('Action')}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {shownData.length > 0 ? (
              shownData.map((entry, i) => (
                <Tr
                  key={`${i}`}
                  className={`${entry.taken ? 'taken' : 'not-taken'} ${
                    selected.includes(entry.report_id) ? 'selected' : ''
                  }`}
                >
                  <Td
                    dataLabel="Selected"
                    key={`selected-${i}`}
                    noPadding
                    textCenter
                  >
                    {entry.taken && (
                      <Switch
                        aria-label="Selected"
                        isChecked={selected.includes(entry.report_id)}
                        isDisabled={!entry.taken}
                        onChange={() =>
                          onParticipantIsSelected(entry.report_id)
                        }
                      />
                    )}
                  </Td>
                  <Td
                    dataLabel="Identifier"
                    key={`identifier-${i}`}
                    modifier="truncate"
                    noPadding
                  >
                    {entry.identifier}
                  </Td>
                  <Td
                    dataLabel="Group"
                    modifier="truncate"
                    noPadding
                  >
                    {entry.group}
                  </Td>
                  <Td
                    dataLabel="Assessments"
                    modifier="wrap"
                    noPadding
                  >
                    <TableText
                      wrapModifier="truncate"
                      key={`taken-assessment-name-${i}`}
                    >
                      {entry.assessmentName}
                    </TableText>
                  </Td>
                  <Td
                    dataLabel="Recommendation"
                    modifier="wrap"
                    noPadding
                  >
                    <TableText wrapModifier="truncate">
                      {entry.recommendation ? entry.recommendation : '-'}
                    </TableText>
                  </Td>
                  <Td
                    dataLabel="Answered on"
                    modifier="truncate"
                    noPadding
                  >
                    <TableText
                      wrapModifier="truncate"
                      key={`any-answered-on-text-${i}`}
                    >
                      {entry.taken
                        ? new Date(entry.answered).toLocaleDateString(
                          localization.currentLanguageCode
                        )
                        : '-'}
                    </TableText>
                  </Td>
                  <Td
                    className="action-column"
                    textCenter
                  >
                    {!entry.taken && user.current.uuid === campaign.owner_id ? (
                      <Button
                        variant="tertiary"
                        onClick={() =>
                          setRevokeModal((prevState) => ({
                            ...prevState,
                            open: true,
                            email: entry.email,
                            assessmentId: entry.assessmentId,
                          }))
                        }
                      >
                        {t('Revoke Invitation')}
                      </Button>
                    ) : (
                      '-'
                    )}
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={8}>
                  <Bullseye>
                    <EmptyState variant={EmptyStateVariant.small}>
                      <EmptyStateIcon icon={SearchIcon} />
                      <Title
                        headingLevel="h2"
                        size="lg"
                      >
                        {t('No results found')}
                      </Title>
                      <EmptyStateBody>
                        {t('Clear all filters and try again.')}
                      </EmptyStateBody>
                      <Button
                        variant="link"
                        onClick={() => {
                          setSearchValue('');
                          setFilteredData([]);
                        }}
                      >
                        {t('Clear all filters')}
                      </Button>
                    </EmptyState>
                  </Bullseye>
                </Td>
              </Tr>
            )}
          </Tbody>
        </TableComposable>
      )}
      {/*Invitation form modal*/}
      <InvitationForm
        onSubmit={handleSendInvitation}
        emailsForValidation={userData.map((user) => user.email)}
        selectData={options}
        isInvitationModalOpen={isInvitationModalOpen}
        setIsInvitationModalOpen={setIsInvitationModalOpen}
      />
      <RevokeModal
        revokeModal={revokeModal}
        setRevokeModal={setRevokeModal}
        handleRevokeInvitation={handleRevokeInvitation}
      />
    </Fragment>
  );
};

export default UserTable;
