// LEGACY, TO BE REMOVED
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { gql } from '@apollo/client';
import { client } from '@app/services/ApolloClient';
import { useMst } from '@app/state/RootStore';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Banner, PageSection } from '@patternfly/react-core';
import SingleChoiceQuestion from '@app/components/PresenterPanesLegacy/SingleChoiceQuestion';
import LinkNodeWrapper from '@app/components/PresenterPanesLegacy/LinkNodeWrapper';
import UpdateCompany from '@app/components/PresenterPanes/UpdateCompany';
import './Presenter.scss';
import { PageHero } from '@app/components/PageHero/PageHero';
import { Loader } from '@app/components/Loader/Loader';
import { PartnerStore } from '@app/state/PartnerStore';
import { UserStore } from '@app/state/UserStore';
import { LocalizationStore } from '@app/state/LocalizationStore';

export const PresenterLegacy = observer(() => {
  const { id } = useParams();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { tracker, catalog } = useMst();
  const { user, isArchitect, isUserReady } = UserStore((state) => ({
    user: state.current,
    isArchitect: state.isArchitect,
    isUserReady: state.ready,
  }));

  const localization = LocalizationStore();
  const { t } = localization.useTranslation();

  const partner = PartnerStore();

  const [loading, setLoading] = useState(true);
  const [currentInvitation, setCurrentInvitation] = useState<any>({});

  const GET_INVITATION = gql`
    query GetInvitation(
      $invitation_id: uuid!
    ) {
      skill_invitations_by_pk(
        id: $invitation_id
      ){
        id
        campaign_id
        metadata
        partner
     }
    }
  `;

  const MAXIMUM_SUGGESTIONS = 3;

  const getScoredUnits = (userScore) => {
    return Object.keys(userScore).map(k => {
      const unit = catalog.get_unit(k);

      if (!unit) {
        throw new Error(`inconsistent units in assessment result: expecting ${k} but none found in the catalog`);
      }

      const scored = userScore[unit.id];
      const maximum = tracker.maximumScore.get(unit.id) as number || 1;

      return {
        id: unit.id,
        name: unit.name,
        providers: unit.providers,
        points: scored,
        maximum: maximum,
        score: Math.round(scored * 100 / maximum),
      }
    });
  };

  const getWeightedOfferings = (scoredUnits) => {
    const weightedOfferings = {};

    scoredUnits.forEach(s => {
      const correspondingOffering = s.score === 100 ? s.providers.length - 1 : Math.floor(s.providers.length * s.score / 100);

      const offering = s.providers[correspondingOffering];

      weightedOfferings[offering.id] = (weightedOfferings[offering.id] || 0) + 1;
    });

    return weightedOfferings;
  };

  const getSuggestions = (weightedOfferings) => {
    const pairedOfferings = Object.keys(weightedOfferings).map(k => {
      return [k, weightedOfferings[k]];
    });

    pairedOfferings.sort((first, second) => {
      return second[1] - first[1];
    });

    return pairedOfferings.map(i => catalog.get_offering(i[0])).slice(0, MAXIMUM_SUGGESTIONS);
  };

  useEffect(() => {
    const invitation = searchParams.get('invitation');

    if (invitation) {
      client.query({
        query: GET_INVITATION,
        variables: {
          invitation_id: invitation,
        }
      }).then((res) => {
        const i = res.data['skill_invitations_by_pk'];

        if(i){
          setCurrentInvitation(i);
        }
      });
    }
  }, [searchParams])

  useEffect(() => {
    catalog.load();
  }, [])

  useEffect(() => {
    if (catalog.ready) {
      const assessment = catalog.get_assessment(id);

      if (!assessment) {
        navigate('/not-found')
      } else {
        tracker.track(assessment);
        console.log('OK')
      }
    }
  }, [catalog.ready])

  useEffect(() => {
    if (tracker.shownAssessment) {
      setLoading(false);
    }
  }, [tracker.shownAssessment]);

  useEffect(() => {
    tracker.debug.forEach(e => {
      console.log(`debug:::${e}`)
    });
    console.log('---')
  }, [tracker.debug.length]);

  const refreshDebug = () => {
    tracker.clear_debug();

    const userScore = tracker.userScore.toJSON();

    const score = getScoredUnits(userScore);

    tracker.add_debug('SCORES');
    Object.keys(score).forEach(uid => {
      const scored = score[uid];
      const providers_labels = scored.providers.map(offering => {
        return offering.label ? offering.label : '!UNKNOWN';
      })

      tracker.add_debug(`>>> ${scored.name} *** ${scored.score}% (${scored.points}/${scored.maximum} pts) *** ${providers_labels.join('/')}`);
    });

    const weightedOfferings = getWeightedOfferings(score);

    const debug_weightedOfferings = Object.keys(weightedOfferings).map((oid, index) => {
      const offering = catalog.get_offering(oid);

      return `>>> ${index + 1} *** ${offering ? offering.label + ' - ' + offering.name : '!UNKNOWN'} *** ${weightedOfferings[oid]}`;
    });

    tracker.add_debug('WEIGHTED OFFERINGS')
    debug_weightedOfferings.forEach(e => tracker.add_debug(e));

    const suggestions = getSuggestions(weightedOfferings);

    const debug_suggestions = suggestions.map((o, index) => `${index + 1}. ${o ? o.label + ' - ' + o.name : '!UNKNOWN'}`);

    tracker.add_debug('SUGGESTIONS');
    debug_suggestions.forEach(e => tracker.add_debug(e));
  };

  useEffect(() => {
    if (isArchitect()) {
      refreshDebug();
    }
  }, [tracker.userScore.toJSON(), tracker.maximumScore.toJSON()]);

  useEffect(() => {
    if (tracker.completed) {
      setLoading(true);

      const mutation = gql`
      mutation CreateReport(
        $campaign: uuid,
        $assessment_name: String!,
        $assessment_id: String!,
        $rhStartOfferId: String!,
        $rhFinishOfferId: String!,
        $partnerStartOfferId: String!,
        $partnerFinishOfferId: String!,
        $user_fullname: String!,
        $user_email: String!,
        $deputy_name: String,
        $score: json!,
        $suggestions:json!,
        $duration: Number!,
        $partner: String
      ) {
        insert_skill_reports_one(object: {
          campaign_id: $campaign,
          metadata: {
            assessment: {
              name: $assessment_name
              id: $assessment_id
              rhStartOfferId: $rhStartOfferId,
              rhFinishOfferId: $rhFinishOfferId,
              partnerStartOfferId: $partnerStartOfferId,
              partnerFinishOfferId: $partnerFinishOfferId
            }
            deputy: {
              fullname: $deputy_name
            }
            user: {
              fullname: $user_fullname
              email: $user_email
            }
          },
          score: $score,
          suggestions: $suggestions,
          statistics: {
            duration: $duration
          },
          partner: $partner
        }) {
          id
        }
      }
`;

      const userScore = tracker.userScore.toJSON();

      const score = getScoredUnits(userScore);
      const weightedOfferings = getWeightedOfferings(score);
      const suggestions = getSuggestions(weightedOfferings);

      client.mutate({
        mutation: mutation,
        variables: {
          campaign: currentInvitation.id ? currentInvitation.campaign_id : null,
          assessment_name: tracker.shownAssessment.name,
          assessment_id: tracker.shownAssessment.id,
          rhStartOfferId: tracker.shownAssessment.metadata.rhStartOfferId,
          rhFinishOfferId: tracker.shownAssessment.metadata.rhFinishOfferId,
          partnerStartOfferId: tracker.shownAssessment.metadata.partnerStartOfferId,
          partnerFinishOfferId: tracker.shownAssessment.metadata.partnerFinishOfferId,
          user_fullname: user.fullname,
          user_email: user.email,
          deputy_name: currentInvitation.id ? currentInvitation.metadata.deputy.fullname : '',
          score: score,
          suggestions: suggestions,
          duration: new Date().getTime() - tracker.startedAt,
          partner: currentInvitation.partner ? currentInvitation.partner : (partner.shortname ? partner.shortname : null)
        }
      }).then((res) => {
        const report_id = res.data.insert_skill_reports_one.id
        navigate(`/${localization.currentLanguageCode}/results/${report_id}`);
      });

    }
  }, [tracker.completed])

  return (
    !loading && isUserReady && tracker.shownAssessment ?
      <React.Fragment>
        <PageSection
          isWidthLimited={true}
          isCenterAligned={true}
          className="page-hero"
        >
          <PageHero
            title={t(tracker.shownAssessment?.name)}
            //image={RhAnsibleLogo}
          />

        </PageSection>

        {currentInvitation.id && <Banner variant="info" className="campaign-banner">
          <PageSection
            isWidthLimited={true}
            isCenterAligned={true}
          >
            {t('This is part of a team skills assessment and your results will be shared with') + ' ' + currentInvitation.metadata.deputy.fullname + '.'}
          </PageSection>
        </Banner>}

        {!user.corporate && !tracker.userUpdatedCompany
          ? <UpdateCompany />
          : <React.Fragment>
            {tracker.shownNode?.type == 'singlechoice' && <SingleChoiceQuestion />}
            {tracker.shownNode?.type == 'link' && <LinkNodeWrapper />}

          </React.Fragment>
        }
      </React.Fragment>
      : <PageSection
        isWidthLimited={true}
        isCenterAligned={true}
        className="loader lg"
      ><Loader /></PageSection>
  );
});

export default PresenterLegacy;