import React from 'react';
import { Button, Modal, ModalVariant } from '@patternfly/react-core';
import { LocalizationStore } from '@app/state/LocalizationStore';

interface RevokeState {
  open: boolean;
  loading: boolean;
  email: string;
  assessmentId: string;
}

interface RevokeModalProps {
  revokeModal: RevokeState;
  setRevokeModal: (value: React.SetStateAction<RevokeState>) => void;
  handleRevokeInvitation: () => void;
}

const RevokeModal: React.FC<RevokeModalProps> = ({
  revokeModal,
  setRevokeModal,
  handleRevokeInvitation,
}) => {
  const localization = LocalizationStore();
  const { t } = localization.useTranslation();
  const onClose = () => {
    setRevokeModal({
      open: false,
      loading: false,
      email: '',
      assessmentId: '',
    });
  };

  return (
    <Modal
      variant={ModalVariant.small}
      title={t('Revoke participants access')}
      isOpen={revokeModal.open}
      showClose={false}
      actions={[
        <Button
          key="cancel"
          variant="tertiary"
          onClick={onClose}
          isDisabled={revokeModal.loading}
        >
          {t('Cancel')}
        </Button>,
        <Button
          key="confirm"
          variant="primary"
          form="invitation-form"
          isDisabled={revokeModal.loading}
          isLoading={revokeModal.loading}
          onClick={handleRevokeInvitation}
        >
          {t('Confirm')}
        </Button>,
      ]}
    >
      {t(
        'Are you sure you want to revoke access to the assessment for {{ email }} ?',
        { email: revokeModal.email }
      )}
    </Modal>
  );
};

export default RevokeModal;
