import React, { useEffect, useState } from 'react';
import DashboardEntry from '@app/components/DashboardEntry/DashboardEntry';
import { useParams } from 'react-router-dom';
import {
  PageSection,
  Divider,
  Alert,
  AlertActionLink,
  Card,
  CardBody,
  Split,
  SplitItem,
  Stack,
  StackItem,
  List,
  ListItem,
  Grid,
  GridItem,
  Button,
} from '@patternfly/react-core';
import {
  PerformanceCard,
  ConfidenceCard,
  TextCard,
} from '@app/components/PerformanceCard/PerformanceCard';
import UsersTable from '@app/components/UsersTable/UsersTable';
import {
  ICampaignModel,
  deleteSharedEmailsMutationResponse,
  sharedEmailList,
  updateSharedEmailsMutationResponse,
} from '@app/types';
import { useNavigate } from 'react-router-dom';
import { SectionHeader } from '@app/components/PageHeader/PageHeader';
import { Loader } from '@app/components/Loader/Loader';
import { parseTeamReport } from '@app/utils/reporting';
import { downloadReportZip } from '@app/utils/reporting';
import './TeamReport.scss';
import { splitLongWords } from '@app/utils/helpers';

// needed for bundle optimization
import {
  Chart as ChartJS,
  Tooltip,
  LinearScale,
  LineElement,
  PointElement,
  RadialLinearScale,
  Filler,
} from 'chart.js';
ChartJS.register(
  Tooltip,
  LinearScale,
  PointElement,
  LineElement,
  RadialLinearScale,
  Filler
);

import { Radar } from 'react-chartjs-2';
import ShareTable from '@app/components/ShareTable/ShareTable';
import { LocalizationStore } from '@app/state/LocalizationStore';
import { UserStore } from '@app/state/UserStore';
import { gql, graphql } from '@app/services/http';
import { RequestDocument } from 'graphql-request';

const SKILL_GAP_THRESHOLD = 90; // everything under this score will be considered a gap
const GET_TEAM_REPORT = gql`
  query GetTeamReport($campaign_id: uuid!) {
    skill_campaigns_by_pk(id: $campaign_id) {
      id
      expiration
      metadata
      reports {
        id
        timestamp
        metadata
        score
        suggestions
        statistics
      }
      owner_id
      shared_email_1
      shared_email_2
      shared_email_3
      shared_email_4
      shared_email_5
      shared_email_6
      shared_email_7
      shared_email_8
      shared_email_9
      shared_email_10
    }
  }
`;

const UPDATE_MUTATION = {
  mutation: gql`
    mutation UpdateCampaignsSharedEmails(
      $updates: [skill_campaigns_updates!]!
    ) {
      update_skill_campaigns_many(updates: $updates) {
        returning {
          shared_email_1
          shared_email_2
          shared_email_3
          shared_email_4
          shared_email_5
          shared_email_6
          shared_email_7
          shared_email_8
          shared_email_9
          shared_email_10
        }
      }
    }
  `,
  variables: (emailsForUpdate: sharedEmailList, id: string | undefined) => ({
    updates: {
      _set: emailsForUpdate,
      where: {
        id: {
          _eq: id,
        },
      },
    },
  }),
};

const DELETE_MUTATION = {
  mutation: (fieldName: string) => gql`
  mutation UpdateSharedEmailField(
    $campaign_id: uuid!
    $fieldValue: String
  ) {
    update_skill_campaigns_by_pk(
      _set: { ${fieldName}: $fieldValue }
      pk_columns: { id: $campaign_id }
    ) {
      shared_email_1
      shared_email_2
      shared_email_3
      shared_email_4
      shared_email_5
      shared_email_6
      shared_email_7
      shared_email_8
      shared_email_9
      shared_email_10
    }
  }
`,
  variables: (campaign_id: string | undefined, fieldValue: string | null) => ({
    campaign_id,
    fieldValue,
  }),
};

{
  /*
  SHARING

  To be able to safely share this report and show enhanced features (like single users' histories) an approach
  could be the following:

  - the report page listen to a `unlock=<token>` URL param
  - if such a param is found, a call to skillmaster is made
  - skillmaster compares the token against an environment variable UNLOCK_TOKEN
  - if the provided token match the env variable, a "redhat" role is added to the JWT roles claim
  - this new role can be used in hasura to allow a wider permission control to those selected users
  - the token (unique for everyone) will be appended to the URL passed to eloqua so that everyone visiting from that URL will have his user automatically enhanced
*/
}

const commonRadarOptions = {
  scales: {
    r: {
      pointLabels: {
        callback(label) {
          return splitLongWords(label);
        },
        font: {
          size: 12,
        },
      },
      min: 0,
      max: 100,
      ticks: {
        stepSize: 20,
      },
    },
  },
};

const TeamReport: React.FunctionComponent = () => {
  const { id } = useParams();
  const { user } = UserStore((state) => ({
    user: state.current,
  }));

  const localization = LocalizationStore();
  const { t } = localization.useTranslation();

  const navigate = useNavigate();
  const [teamStatistics, setTeamStatistics] = useState<any>({
    performanceEntries: [],
    overallScore: 0,
    suggestions: [],
    outcomeConvergence: 0,
    responseRate: 0,
    allUsersData: [],
  });
  const [selectionStatistics, setSelectionStatistics] = useState<any>({
    performanceEntries: [],
    overallScore: 0,
    suggestions: [],
    outcomeConvergence: 0,
    responseRate: 0,
    allUsersData: [],
  });
  const [campaign, setCampaign] = useState<ICampaignModel | null>(null);
  const [selectedReports, setSelectedReports] = useState<string[]>([]);
  const [performanceEntriesAverage, setPerformanceEntriesAverage] = useState<any>({});
  const [strongSkills, setStrongSkills] = useState<any>([]);
  const [skillGaps, setSkillGaps] = useState<any>([]);

  const getTeamReport = async () => {
    await graphql
      .request(GET_TEAM_REPORT, {
        campaign_id: id,
      })
      .then((data: any) => {
        if (data['skill_campaigns_by_pk']) {
          setTeamStatistics(parseTeamReport(data.skill_campaigns_by_pk, []));

          setCampaign(data.skill_campaigns_by_pk);
        } else {
          navigate('/en/not-found');
        }
      })
      .catch((error: any) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    getTeamReport()
  }, []);

  useEffect(() => {
    setPerformanceEntriesAverage(
      Object.assign(
        {},
        ...teamStatistics.performanceEntries.map((e) => ({ [e.name]: e.value }))
      )
    );

    let gaps: any[] = [];
    let strongest: any[] = [];

    if (selectionStatistics.performanceEntries.length == 1) {
      const unit = selectionStatistics.performanceEntries[0];

      if (unit.value < SKILL_GAP_THRESHOLD) {
        gaps = [unit];
        strongest = [];
      } else {
        gaps = [];
        strongest = [unit];
      }
    } else {
      gaps = selectionStatistics.performanceEntries.filter(
        (u) => u.value < SKILL_GAP_THRESHOLD
      );
      strongest = selectionStatistics.performanceEntries.filter(
        (u) => u.value >= SKILL_GAP_THRESHOLD
      );
    }

    setStrongSkills(strongest);
    setSkillGaps(gaps);
  }, [selectionStatistics.performanceEntries]);

  useEffect(() => {
    if (campaign) {
      setSelectionStatistics(parseTeamReport(campaign, selectedReports));
    }
  }, [campaign, selectedReports]);

  const notEnoughData = () => {
    return <p className="not-enough-data">{t('No data to show')}</p>;
  };

  const renderRadarGraph = (units): React.ReactNode => {
    if (selectionStatistics.performanceEntries.length < 3) {
      return <div>Not enough data</div>;
    }

    return (
      <Radar
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          ...commonRadarOptions,
        }}
        data={{
          labels: selectionStatistics.performanceEntries.map((a) => a.name),
          datasets: [
            {
              label: 'Proficiency',
              data: selectionStatistics.performanceEntries.map((a) => a.value),
              fill: true,
              backgroundColor: 'rgba(115, 188, 247, 0.5)',
              borderColor: '#0066cc',
              pointBackgroundColor: '#004080',
              pointBorderColor: '#fff',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: '#0066cc',
            },
          ],
        }}
      />
    );
  };

  const executeMutation = async (
    mutation: RequestDocument,
    variables: Record<string, unknown>
  ) => {
    try {
      const data: any = await graphql.request(mutation, variables);
      return data;
    } catch (error) {
      throw new Error(
        'Error while processing the request on the server, please try again'
      );
    }
  };

  const handleSharedEmailUpdate = async (
    value: string | null,
    fieldName: string
  ): Promise<updateSharedEmailsMutationResponse> => {
    return await executeMutation(
      DELETE_MUTATION.mutation(fieldName),
      DELETE_MUTATION.variables(id, value)
    );
  };

  const handleDelete = async (
    emailsForUpdate: sharedEmailList
  ): Promise<deleteSharedEmailsMutationResponse> => {
    return await executeMutation(
      UPDATE_MUTATION.mutation,
      UPDATE_MUTATION.variables(emailsForUpdate, id)
    );
  };

  return !teamStatistics || !campaign ? (
    <PageSection
      isWidthLimited={true}
      isCenterAligned={true}
      className="loader lg"
    >
      <Loader />
    </PageSection>
  ) : (
    <PageSection
      isWidthLimited={true}
      isCenterAligned={true}
      className="team-report"
    >
      <Grid hasGutter={true}>
        <GridItem span={6}>
          <Button
            variant="tertiary"
            className="dashboard-button"
            onClick={() => {
              navigate(`/${localization.currentLanguageCode}/dashboard`);
            }}
          >
            {t('My dashboard')}
          </Button>
        </GridItem>
        <GridItem span={6}>
          <p className="legal-notice">
            {t(
              'Note: Before sharing individuals\' team skills assessment results, please ensure that you are authorized to act in this capacity and that the individuals have been made aware that their information is being shared with others.'
            )}
          </p>
        </GridItem>
      </Grid>

      {selectedReports.length > 0 && (
        <Alert
          variant="warning"
          isInline
          title={t('This is a filtered view')}
          className="fixed-alert"
          actionLinks={
            <React.Fragment>
              <AlertActionLink
                onClick={() => {
                  document.getElementById('metrics-anchor')?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  });
                }}
              >{`${t('Go to metrics')}`}</AlertActionLink>
            </React.Fragment>
          }
        >
          {selectedReports.length === 1 ? (
            <p>{t('You\'re seeing a single report')}</p>
          ) : (
            <p>
              {t(
                `You're seeing aggregated data from ${selectedReports.length} reports`
              )}
            </p>
          )}
        </Alert>
      )}

      <section className="report-section">
        {campaign && (
          <DashboardEntry
            campaign={campaign}
            onlyExtendButton={true}
            onExpirationUpdated={getTeamReport}
          />
        )}
      </section>

      {campaign.reports.length > 0 && (
        <section
          className="report-section"
          id="metrics-anchor"
        >
          <Grid hasGutter={true}>
            {/* left column */}
            <GridItem
              sm={12}
              md={12}
              xl={4}
            >
              <Stack hasGutter={true}>
                {/* knowledge map */}
                <StackItem>
                  <SectionHeader
                    title={t('Knowledge Map')}
                    description={t('All scores at a glance')}
                  />
                  {renderRadarGraph(
                    selectionStatistics.performanceEntries.slice(0, 10)
                  )}
                </StackItem>
                <StackItem>
                  <Divider className="large" />
                </StackItem>
                {/* assessment metrics */}
                <StackItem>
                  <SectionHeader
                    title={t('Assessment metrics')}
                    description={t(
                      'Additional data on this participants\' performance'
                    )}
                  />
                  {selectionStatistics.performanceEntries.length > 0 ? (
                    <Stack hasGutter={true}>
                      <StackItem>
                        <PerformanceCard
                          name={t('Overall Score')}
                          value={selectionStatistics.overallScore}
                          average={
                            selectedReports.length
                              ? teamStatistics.overallScore
                              : null
                          }
                        />
                      </StackItem>
                      <StackItem>
                        <ConfidenceCard
                          name={t('Convergence')}
                          value={selectionStatistics.outcomeConvergence}
                          average={
                            selectedReports.length
                              ? teamStatistics.outcomeConvergence
                              : null
                          }
                        />
                      </StackItem>
                      <StackItem>
                        <Grid hasGutter={true}>
                          <GridItem span={6}>
                            <TextCard
                              name={t('Completion time')}
                              value={selectionStatistics.avgCompletionTime}
                            />
                          </GridItem>
                          <GridItem span={6}>
                            <TextCard
                              name={t('Response rate')}
                              value={`${selectionStatistics.responseRate}%`}
                            />
                          </GridItem>
                        </Grid>
                      </StackItem>
                    </Stack>
                  ) : (
                    notEnoughData()
                  )}
                </StackItem>

                {/* Export */}
                {user.uuid === campaign.owner_id && (
                  <React.Fragment>
                    <StackItem>
                      <SectionHeader
                        title={t('Export data')}
                        description={t(
                          'Download a file containing all the metrics regarding these participants to be used in your preferred software'
                        )}
                      />
                      <Button
                        variant="primary"
                        isBlock
                        isLarge
                        onClick={() => {
                          downloadReportZip(
                            selectionStatistics.performanceEntries,
                            selectionStatistics.overallScore,
                            selectionStatistics.outcomeConvergence,
                            selectionStatistics.avgCompletionTime,
                            selectionStatistics.responseRate,
                            selectionStatistics.suggestions,
                            selectionStatistics.allUsersData,
                            localization.currentLanguageCode
                          );
                        }}
                      >
                        {selectedReports.length > 0
                          ? t('Download filtered report')
                          : t('Download full report')}
                      </Button>
                    </StackItem>
                  </React.Fragment>
                )}
              </Stack>
            </GridItem>
            {/* center space */}
            <GridItem
              sm={undefined}
              md={undefined}
              xl={1}
            ></GridItem>
            {/* right column */}
            <GridItem
              sm={12}
              md={12}
              xl={7}
            >
              <Stack hasGutter={true}>
                {/* skill gaps */}
                <StackItem>
                  <SectionHeader
                    title={t('Skill Gaps')}
                    description={t('Low scoring topics needing attention')}
                  />
                  {skillGaps.length > 0 ? (
                    <Stack hasGutter={true}>
                      {skillGaps.slice(0, 3).map((unit) => {
                        return (
                          <StackItem key={unit.name}>
                            <PerformanceCard
                              average={
                                selectedReports.length
                                  ? performanceEntriesAverage[unit.name]
                                  : null
                              }
                              {...unit}
                            />
                          </StackItem>
                        );
                      })}
                    </Stack>
                  ) : (
                    notEnoughData()
                  )}
                </StackItem>
                <StackItem>
                  <Divider className="large" />
                </StackItem>
                {/* strong skills */}
                <StackItem>
                  <SectionHeader
                    title={t('Strong Skills')}
                    description={t(
                      'High scoring topics demonstrating proficiency'
                    )}
                  />
                  {strongSkills.length > 0 ? (
                    <Stack hasGutter={true}>
                      {strongSkills.slice(0, 3).map((unit) => {
                        return (
                          <StackItem key={unit.name}>
                            <PerformanceCard
                              average={
                                selectedReports.length
                                  ? performanceEntriesAverage[unit.name]
                                  : null
                              }
                              {...unit}
                            />
                          </StackItem>
                        );
                      })}
                    </Stack>
                  ) : (
                    notEnoughData()
                  )}
                </StackItem>
                <StackItem>
                  <Divider className="large" />
                </StackItem>
                {/* top recommendations */}
                <StackItem>
                  <SectionHeader
                    title={t('Top recommendations')}
                    description={t(
                      'Start this participants\' upskilling with the following offerings'
                    )}
                  />

                  <Stack hasGutter={true}>
                    <StackItem>
                      <p>
                        {t(
                          'Suggestions are ordered by their upskilling ability to the whole team; the first suggestion is the one beneficial to the most people'
                        )}
                      </p>
                    </StackItem>
                    <StackItem>
                      {selectionStatistics.suggestions.length > 0 ? (
                        <Card>
                          <CardBody>
                            <List
                              isPlain
                              isBordered
                              className="suggestions-list"
                            >
                              {selectionStatistics.suggestions.map(
                                (offering, i) => (
                                  <ListItem key={i}>
                                    <Split hasGutter={true}>
                                      <SplitItem className="index-element">
                                        {i + 1}
                                      </SplitItem>
                                      <SplitItem isFilled>
                                        <strong>
                                          {t(offering.label)}
                                        </strong>{' '}
                                        - {t(offering.name)}
                                      </SplitItem>
                                      {offering.href && offering.action && (
                                        <SplitItem>
                                          <a
                                            href={offering.href}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {t(offering.action)}
                                          </a>
                                        </SplitItem>
                                      )}
                                    </Split>
                                  </ListItem>
                                )
                              )}
                            </List>
                          </CardBody>
                        </Card>
                      ) : (
                        notEnoughData()
                      )}
                    </StackItem>
                  </Stack>
                </StackItem>
              </Stack>
            </GridItem>
          </Grid>
        </section>
      )}

      <Divider className="large" />

      <section className="report-section">
        <UsersTable
          userData={teamStatistics.allUsersData}
          selected={selectedReports}
          campaign={campaign}
          onSelectionChange={(selection) => {
            setSelectedReports(selection);
          }}
          setTeamStatistics={setTeamStatistics}
        />
      </section>

      {user.uuid === campaign.owner_id && (
        <>
          <Divider className="large" />

          <section className="report-section">
            <ShareTable
              data={campaign}
              handleSharedEmailUpdate={handleSharedEmailUpdate}
              handleDelete={handleDelete}
            />
          </section>
        </>
      )}
    </PageSection>
  );
};

export default TeamReport;
