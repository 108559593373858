import React from 'react';
import { Button, Toolbar, ToolbarContent, ToolbarItem } from '@patternfly/react-core';
import './PageControls.scss';

interface IPageControls {
  onRight?: () => void,
  onLeft?: () => void,
  rightLabel?: string,
  leftLabel?: string,
  isRightDisabled?: boolean,
  isLeftDisabled?: boolean,
  isRightLoading?: boolean,
  isLeftLoading?: boolean,
}

export const PageControls: React.FC<IPageControls> = ({
  onRight,
  onLeft,
  rightLabel='',
  leftLabel='',
  isRightDisabled,
  isLeftDisabled,
  isRightLoading,
  isLeftLoading,
}): React.ReactElement => {
  return (
    <Toolbar>
      <ToolbarContent>
        {onLeft && <ToolbarItem alignment={{ default: 'alignLeft' }}>
          <Button
            variant="tertiary"
            iconPosition="right"
            onClick={onLeft}
            isDisabled={isLeftDisabled}
            isLoading={isLeftLoading}
          >
            {leftLabel}
          </Button>
        </ToolbarItem>}
        {onRight && <ToolbarItem alignment={{ default: 'alignRight' }}>
          <Button
            variant="primary"
            iconPosition="right"
            onClick={onRight}
            isDisabled={isRightDisabled}
            isLoading={isRightLoading}
          >
            {rightLabel}
          </Button>
        </ToolbarItem>}
      </ToolbarContent>
    </Toolbar>
  )
};

export default PageControls;
