// LEGACY, TO BE REMOVED
import { Instance, types } from 'mobx-state-tree';
import { createContext, useContext } from 'react';

import { AssessmentTrackerStore } from '@app/state/AssessmentTrackerStore';
import { CatalogStore } from '@app/state/CatalogStore';

// every part of our model goes in here
export const RootStore = types.model('RootStore', {
  catalog: CatalogStore,
  tracker: AssessmentTrackerStore,
});
export type IRootStore = Instance<typeof RootStore>;

const initialState = RootStore.create({
  catalog: {
    assessments: [],
    ready: false,
  },
  tracker: {
    shownAssessment: null,
    shownNode: null,
    listenersStack: [],
    userScore: {},
    completed: false,
    startedAt: 0,
    userUpdatedCompany: false,
    debug: [],
  },
});

export const rootStore = initialState;
export type RootInstance = Instance<typeof RootStore>;
const RootStoreContext = createContext<null | RootInstance>(initialState);

export const Provider = RootStoreContext.Provider;
export const useMst = (): RootInstance => {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store;
}
