import React, { useEffect } from 'react';
import * as SentryReact from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';

const Sentry: React.FC<{
  publicKey: string;
  user: any;
  environment: string;
  isUserReady: boolean;
}> = ({ publicKey, user, environment, isUserReady }): null => {
  useEffect(() => {
    if (publicKey && environment) {
      if (environment === 'local') {
        console.info('Disabling sentry on local deployment');
        return;
      }

      SentryReact.init({
        release: process.env.npm_package_version,
        dsn: publicKey,
        integrations: [
          new BrowserTracing(),
          new CaptureConsole({
            levels: ['error'],
          }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: environment === 'production' ? 0.001 : 1.0,
        environment: environment,
        // allowUrls or denyUrls to prevent monotoring specific services
        denyUrls: [/extensions\//i, /^chrome:\/\//i],
        ignoreErrors: [
          'Warning: validateDOMNesting',
          'Failed to construct \'CustomElement\'',
          /.*(http:|https:).* 4\d\d$/, // ignore errors that contain http(s) and end with 4xx
        ],
      });
    }
  }, [publicKey, environment]);

  useEffect(() => {
    if (isUserReady) {
      SentryReact.setUser({
        email: user.email,
        id: user.uuid,
        username: user.username,
        role: user.role,
      });
    }
  }, [isUserReady]);

  return null;
};

export default Sentry;
