import React, { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import '@patternfly/react-core/dist/styles/base.css';
import { Page, PageSection } from '@patternfly/react-core';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import { Header } from '@app/components/Header/Header';
import { Footer } from '@app/components/Footer/Footer';

import '@cpelements/pfe-navigation/dist/pfe-navigation--lightdom.css';
import '@rhds/tokens/css/global.css';
import '@app/app.scss';
import { Loader } from '@app/components/Loader/Loader';

import { availableLanguages, SENTRY_PUBLIC_KEY } from './constants';

import { CookieConsent } from '@app/components/CookieConsent/CookieConsent';
import Sentry from './components/Sentry/Sentry';

import { useRoadmapStore } from '@app/state/RoadmapStore';
import { LocalizationStore } from '@app/state/LocalizationStore';
import { PartnerStore } from '@app/state/PartnerStore';
import { UserStore } from '@app/state/UserStore';
import { SystemStore } from '@app/state/SystemStore';

const BaseLayout: React.FunctionComponent = () => {
  // capture partner id
  const [searchParams] = useSearchParams();
  // handle internationalization
  const navigate = useNavigate();
  const { lang } = useParams();
  // scroll to top on route transition
  const { pathname } = useLocation();

  const {
    loadRoadmap,
    isRoadmapReady
  } = useRoadmapStore((state) => ({
    loadRoadmap: state.load,
    isRoadmapReady: state.ready
  }));

  const { handle_sso_redirect, user, isUserReady } = UserStore((state) => ({
    handle_sso_redirect: state.handle_sso_redirect,
    user: state.current,
    isUserReady: state.ready,
  }));

  const localization = LocalizationStore();
  const system = SystemStore();
  const partner = PartnerStore();

  useEffect(() => {
    handle_sso_redirect();

    loadRoadmap();
  }, []);

  // app initialization code goes here
  useEffect(() => {
    // UI localization
    localization.loadTranslations("ui");
    localization.loadTranslations("collateral");
  }, [localization.currentLanguageCode]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // set language from url and handle partner flag
  useEffect(() => {
    const paramPartner = searchParams.get('partner');

    if (paramPartner) {
      partner.getPartnerInfo(paramPartner);
    } else {
      partner.setNoPartner();
    }

    if (lang) {
      if (Object.keys(availableLanguages).includes(lang)) {
        localization.setCurrentLanguageCode(lang);
      } else {
        navigate('/en');
      }
    }
  }, [lang]);

  return (
    <React.Fragment>
      {
        (
          localization.currentLanguageCode === 'en' || 
            (localization.currentLanguageCode !== 'en'
              && localization.loadedNamespaces.includes("ui")
              && localization.loadedNamespaces.includes("collateral")
            )
          ) 
            && isRoadmapReady
            && partner.ready ? (
        <Page>
          <PageSection
            isWidthLimited={true}
            isCenterAligned={true}
            padding={{ default: 'noPadding' }}
            className="header"
          >
            <Header />
          </PageSection>
          <Outlet />
          <CookieConsent />
          <Sentry
            publicKey={SENTRY_PUBLIC_KEY}
            user={user}
            environment={system.environment}
            isUserReady={isUserReady}
          />
          <PageSection
            className="footer"
            isWidthLimited={true}
            isCenterAligned={true}
            padding={{ default: 'noPadding' }}
          >
            <Footer />
          </PageSection>
        </Page>
      ) : (
        <PageSection
          isWidthLimited={true}
          isCenterAligned={true}
          className="loader lg dark"
        >
          <Loader />
        </PageSection>
      )}
    </React.Fragment>
  );
};

export default BaseLayout;
