import React from 'react';
import ReactDOM from 'react-dom/client';

import '@patternfly/pfe-avatar';
import '@patternfly/pfe-cta';
import '@patternfly/pfe-icon';
import '@cpelements/pfe-navigation';

import App from '@app/app';

/*
const isProduction = process.env.DEPLOYMENT === 'production';
if (!isProduction) {
  const config = {
    rules: [
      {
        id: 'color-contrast',
        enabled: false,
      },
    ],
  };
  // eslint-disable-next-line @typescript-eslint/no-var-requires, no-undef
  const axe = require('react-axe');
  axe(React, ReactDOM, 1000, config);
}
*/

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<App />);
