import React from 'react';
import { Grid, GridItem } from '@patternfly/react-core';
import PageHeader from '../PageHeader/PageHeader';
import './PageHero.scss';
import { LocalizationStore } from '@app/state/LocalizationStore';

interface IPageHero {
  title: string;
  image?: string;
}

export const PageHero: React.FC<IPageHero> = ({
  title,
  image,
}): React.ReactElement => {
  const localization = LocalizationStore();
  const { t } = localization.useTranslation();

  return (
    <Grid hasGutter={true}>
      <GridItem
        sm={12}
        md={12}
        xl={8}
      >
        <PageHeader
          isDark={true}
          title={title}
          suptitle={t('SKILL ASSESSMENT')}
          span={12}
        />
      </GridItem>
      <GridItem
        xl={4}
        style={{ backgroundImage: image ? `url(${image})` : 'none' }}
        className="image-container"
      />
    </Grid>
  );
};

export default PageHero;
