// LEGACY CODE, TO BE REMOVED WHEN APOLLO CODE GOES AWAY
import axios from 'axios';
import Cookies from 'js-cookie';
import { CSRF_ACCESS_TOKEN } from '@src/app/constants';

const client = axios.create({});

// request interceptor
client.interceptors.request.use(
  (config) => {
    const csrf_token = Cookies.get(CSRF_ACCESS_TOKEN);
    if (csrf_token) {
      // @ts-ignore
      config.headers['x-csrf-authorization'] = csrf_token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptor
/*
axios.interceptors.response.use(response => {
  return response;
}, error => {
 if (error.response.status === 401) {
  // add handler
 }
 return error;
});
*/

export const web = client;