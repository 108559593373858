import React from 'react';
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react';
import Swiper, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/components/navigation/navigation.min.css';
import './AssessmentCarousel.css';
import AssessmentTile from '@app/components/AssessmentTile/AssessmentTile';
import { IBlueprint } from '@app/types';

Swiper.use([Navigation]);

interface CarouselProps {
  blueprints: Array<IBlueprint>,
  slidesPerView?: number,
  initialSlide?: number,
}

export const AssessmentCarousel: React.FC<CarouselProps> = ({ blueprints, slidesPerView = 3.5, initialSlide = 0 }) => {
  return (
    <ReactSwiper
      spaceBetween={14}
      grabCursor
      speed={650}
      navigation
      initialSlide={initialSlide}
      watchSlidesVisibility
      breakpoints={{
        375: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 1.5,
        },
        1024: {
          slidesPerView: 2.5,
        },
        1440: {
          slidesPerView: slidesPerView,
        },
      }}
    >
      {blueprints.map((blueprint, idx) => (
        <SwiperSlide key={idx}>
          <AssessmentTile blueprint={blueprint} />
        </SwiperSlide>
      ))}
    </ReactSwiper>
  );
};