export const availableLanguages = {
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
  it: 'Italiano',
  es: 'Español',
  pt: 'Português',
  ja: '日本語',
  ko: '한국어',
  zh: '简体中文',
};

export const SENTRY_PUBLIC_KEY =
  'https://36aee3748a41414ab0bab361c944afa2@o490301.ingest.sentry.io/4504883283492864';

export const KEEP_ALIVE_POLL_INTERVAL = 180000; // in ms
export const JWT_AUTOMATIC_REFRESH_DELTA = 3; // must match the one in skillmaster
export const sso_local_key = 'sso_redirect';
export const timestamp_before_sso_key = 'timestamp_before_sso';
export const REDIRECT_TIMESTAMP_LIMIT = 5;
export const CSRF_ACCESS_TOKEN = 'csrf_access_token';

export const ENVIRONMENTS_MAP = {
  localhost: 'local',
  '127.0.0.1': 'local',
  'sa-devel-e6a8392dcf81.ole.redhat.com': 'develop',
  'sa-stage.ole.redhat.com': 'stage',
  'skills.ole.redhat.com': 'production',
};
