import React, { useEffect, useState } from 'react';
import { Alert, AlertActionLink } from '@patternfly/react-core';
import './CookieConsent.scss';
import { LocalizationStore } from '@app/state/LocalizationStore';

const CookieConsent: React.FC = () => {
  const localization = LocalizationStore();
  const { t } = localization.useTranslation();

  const [isCookieConsentOpen, setIsCookieConsentOpen] = useState(false);

  const onCookieConsentDismissed = () => {
    localStorage.setItem('privacy-dismiss', 'true');
    setIsCookieConsentOpen(false);
  };

  useEffect(() => {
    const dismissed = localStorage.getItem('privacy-dismiss');

    if(!dismissed){
      setIsCookieConsentOpen(true);
    }
  }, []);

  return (
    <React.Fragment>
      {isCookieConsentOpen && (
        <Alert
          variant="info"
          title={t('How we use cookies')}
          isInline
          className="cookie-consent-alert"
          data-dismiss="alert"
          aria-label={t('Close')}
          actionLinks={
            <React.Fragment>
              <AlertActionLink onClick={onCookieConsentDismissed}>
                {t('Dismiss')}
              </AlertActionLink>
            </React.Fragment>
          }
        >
          { t('We use cookies on our websites to deliver our online services.') }
          <br/>
          <br/>
          { t('Details about how we use cookies and how you may disable them are set out in our')} <a href="https://www.redhat.com/en/about/privacy-policy#cookies" target="_blank" rel="noreferrer">{t('Privacy Statement')}</a>.
          <br/>
          <br/>

          {t('By using this website you agree to our use of cookies.')}
        </Alert>
      )}
    </React.Fragment>
  );
};

export { CookieConsent };
