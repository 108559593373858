import { create } from 'zustand';
import { gql, graphql, rest } from '@app/services/http';
import { IBlueprint, Waypoints } from '@app/types';

const GET_LATEST_ROADMAP = gql`
  query GetLatestRoadmap {
    skill_roadmaps(limit: 1, order_by: { created: desc }) {
      id
      blueprints
      waypoints
    }
  }
`;

export interface IRoadmapStore {
  ready: boolean;
  id: string | null;
  blueprints: IBlueprint[];
  waypoints: Waypoints;
  get_blueprint_by_sku: (sku: string) => IBlueprint;
  load: () => void;
  generate_new_assessment: (
    roadmapId: string | null,
    targets: string[]
  ) => Promise<string>;
}

export const useRoadmapStore = create<IRoadmapStore>()((set, get) => ({
  ready: false,
  id: null,
  blueprints: [],
  waypoints: {
    ask: 'Which product are you interested in?',
    options: [],
    type: 'question'
  },
  get_blueprint_by_sku(sku) {
    const t: IBlueprint | undefined = get().blueprints.find(
      (a) => a.sku === sku
    );

    if (t === undefined) {
      throw Error(`No blueprint matching SKU ${sku}`);
    }

    return t;
  },
  load: async () => {
    const res: any = await graphql.request(GET_LATEST_ROADMAP);

    if (!res.skill_roadmaps.length) {
      throw Error('No roadmap available');
    }

    const rm = res.skill_roadmaps[0];

    set({
      id: rm.id,
      blueprints: rm.blueprints,
      waypoints: rm.waypoints,
      ready: true,
    });
  },
  generate_new_assessment: async (roadmapId, targets) => {
    try {
      if (!roadmapId) throw Error('No roadmap ID provided');

      const res: any = await rest
        .post('/skillmaster/authoring/assessments/generate', {
          json: {
            roadmap: roadmapId,
            targets: targets,
          },
        })
        .json();

      if (!res['result'][0]['id']) throw Error('No assessment found');

      return res['result'][0]['id'];
    } catch (error: any) {
      throw Error(error);
    }
  },
}));
