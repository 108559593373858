import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Banner, PageSection } from '@patternfly/react-core';
import SingleChoiceQuestion from '@app/components/PresenterPanes/SingleChoiceQuestion';
import UpdateCompany from '@app/components/PresenterPanes/UpdateCompany';
import './Presenter.scss';
import { PageHero } from '@app/components/PageHero/PageHero';
import { Loader } from '@app/components/Loader/Loader';
import { IOffering, ISheet, useProctorStore } from '@app/state/ProctorStore';
import { gql, graphql } from '@app/services/http';
import { LocalizationStore } from '@app/state/LocalizationStore';
import { PartnerStore } from '@app/state/PartnerStore';
import { UserStore } from '@app/state/UserStore';

const GET_INVITATION = gql`
  query GetInvitation($invitation_id: uuid!) {
    skill_invitations_by_pk(id: $invitation_id) {
      id
      campaign_id
      metadata
      partner
    }
  }
`;

const GET_SHEET = gql`
  query GetSheet($id: uuid!) {
    skill_sheets_by_pk(id: $id) {
      id
      metadata
      title
      body
      path
    }
    skill_offerings {
      summary
      name
      label
      id
      href
      action
    }
  }
`;

const CREATE_REPORT = gql`
  mutation CreateReport(
    $campaign: uuid
    $assessment_name: String!
    $assessment_id: String!
    $rhStartOfferId: String!
    $rhFinishOfferId: String!
    $partnerStartOfferId: String!
    $partnerFinishOfferId: String!
    $user_fullname: String!
    $user_email: String!
    $deputy_name: String
    $score: json!
    $suggestions: json!
    $duration: Number!
    $partner: String
  ) {
    insert_skill_reports_one(
      object: {
        campaign_id: $campaign
        metadata: {
          assessment: {
            name: $assessment_name
            id: $assessment_id
            rhStartOfferId: $rhStartOfferId
            rhFinishOfferId: $rhFinishOfferId
            partnerStartOfferId: $partnerStartOfferId
            partnerFinishOfferId: $partnerFinishOfferId
          }
          deputy: { fullname: $deputy_name }
          user: { fullname: $user_fullname, email: $user_email }
        }
        score: $score
        suggestions: $suggestions
        statistics: { duration: $duration }
        partner: $partner
      }
    ) {
      id
    }
  }
`;

const Presenter: React.FunctionComponent = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user, isUserReady } = UserStore((state) => ({
    user: state.current,
    isUserReady: state.ready,
  }));
  const localization = LocalizationStore();
  const { t } = localization.useTranslation();
  const partner = PartnerStore();

  const [loading, setLoading] = useState(true);
  const [currentInvitation, setCurrentInvitation] = useState<any>({});

  const {
    currentSheet,
    completed,
    chronoStartedAt,
    setSheet,
    setOfferings,
    getFinalScore,
    score,
    suggestions,
    resetSheet,
    hasUpdatedCompanyName
  } = useProctorStore((state) => ({
    currentSheet: state.currentSheet,
    completed: state.completed,
    chronoStartedAt: state.chronoStartedAt,
    setSheet: state.setSheet,
    setOfferings: state.setOfferings,
    getFinalScore: state.getFinalScore,
    score: state.score,
    suggestions: state.suggestions,
    resetSheet: state.resetSheet,
    hasUpdatedCompanyName: state.hasUpdatedCompanyName
  }));

  const verifyInvitation = async () => {
    const invitation = searchParams.get('invitation');

    if (invitation) {
      await graphql
        .request(GET_INVITATION, {
          invitation_id: invitation,
        })
        .then((data: any) => {
          const invitation = data.insert_skill_reports_one.id
          if (invitation) {
            setCurrentInvitation(invitation);
          }
        });
    }
  };

  const createReport = async () => {
    if(currentSheet && completed){
      setLoading(true);

      await graphql
        .request(CREATE_REPORT, {
          campaign: currentInvitation.id
            ? currentInvitation.campaign_id
            : null,
          assessment_name: currentSheet.title,
          assessment_id: currentSheet.id,
          rhStartOfferId: currentSheet.metadata.rhStartOfferId,
          rhFinishOfferId: currentSheet.metadata.rhFinishOfferId,
          partnerStartOfferId: currentSheet.metadata.partnerStartOfferId,
          partnerFinishOfferId: currentSheet.metadata.partnerFinishOfferId,
          user_fullname: user.fullname,
          user_email: user.email,
          deputy_name: currentInvitation.id
            ? currentInvitation.metadata.deputy.fullname
            : '',
          score: getFinalScore(),
          suggestions: suggestions,
          duration:
            new Date().getTime() - (chronoStartedAt ? chronoStartedAt : 0),
          partner: currentInvitation.partner
            ? currentInvitation.partner
            : partner.shortname
              ? partner.shortname
              : null,
        })
        .then((data: any) => {
          resetSheet();
          navigate(`/${localization.currentLanguageCode}/results/${data.insert_skill_reports_one.id}`);
        });
    }
  }

  const get_sheet = async () => {
    const res: any = await graphql.request(GET_SHEET, {
      id: id,
    });

    const sheet: ISheet = res['skill_sheets_by_pk'];
    const offerings: IOffering[] = res['skill_offerings'];

    if (!sheet) {
      navigate('/not-found');
    } else {
      setSheet(sheet);
      setOfferings(offerings);
      setLoading(false);
    }
  };

  useEffect(() => {
    verifyInvitation()
  }, [searchParams]);

  useEffect(() => {
    get_sheet();
  }, [id]);

  useEffect(() => {
    if(currentSheet){
      // load translations
      currentSheet.path.forEach((offering: string) => {
        // TODO: download chapter-by-chapter to lower latency?
        localization.loadTranslations(`content/${offering}/%`);
      });
    }
  }, [currentSheet]);

  useEffect(() => {
    if(currentSheet && completed){
      createReport();

      // unload translations
      currentSheet.path.forEach((offering: string) => {
        localization.unloadTranslations(offering)
      });
    }
  }, [completed]);

  return (
    !loading
    && currentSheet
    && isUserReady
    && (
      localization.currentLanguageCode === 'en'
      || (
        localization.currentLanguageCode !== 'en'
          && currentSheet.path.every(a => localization.loadedNamespaces.includes(`content/${a}/%`))
      )
    )
  ) ? (
    <React.Fragment>
      <PageSection
        isWidthLimited={true}
        isCenterAligned={true}
        className="page-hero"
      >
        <PageHero title={t(currentSheet.title)} />
      </PageSection>

      {currentInvitation.id && (
        <Banner
          variant="info"
          className="campaign-banner"
        >
          <PageSection
            isWidthLimited={true}
            isCenterAligned={true}
          >
            {t(
              'This is part of a team skills assessment and your results will be shared with'
            ) +
              ' ' +
              currentInvitation.metadata.deputy.fullname +
              '.'}
          </PageSection>
        </Banner>
      )}

      {!user.corporate && !hasUpdatedCompanyName ? (
        <UpdateCompany />
      ) : (
        <SingleChoiceQuestion />
      )}
    </React.Fragment>
  ) : (
    <PageSection
      isWidthLimited={true}
      isCenterAligned={true}
      className="loader lg"
    >
      <Loader />
    </PageSection>
  );
};

export default Presenter;
