import { create } from 'zustand';
import { ENVIRONMENTS_MAP } from '@src/app/constants';

type Environment = 'local' | 'develop' | 'qa' | 'stage' | 'production';

interface SystemStoreInterface {
  environment: Environment;
  isLocal: () => boolean;
  isQA: () => boolean;
  isStage: () => boolean;
  isProduction: () => boolean;
}

export const SystemStore = create<SystemStoreInterface>()((set, get) => ({
  environment: ENVIRONMENTS_MAP[window.location.hostname]
    ? ENVIRONMENTS_MAP[window.location.hostname]
    : 'develop',
  isLocal: () => get().environment === 'local',
  isQA: () => get().environment === 'qa',
  isStage: () => get().environment === 'stage',
  isProduction: () => get().environment === 'production',
}));
