import {
  BlobWriter,
  ZipWriter,
} from '@zip.js/zip.js';

export const downloadFile = (filename, blob) => {
  const elem = window.document.createElement('a');
  elem.href = window.URL.createObjectURL(blob);
  elem.download = filename;
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
}

export const downloadZip = async (filename, content) => {
  // generate and download file
  //if(window.navigator.msSaveOrOpenBlob) {
  //    window.navigator.msSaveBlob(blob, filename);
  //}
  //else{
  const zipFileWriter = new BlobWriter();
  const zipWriter = new ZipWriter(zipFileWriter);

  for (const entry of content) {
    await zipWriter.add(entry.filename, entry.data.stream());
  }

  const zipBlob = await zipWriter.close();

  downloadFile(filename, zipBlob);
  //}
}