// LEGACY, TO BE REMOVED
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import { web as axiosInstance } from './axios';
import { buildAxiosFetch } from '@lifeomic/axios-fetch';

import Cookies from 'js-cookie';
import { onError } from '@apollo/client/link/error';
import { CSRF_ACCESS_TOKEN } from '@src/app/constants';

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      'x-csrf-authorization': Cookies.get(CSRF_ACCESS_TOKEN),
      ...headers,
    },
  };
});

const httpLink = createHttpLink({
  uri: '/skillmaster/hasura/v1/graphql',
  fetch: buildAxiosFetch(axiosInstance),
});

const retryLink = new RetryLink({
  delay: {
    initial: 250,
    max: 250,
    jitter: false,
  },
  attempts: {
    max: 3,
    //retryIf: (error, _operation) => !!error
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

export const client = new ApolloClient({
  link: errorLink.concat(authLink).concat(retryLink).concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
    typePolicies: {
      Query: {
        fields: {
          CatalogQuery: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  }),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
    watchQuery: { fetchPolicy: 'no-cache' },
  },
});
