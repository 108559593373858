import React from 'react';
import {
  Button,
  Card,
  CardBody,
  Stack,
  StackItem,
  Text,
} from '@patternfly/react-core';
import './AssessmentTile.scss';
import { ArrowRightIcon } from '@patternfly/react-icons';
import { useNavigate } from 'react-router-dom';
import { LocalizationStore } from '@app/state/LocalizationStore';
import { IBlueprint } from '@app/types';

interface IAssessmentTileProps {
  blueprint: IBlueprint | any;
}

const AssessmentTile: React.FC<IAssessmentTileProps> = ({ blueprint }) => {
  const navigate = useNavigate();
  const localization = LocalizationStore();
  const { t } = localization.useTranslation();

  return (
    <Card className="container">
      <CardBody className='body'>
        <Stack>
          <StackItem>
            <Text className="product">
              {blueprint.tagline
                ? t(blueprint.tagline)
                : t('NO PRODUCT')}
            </Text>
          </StackItem>
          <StackItem>
            <Text className="title">
              {t(blueprint.label)}
            </Text>
          </StackItem>
          <StackItem isFilled>
            <Text className="summary">
              {t(blueprint.summary)}
            </Text>
          </StackItem>
          <StackItem>
            <Button
              variant="link"
              isLarge
              onClick={() => {
                navigate(
                  `/${localization.currentLanguageCode}/start/${blueprint.sku}`
                );
              }}
            >
              {t('Select')} <ArrowRightIcon />
            </Button>
          </StackItem>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default AssessmentTile;
