import { getSnapshot, Instance, isStateTreeNode, types } from 'mobx-state-tree';
import { v4 as uuidv4 } from 'uuid';

export interface IOfferingModel {
  id: string;
  name: string;
  label: string;
  summary: string;
  action?: string | null;
  href?: string | null;
}

interface IUserModel {
  uuid?: string | null;
  username: string;
  country: string;
  email: string;
  family_name: string;
  fullname: string;
  given_name: string;
  job_title?: string | null;
  locale: string;
  phone?: string | null;
  corporate: boolean;
  company?: string | null;
}

interface MetadataSourceDataParticipantsModel {
  assessment: string;
  email: string;
  group: string;
  _valid: boolean;
}

interface MetadataSourceDataModel {
  expiry: number;
  participants: MetadataSourceDataParticipantsModel[];
  valid: boolean;
}

interface MetadataCampaignModel {
  sourceData: MetadataSourceDataModel;
  invitations: IInvitationModel[];
  owner: {
    name: string;
  };
  partner: {
    name: string;
    logo?: string;
    shortname: string;
  };
}

export interface ICampaignModel {
  id: string;
  owner_id: string;
  expiration: string;
  metadata: MetadataCampaignModel;
  reports: IReportModel[];
  user: IUserModel;
  shared_email_1?: string | null;
  shared_email_2?: string | null;
  shared_email_3?: string | null;
  shared_email_4?: string | null;
  shared_email_5?: string | null;
  shared_email_6?: string | null;
  shared_email_7?: string | null;
  shared_email_8?: string | null;
  shared_email_9?: string | null;
  shared_email_10?: string | null;
}

interface ReportScoreModel {
  id: string;
  name: string;
  points: number;
  maximum: number;
}

interface ReportMetadataAssessmentModel {
  name: string;
  id: string;
}

interface MetadataUserModel {
  fullname: string;
  group?: string;
  email: string;
}

interface MetadataDeputyModel {
  fullname: string;
}

interface MetadataModel {
  assessment: ReportMetadataAssessmentModel;
  user: MetadataUserModel;
  expiration: string;
}

interface ReportMetadataModel {
  assessment: ReportMetadataAssessmentModel;
  user: MetadataUserModel;
  deputy: MetadataDeputyModel;
}

export interface ICachedOfferingModel {
  id: string;
  name: string;
  label: string;
  summary: string;
  action?: string | null;
  href?: string | null;
}

interface StatisticsModel {
  duration: number;
}

export interface IReportModel {
  id: string;
  owner_id: string;
  timestamp: string;
  campaign_id: string;
  metadata: ReportMetadataModel;
  score: ReportScoreModel[];
  suggestions: ICachedOfferingModel[];
  statistics: StatisticsModel;
  partner: string;
  campaign: ICampaignModel;
  user: IUserModel;
}

export interface IInvitationModel {
  id: string;
  timestamp: string;
  campaign_id: string;
  assessment_id?: string | null;
  sheet_id?: string | null;
  recipient_email: string;
  expiration: string;
  metadata: MetadataModel;
  assessment: IBlueprint;
  campaign: ICampaignModel;
}

export interface sharedEmailList {
  shared_email_1: string | null;
  shared_email_2: string | null;
  shared_email_3: string | null;
  shared_email_4: string | null;
  shared_email_5: string | null;
  shared_email_6: string | null;
  shared_email_7: string | null;
  shared_email_8: string | null;
  shared_email_9: string | null;
  shared_email_10: string | null;
}

export interface deleteSharedEmailsMutationResponse {
  update_skill_campaigns_many: [
    {
      returning: sharedEmailList[];
    }
  ];
}

export interface updateSharedEmailsMutationResponse {
  update_skill_campaigns_by_pk: sharedEmailList;
}

export interface ISharedWithMe {
  id: string;
  expiration: Date;
  owner_id: string;
  metadata: {
    invitations: [
      {
        metadata: {
          assessment: {
            name: string;
          };
        };
        partner: null;
        recipient_email: string;
      }
    ];
    owner: {
      name: string;
    };
  };
}

export interface IBlueprint {
  sku: string;
  label: string;
  summary: string;
  targets: string[];
  metadata: { [key: string]: string };
  tagline: string;
}

interface Question {
  answer: string;
  ask: string;
  options: Option[];
  type: 'question';
}

interface Path {
  answer: string;
  target: string;
  type: 'path';
}

type Option = Question | Path

export interface Waypoints {
  ask: string;
  options: Option[];
  type: string;
}

// LEGACY, TO BE REMOVED
export enum ActionTypes {
  NEXT = 'next',
  SCORE = 'score',
  EVENT = 'event',
  END = 'end',
}

export const OfferingModel = types.model({
  id: types.identifier,
  name: types.string,
  label: types.string,
  summary: types.string,
  action: types.maybeNull(types.string),
  href: types.maybeNull(types.string),
});

export const UnitModel = types.model({
  id: types.identifier,
  name: types.string,
  providers: types.array(types.reference(OfferingModel)),
});

export const ProductModel = types.model({
  icon: types.string,
  name: types.string,
  id: types.string,
});

export const JobRoleModel = types.model({
  icon: types.string,
  name: types.string,
  id: types.string,
});

export const GoalModel = types.model({
  icon: types.string,
  name: types.string,
  id: types.string,
});

const MetadataAssessmentModel = types
  .model({
    forked: types.optional(types.string, ''),
    rhStartOfferId: types.optional(types.string, ''),
    partnerStartOfferId: types.optional(types.string, ''),
    rhFinishOfferId: types.optional(types.string, ''),
    partnerFinishOfferId: types.optional(types.string, ''),
  })
  .actions((self) => ({
    update(field, value) {
      self[field] = value;
    },
  }));

const ProductInfoModel = types.model({
  product: ProductModel,
});

const JobRoleInfoModel = types.model({
  jobrole: JobRoleModel,
});

const GoalInfoModel = types.model({
  goal: GoalModel,
});

export const BaseAnswersModel = types
  .model({
    message: types.string,
  })
  .actions((self) => ({
    updateText(value: string) {
      self.message = value;
    },
  }));

const ActionModel = types.model('ActionModel', {
  type: types.string,
});

const NextPayloadModel = types
  .model({
    target: types.string,
  })
  .actions((self) => ({
    updateTarget(newTarget) {
      self.target = newTarget;
    },
  }));

const NextTypeModel = types.model('NextTypeModel', {
  type: types.literal(ActionTypes.NEXT),
  payload: NextPayloadModel,
});

const NextActionModel = types.compose(ActionModel, NextTypeModel);

const ScorePayloadModel = types
  .model({
    target: types.string,
    value: types.number,
  })
  .actions((self) => ({
    updateValues(field, value) {
      self[field] = value;
    },
  }));

const ScoreActionModel = types.model({
  type: ActionTypes.SCORE,
  payload: ScorePayloadModel,
});

const TerminatorActionModel = types.model({
  type: types.literal(ActionTypes.END),
});

const EmitPayloadModel = types
  .model('EmitPayloadModel', {
    target: types.string,
  })
  .actions((self) => ({
    updateValues(field, value) {
      self[field] = value;
    },
  }));

const EventActionModel = types.model({
  type: types.literal(ActionTypes.EVENT),
  payload: EmitPayloadModel,
});

const ActionsUnionModel = types.union(
  NextActionModel,
  ScoreActionModel,
  TerminatorActionModel,
  EventActionModel
);

const ActionsUnionModelList = types.array(ActionsUnionModel);

type IActionsUnionModelList = Instance<typeof ActionsUnionModelList>;

const BaseActionsUnionModel = types
  .model('BaseActionsUnionModel', {
    actions: types.late(() => ActionsUnionModelList),
  })
  .actions((self) => ({
    addNewAction() {
      const currentActions = getSnapshot(self.actions).slice();

      const newActions = [
        ...currentActions,
        {
          type: ActionTypes.NEXT,
          payload: {
            target: '',
          },
        },
      ];

      self.actions = newActions as IActionsUnionModelList;
    },
    updateAction(actionType, index) {
      const newAction = {
        type: actionType,
        ...(actionType !== ActionTypes.END && {
          payload: {
            target: '',
            ...(actionType === ActionTypes.SCORE && { value: 0 }),
          },
        }),
      };

      const currentActions = getSnapshot(self.actions).slice();

      currentActions[index] = newAction;

      self.actions = currentActions as IActionsUnionModelList;
    },
    deleteAction(actionIndex) {
      const currentActions = getSnapshot(self.actions).slice();

      currentActions.splice(actionIndex, 1);

      self.actions = currentActions as IActionsUnionModelList;
    },
  }));

const AnswersModel = types.compose(BaseAnswersModel, BaseActionsUnionModel);

const AnswersList = types.array(AnswersModel);

const SingleChoiceModel = types
  .model({
    answers: AnswersList,
    message: types.string,
  })
  .actions((self) => ({
    addAnswer() {
      const currentAnswers = self.answers.slice();

      currentAnswers.push({
        // @ts-expect-error
        actions: [],
        message: '',
      });

      self.answers = currentAnswers as any;
    },
    updateAnswers(newAnswers: any) {
      self.answers = newAnswers;
    },

    updateMessage(newMessage: string) {
      self.message = newMessage;
    },
  }));

export type ISingleChoiceModel = Instance<typeof SingleChoiceModel>;

const SingleChoiceBodyModel = types.model({
  singlechoice: SingleChoiceModel,
});

const BaseListenerModel = types
  .model({
    target: types.optional(types.string, ''),
  })
  .actions((self) => ({
    updateText(value: string) {
      self.target = value;
    },
  }));
const ListenerModel = types.compose(BaseListenerModel, BaseActionsUnionModel);

const ListenerModelList = types.array(ListenerModel);

const LinkModel = types
  .model('LinkModel', {
    listeners: ListenerModelList,
    target: types.late(() => types.reference(AssessmentModel)),
  })
  .actions((self) => ({
    addListener() {
      const currentListeners = self.listeners.slice();

      //@ts-expect-error
      currentListeners.push({ target: '', actions: [] });

      self.listeners = currentListeners as any;
    },
    removeListener(index: number) {
      const currentListeners = self.listeners.slice();

      currentListeners.splice(index, 1);

      self.listeners = currentListeners as any;
    },
  }));

const LinkBodyModel = types.model('LinkBodyModel', {
  link: LinkModel,
});

const NodeBodyModel = types.union(SingleChoiceBodyModel, LinkBodyModel);

export const NodeData = types.model('NodeData', {
  id: types.identifier,
  position: types.number,
  comment: types.optional(types.string, ''),
  type: types.string,
  body: NodeBodyModel,
});

const NodeModel = types.array(NodeData);

export enum NodeTypes {
  LINK = 'link',
  SINGLECHOICE = 'singlechoice',
}

export enum AssessmentStates {
  SHARED = 'shared',
  DRAFT = 'draft',
  DEPLOYED = 'deployed',
  LEGACY = 'legacy',
}

export const AssessmentModel = types
  .model({
    id: types.identifier,
    state: types.string,
    sku: types.string,
    name: types.string,
    priority: types.number,
    metadata: types.optional(MetadataAssessmentModel, {}),
    products: types.optional(types.array(ProductInfoModel), []),
    jobroles: types.optional(types.array(JobRoleInfoModel), []),
    goals: types.optional(types.array(GoalInfoModel), []),
    summary: types.string,
    comment: types.optional(types.string, ''),
    nodes: NodeModel,
  })
  .preProcessSnapshot((snapshot: any) => {
    return {
      ...snapshot,
      //this is needed in order to ensure the nodes are going to be ordered by position
      nodes: snapshot.nodes
        .slice()
        .sort((nodeA, nodeB) => nodeA.position - nodeB.position),
    };
  })
  .views((self) => ({
    isTheLastNode(nodeIndex: number) {
      return self.nodes.length - 1 === nodeIndex;
    },
    getNode(nodeId) {
      return self.nodes.find((node) => node.id === nodeId);
    },
    get isDeployed() {
      return self.state !== AssessmentStates.DRAFT;
    },
  }))
  .actions((self) => ({
    addNewNode(nodeType: NodeTypes, position: number, linkTarget = '') {
      self.nodes[position] = {
        position: position,
        id: uuidv4(),
        type: nodeType,
        comment: '',
        body: {
          [nodeType]:
            nodeType === NodeTypes.SINGLECHOICE
              ? {
                answers: [],
                message: '',
              }
              : { listeners: [], target: linkTarget },
        },
      };
    },
    // the purpose of this map is to ensure that everytime we update the nodes of any assessment...
    // the position field is going to reflect the order in which the items are organized within the array
    updateNodes(newNodes: any) {
      self.nodes = newNodes.map((node: any, index: number) => ({
        // need to verify if is a node because it might be cases where we do not pass a mobx state tree node instance
        ...Object.assign({}, isStateTreeNode(node) ? getSnapshot(node) : node),
        position: index,
      }));
    },
    updateLists(field, newValues) {
      self[field] = newValues;
    },
  }));

export type IAssessmentModel = Instance<typeof AssessmentModel>;
