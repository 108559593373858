import React, { useEffect, useState } from 'react';
import { PageSection, Text, Radio } from '@patternfly/react-core';
import './Start.scss';
import { PageHero } from '@app/components/PageHero/PageHero';
import { Loader } from '@app/components/Loader/Loader';
import PageControls from '@app/components/PageControls/PageControls';

import { useNavigate, useParams } from 'react-router-dom';

import { useRoadmapStore } from '@app/state/RoadmapStore';
import { UserStore } from '@app/state/UserStore';
import { LocalizationStore } from '@app/state/LocalizationStore';
import { Waypoints } from '@app/types';

const StartPage: React.FunctionComponent = () => {
  const { sku } = useParams();
  const navigate = useNavigate();

  const { isUserReady } = UserStore((state) => ({
    isUserReady: state.ready,
  }));
  const { waypoints, roadmapId, generate_new_assessment, get_blueprint_by_sku, isRoadmapReady } =
  useRoadmapStore((state) => ({
    waypoints: state.waypoints,
    roadmapId: state.id,
    generate_new_assessment: state.generate_new_assessment,
    get_blueprint_by_sku: state.get_blueprint_by_sku,
    isRoadmapReady: state.ready
  }));

  const localization = LocalizationStore();
  const { t } = localization.useTranslation();

  const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [isRightLoading, setIsRightLoading] = useState<boolean>(false);
  const [currentQuestion, setCurrentQuestion] = useState<Waypoints>(waypoints);

  const handleChange = (_: boolean, event: React.BaseSyntheticEvent) => {
    setSelectedAnswer(parseInt(event.target.dataset.index));
  };

  const onNextCallback = async () => {
    const currentAnswer = currentQuestion.options[selectedAnswer];

    if (currentAnswer.type === 'question') {
      setCurrentQuestion(currentAnswer);
      setSelectedAnswer(0);
    } else if (currentAnswer.type === 'path') {
      setIsRightLoading(!isRightLoading)
      try {
        await handleNavigation(currentAnswer.target, roadmapId);
      } catch (error: any) {
        console.error('Error navigating:', error.message);
      } finally {
        setIsRightLoading(!isRightLoading)
      }
    }
  };

  // necessary due the lack of user auth information on the landing page to redirect the user
  const handleAssessmentRedirect = async () => {
    try {
      if (sku) {
        const blueprint = get_blueprint_by_sku(sku);
        await handleNavigation(blueprint.sku, roadmapId);
      } else {
        throw new Error('Invalid sku');
      }
    } catch (error: any) {
      console.error('Error handling assessment redirect:', error.message);
    }
  };

  const handleNavigation = async (
    blueprintSku: string,
    roadmapId: string | null
  ) => {
    try {
      if (!roadmapId) {
        throw new Error('Invalid roadmap');
      }

      const assessmentId = await generate_new_assessment(roadmapId, [blueprintSku]);

      if (assessmentId) {
        navigate(
          `/${localization.currentLanguageCode}/assessment/${assessmentId}`
        );
      } else {
        navigate('/en/not-found');
      }
    } catch (error: any) {
      console.error('Error navigating:', error.message);
    }
  };

  useEffect(() => {
    if (!sku) {
      setShowContent(true);
      return;
    }

    handleAssessmentRedirect();
  }, []);

  return isUserReady && showContent && isRoadmapReady ? (
    <React.Fragment>
      <PageSection
        isWidthLimited={true}
        isCenterAligned={true}
        className="page-hero"
      >
        <PageHero title={t('What are you looking for?')} />
      </PageSection>
      <PageSection
        isWidthLimited={true}
        isCenterAligned={true}
        className="single-question"
      >
        <Text className="question-text">{t(currentQuestion.ask)}</Text>

        {currentQuestion.options.map((option, index) => (
          <Radio
            aria-label={t(option.answer)}
            id={'option-' + index}
            className="question-answer"
            name={'answer-choice'}
            key={index}
            data-index={index}
            onChange={handleChange}
            label={t(option.answer)}
            isChecked={index === selectedAnswer}
          />
        ))}
      </PageSection>
      <PageSection
        isWidthLimited={true}
        isCenterAligned={true}
        className="page-controls"
      >
        <PageControls
          onRight={onNextCallback}
          isRightLoading={isRightLoading}
          isRightDisabled={isRightLoading}
          rightLabel={t('Next')}
        />
      </PageSection>
    </React.Fragment>
  ) : (
    <PageSection
      isWidthLimited={true}
      isCenterAligned={true}
      className="loader lg"
    >
      <Loader />
    </PageSection>
  );
};

export default StartPage;
