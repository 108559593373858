import { Bullseye, Spinner, SpinnerProps } from '@patternfly/react-core';
import React from 'react';

export const Loader: React.FC<SpinnerProps> = ({ size }) => {
  return (
    <Bullseye>
      <Spinner
        aria-label="Loading"
        size={size}
      />
    </Bullseye>
  );
};

export default Loader;
