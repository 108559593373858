import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Loader } from '@app/components/Loader/Loader';
import { PageSection } from '@patternfly/react-core';
import { PartnerStore } from '@app/state/PartnerStore';
import { UserStore } from '@app/state/UserStore';
import { JWT_AUTOMATIC_REFRESH_DELTA } from '@src/app/constants';

const PrivateRoutes: React.FunctionComponent = () => {
  const partner = PartnerStore();
  const { heartbeat, isLoggedIn, expiration } = UserStore((state) => ({
    heartbeat: state.heartbeat,
    isLoggedIn: state.isLoggedIn,
    expiration: state.expiration,
  }));

  const doHeartbeat = () => {
    if (
      !expiration ||
      expiration - Date.now() < JWT_AUTOMATIC_REFRESH_DELTA * 60 * 1000
    ) {
      heartbeat(partner.shortname ? 'partners' : 'redhat');
    }
  };

  useEffect(() => {
    const interval = setInterval(doHeartbeat, 10 * 1000); // 10 seconds
    return () => clearInterval(interval);
  }, [expiration]);

  return isLoggedIn() ? (
    <Outlet />
  ) : (
    <PageSection
      isWidthLimited={true}
      isCenterAligned={true}
      className="loader lg"
    >
      <Loader />
    </PageSection>
  );
};

export default PrivateRoutes;
